// pages/ProjectsPage.jsx
import { useState, useEffect } from "react"
import { useAuth } from "../contexts/AuthContext"
import { useToast } from "../components/ui/toast"
import axiosInstance from "../api/axiosInstance"

// Components
import { EmptyState } from "../components/projects/EmptyState"
import { ProjectSectionHeader } from "../components/projects/ProjectSectionHeader"
import { ProjectRow } from "../components/projects/ProjectRow"
import { NewProjectDialog } from "../components/projects/NewProjectDialog"
import { DeleteProjectDialog } from "../components/projects/DeleteProjectDialog"
import { ProjectHeader } from "../components/projects/ProjectHeader"

// Constants
import { COLORS, getProjectStatus, formatDateTimeLocal } from "../components/projects/constants"

export default function ProjectsPage() {
  const { auth } = useAuth()
  const { toast } = useToast()
  const [projects, setProjects] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [projectToDelete, setProjectToDelete] = useState(null)
  const [newProject, setNewProject] = useState(false)
  const [projectName, setProjectName] = useState("")
  const [startTime, setStartTime] = useState("")
  const [deadline, setDeadline] = useState("")
  const [selectedColor, setSelectedColor] = useState(COLORS[0])
  const [isGenerating, setIsGenerating] = useState(false)
  const [quickGenerating, setQuickGenerating] = useState(false)
  const [generatingProject, setGeneratingProject] = useState(null)

  const fetchProjects = async () => {
    try {
      const response = await axiosInstance.get('/api/projects')
      setProjects(response.data.projects)
      setLoading(false)
    } catch (err) {
      setError(`Failed to fetch projects. Error: ${err.message}`)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchProjects()
  }, [])

  const handleUpdateDates = async (projectId, newStartTime, newDeadline) => {
    const projectToUpdate = projects.find(p => p.id === projectId)
    if (!projectToUpdate) return

    try {
      const tasks = projectToUpdate.tasks || [];
      const tasksWithPreservedState = tasks.map(task => ({
        ...task,
        completed: task.completed || false,
        order: task.order || 0,
        estimated_hours: task.estimated_hours || 0,
        estimated_minutes: task.estimated_minutes || 0,
        due_date: task.due_date || null
      }));

      await axiosInstance.put(`/api/projects/${projectId}`, {
        ...projectToUpdate,
        start_time: newStartTime,
        deadline: newDeadline,
        tasks: tasksWithPreservedState
      })
      await fetchProjects()
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update project dates. Please try again.",
        variant: "destructive",
      })
    }
 }

  const handleQuickGenerate = async (projectName, updateStatus) => {
    setQuickGenerating(true)
    try {
      // Initial state
      setGeneratingProject({
        id: 'generating',
        name: projectName,
        tasks: [],
        isGenerating: true,
        status: 'Analyzing project requirements...'
      })

      updateStatus("Making API call...")
      const { data } = await axiosInstance.post('/api/preview-project', {
        project_name: projectName
      })

      if (data.success && data.suggestions) {
        // Update name and structure
        updateStatus("Creating project structure...")
        setGeneratingProject(prev => ({
          ...prev,
          name: data.suggestions.name || projectName
        }))
        await new Promise(resolve => setTimeout(resolve, 600))

        // Update timeline
        updateStatus("Setting project timeline...")
        setGeneratingProject(prev => ({
          ...prev,
          start_time: data.suggestions.start_time,
          deadline: data.suggestions.deadline
        }))
        await new Promise(resolve => setTimeout(resolve, 600))

        // Process tasks if they exist
        if (data.suggestions.tasks && data.suggestions.tasks.length > 0) {
          updateStatus("Breaking down into tasks...")
          await new Promise(resolve => setTimeout(resolve, 600))

          // Sort tasks by order before processing
          const sortedTasks = [...data.suggestions.tasks].sort((a, b) => (a.order || 0) - (b.order || 0))

          setGeneratingProject(prev => ({
            ...prev,
            tasks: sortedTasks.map(task => ({
              name: task.name,
              estimated_hours: task.estimated_hours || 0,
              estimated_minutes: task.estimated_minutes || 0,
              completed: false,
              tag: task.tag || "Other",
              order: task.order
            }))
          }))

          // Visual delay for task creation
          for (let i = 0; i < sortedTasks.length; i++) {
            updateStatus(`Creating task ${i + 1} of ${sortedTasks.length}...`)
            await new Promise(resolve => setTimeout(resolve, 400))
          }
        }

        const projectData = {
          name: data.suggestions.name || projectName,
          start_time: data.suggestions.start_time,
          deadline: data.suggestions.deadline,
          color: data.suggestions.color || COLORS[0],
          tasks: data.suggestions.tasks?.sort((a, b) => (a.order || 0) - (b.order || 0))
            .map(task => ({
              name: task.name,
              estimated_hours: task.estimated_hours || 0,
              estimated_minutes: task.estimated_minutes || 0,
              completed: false,
              tag: task.tag || "Other",
              order: task.order
            })) || [],
        }

        updateStatus("Saving project...")
        await axiosInstance.post("/api/project-create", projectData)
        await fetchProjects()
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to generate project. Please try again.",
        variant: "destructive",
      })
    } finally {
      setQuickGenerating(false)
      setGeneratingProject(null)
    }
  }

  const handleCreateProject = async () => {
    const now = new Date()
    const start = new Date(startTime)
    const end = new Date(deadline)

    if (start <= now) {
      toast({
        title: "Error",
        description: "Start time must be in the future for new projects.",
        variant: "destructive",
      })
      return
    }

    if (end <= now) {
      toast({
        title: "Error",
        description: "Deadline must be in the future.",
        variant: "destructive",
      })
      return
    }

    if (end <= start) {
      toast({
        title: "Error",
        description: "Deadline must be after the start time.",
        variant: "destructive",
      })
      return
    }

    try {
      await axiosInstance.post("/api/project-create", {
        name: projectName,
        start_time: startTime,
        deadline,
        color: selectedColor,
        tasks: [],
      })
      await fetchProjects()
      setNewProject(false)
      resetNewProjectForm()
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to create project. Please try again.",
        variant: "destructive",
      })
    }
  }

  const handleGenerate = async () => {
    if (!projectName.trim()) return

    setIsGenerating(true)
    try {
      const { data } = await axiosInstance.post('/api/preview-project', {
        project_name: projectName
      })

      if (data.success && data.suggestions) {
        const suggestions = data.suggestions

        setTimeout(() => setProjectName(suggestions.name || ""), 100)
        setTimeout(() => setStartTime(formatDateTimeLocal(suggestions.start_time)), 300)
        setTimeout(() => setDeadline(formatDateTimeLocal(suggestions.deadline)), 500)
        setTimeout(() => setSelectedColor(suggestions.color || COLORS[0]), 700)
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to generate project details",
        variant: "destructive",
      })
    } finally {
      setTimeout(() => setIsGenerating(false), 1000)
    }
  }

  const handleDeleteProject = async () => {
    if (!projectToDelete) return

    try {
      await axiosInstance.delete(`/api/projects/${projectToDelete.id}`)
      setProjects(prev => prev.filter(p => p.id !== projectToDelete.id))
      setProjectToDelete(null)
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to delete project",
        variant: "destructive",
      })
    }
  }

  const handleUpdateColor = async (projectId, newColor) => {
    const projectToUpdate = projects.find(p => p.id === projectId)
    if (!projectToUpdate) return

    try {
      // Safely handle tasks - if undefined/null, use empty array
      const tasks = projectToUpdate.tasks || [];

      // Sort tasks by order before sending (only if there are tasks)
      const orderedTasks = tasks.length > 0 ? [...tasks].sort((a, b) => {
        const orderA = typeof a.order === 'number' ? a.order : Number.MAX_SAFE_INTEGER;
        const orderB = typeof b.order === 'number' ? b.order : Number.MAX_SAFE_INTEGER;
        return orderA - orderB;
      }) : [];

      // Ensure each task has an explicit order property (only if there are tasks)
      const tasksWithOrder = orderedTasks.map((task, index) => ({
        ...task,
        order: index + 1,
        due_date: task.due_date || null
      }));

      // Create request payload with explicit date preservation
      const payload = {
        id: projectToUpdate.id,
        name: projectToUpdate.name,
        color: newColor,
        start_time: projectToUpdate.start_time,
        deadline: projectToUpdate.deadline,
        completed: projectToUpdate.completed || false, // Ensure completed has a value
        tasks: tasksWithOrder
      }

      await axiosInstance.put(`/api/projects/${projectId}`, payload)
      await fetchProjects()
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update project color. Please try again.",
        variant: "destructive",
      })
    }
  }

  const handleUpdateName = async (projectId, newName) => {
    const projectToUpdate = projects.find(p => p.id === projectId)
    if (!projectToUpdate) return

    try {
      await axiosInstance.put(`/api/projects/${projectId}`, {
        ...projectToUpdate,
        name: newName,
        tasks: (projectToUpdate.tasks || []).map(task => ({
          ...task,
          due_date: task.due_date || null  // Add this
        }))
      })
      await fetchProjects()
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update project name. Please try again.",
        variant: "destructive",
      })
    }
  }

  const handleUpdateTasks = async (projectId, updatedTasks) => {
    const projectToUpdate = projects.find(p => p.id === projectId)
    if (!projectToUpdate) return

    try {
      // Get all tasks with due dates
      const tasksWithDates = updatedTasks.filter(task => task.due_date);

      // Verify that all tasks with due dates are in chronological order
      const hasInvalidOrder = tasksWithDates.some((task, index) => {
        // For each task with a due date, check against all subsequent tasks with due dates
        const laterTasks = tasksWithDates.slice(index + 1);
        return laterTasks.some(laterTask =>
          new Date(laterTask.due_date) < new Date(task.due_date)
        );
      });

      if (hasInvalidOrder) {
        toast({
          title: "Invalid Task Order",
          description: "Tasks with due dates must be in chronological order",
          variant: "destructive",
        });
        return;
      }

      // If order is valid, proceed with update while preserving user's order
      await axiosInstance.put(`/api/projects/${projectId}`, {
        ...projectToUpdate,
        tasks: updatedTasks.map((t, idx) => ({
          ...t,
          order: idx + 1,
          due_date: t.due_date || null
        })),
      })
      await fetchProjects()
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update tasks. Please try again.",
        variant: "destructive",
      })
    }
  }

  const handleDuplicateProject = async (projectData) => {
    try {
      // Create the project with copied data, ensuring task order is maintained
      await axiosInstance.post("/api/project-create", {
        name: projectData.name,
        start_time: projectData.start_time,
        deadline: projectData.deadline,
        color: projectData.color,
        tasks: projectData.tasks
          .sort((a, b) => (a.order || 0) - (b.order || 0)) // Sort tasks by order first
          .map((task, index) => ({
            name: task.name,
            estimated_hours: task.estimated_hours || 0,
            estimated_minutes: task.estimated_minutes || 0,
            due_date: task.due_date || null,
            tag: task.tag,
            order: index + 1
          })),
      })

      await fetchProjects()

      toast({
        title: "Success",
        description: "Project duplicated successfully",
      })
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to duplicate project. Please try again.",
        variant: "destructive",
      })
    }
  }

  const resetNewProjectForm = () => {
    setProjectName("")
    setStartTime("")
    setDeadline("")
    setSelectedColor(COLORS[0])
  }

  if (loading) {
    return (
      <div className="flex h-[50vh] items-center justify-center">
        <div className="flex items-center gap-2">
          <div className="h-4 w-4 animate-spin rounded-full border-2 border-primary border-t-transparent" />
          <span>Loading projects...</span>
        </div>
      </div>
    )
  }

  if (error) {
    return <p className="text-destructive">{error}</p>
  }

  const { toStart, inProgress, overdue, unscheduled, completed } = {
    toStart: projects.filter(p => !p.completed && getProjectStatus(p) === "to-start"),
    inProgress: projects.filter(p => !p.completed && getProjectStatus(p) === "in-progress"),
    overdue: projects.filter(p => !p.completed && getProjectStatus(p) === "overdue"),
    unscheduled: projects.filter(p => !p.completed && getProjectStatus(p) === undefined),
    completed: projects.filter(p => p.completed)
  };

  // Add this console.log here
  console.log({
    total: projects.length,
    completed: projects.filter(p => p.completed).length,
    toStart: toStart.length,
    inProgress: inProgress.length,
    overdue: overdue.length,
    unscheduled: unscheduled.length
  });

  return (
      <div className="flex flex-col pt-6 pb-12">
        <ProjectHeader
            onAddProject={() => setNewProject(true)}
            onGenerateProject={handleQuickGenerate}
        />
        <div className="px-6">
          {projects.length === 0 && !generatingProject ? (
              <EmptyState onCreateClick={() => setNewProject(true)}/>
          ) : (
              <div className="space-y-8 pt-6">
                <div>
                  <ProjectSectionHeader
                      title="To Start"
                      count={toStart.length + (generatingProject ? 1 : 0)}
                      color="#3B82F6"
                  />
                  {generatingProject && (
                      <div className="mt-2 rounded-md border bg-card p-4">
                        <div className="flex items-center gap-2">
                          <div className="h-4 w-4 rounded-full animate-pulse bg-primary"/>
                          <span className="font-medium">{generatingProject.name}</span>
                          <span className="text-sm text-muted-foreground">
                      {generatingProject.status}
                    </span>
                        </div>
                      </div>
                  )}
                  {toStart.map(project => (
                      <ProjectRow
                          key={project.id}
                          project={project}
                          onDeleteProject={() => setProjectToDelete(project)}
                          onTaskUpdate={(tasks) => handleUpdateTasks(project.id, tasks)}
                          onTasksReorder={(tasks) => handleUpdateTasks(project.id, tasks)}
                          onUpdateDates={(startTime, deadline) => handleUpdateDates(project.id, startTime, deadline)}
                          onUpdateColor={(color) => handleUpdateColor(project.id, color)}
                          onDuplicate={handleDuplicateProject}
                          isSubscribed={auth.isSubscribed}
                          onUpdateName={(newName) => handleUpdateName(project.id, newName)}
                          onEditDueDateChange={(newDueDate) => handleUpdateTasks(project.id, {
                            ...project.tasks,
                            due_date: newDueDate
                        })}
                      />
                  ))}
                </div>

                <div>
                  <ProjectSectionHeader
                      title="In Progress"
                      count={inProgress.length}
                      color="#22C55E"
                  />
                  {inProgress.map(project => (
                      <ProjectRow
                          key={project.id}
                          project={project}
                          onDeleteProject={() => setProjectToDelete(project)}
                          onTaskUpdate={(tasks) => handleUpdateTasks(project.id, tasks)}
                          onTasksReorder={(tasks) => handleUpdateTasks(project.id, tasks)}
                          onUpdateDates={(startTime, deadline) => handleUpdateDates(project.id, startTime, deadline)}
                          onUpdateColor={(color) => handleUpdateColor(project.id, color)}
                          onDuplicate={handleDuplicateProject}
                          isSubscribed={auth.isSubscribed}
                          onUpdateName={(newName) => handleUpdateName(project.id, newName)}
                          onEditDueDateChange={(newDueDate) => handleUpdateTasks(project.id, {
                              ...project.tasks,
                              due_date: newDueDate
                          })}
                      />
                  ))}
                </div>

                <div>
                  <ProjectSectionHeader
                      title="Overdue"
                      count={overdue.length}
                      color="#EF4444"
                  />
                  {overdue.map(project => (
                      <ProjectRow
                          key={project.id}
                          project={project}
                          onDeleteProject={() => setProjectToDelete(project)}
                          onTaskUpdate={(tasks) => handleUpdateTasks(project.id, tasks)}
                          onTasksReorder={(tasks) => handleUpdateTasks(project.id, tasks)}
                          onUpdateDates={(startTime, deadline) => handleUpdateDates(project.id, startTime, deadline)}
                          onUpdateColor={(color) => handleUpdateColor(project.id, color)}
                          onDuplicate={handleDuplicateProject}
                          isSubscribed={auth.isSubscribed}
                          onUpdateName={(newName) => handleUpdateName(project.id, newName)}
                          onEditDueDateChange={(newDueDate) => handleUpdateTasks(project.id, {
                              ...project.tasks,
                              due_date: newDueDate
                          })}
                      />
                  ))}
                </div>

                <div>
                  <ProjectSectionHeader
                      title="Completed"
                      count={completed.length}
                      color="#64748B"  // You can choose a different color
                  />
                  {completed.map(project => (
                      <ProjectRow
                          key={project.id}
                          project={project}
                          onDeleteProject={() => setProjectToDelete(project)}
                          onTaskUpdate={(tasks) => handleUpdateTasks(project.id, tasks)}
                          onTasksReorder={(tasks) => handleUpdateTasks(project.id, tasks)}
                          onUpdateDates={(startTime, deadline) => handleUpdateDates(project.id, startTime, deadline)}
                          onUpdateColor={(color) => handleUpdateColor(project.id, color)}
                          onDuplicate={handleDuplicateProject}
                          isSubscribed={auth.isSubscribed}
                          onUpdateName={(newName) => handleUpdateName(project.id, newName)}
                          onEditDueDateChange={(newDueDate) => handleUpdateTasks(project.id, {
                              ...project.tasks,
                              due_date: newDueDate
                          })}
                      />
                  ))}
                </div>
              </div>
          )}
        </div>

        <NewProjectDialog
            open={newProject}
            onClose={() => setNewProject(false)}
            onSubmit={handleCreateProject}
            isGenerating={isGenerating}
            onGenerate={handleGenerate}
            projectName={projectName}
            onProjectNameChange={setProjectName}
            startTime={startTime}
            onStartTimeChange={setStartTime}
            deadline={deadline}
            onDeadlineChange={setDeadline}
            selectedColor={selectedColor}
            onColorChange={setSelectedColor}
        />

        <DeleteProjectDialog
            project={projectToDelete}
            open={!!projectToDelete}
            onClose={() => setProjectToDelete(null)}
            onConfirm={handleDeleteProject}
        />
      </div>
  )
}