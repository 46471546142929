import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { 
  Bold, 
  Italic, 
  List, 
  Heading, 
  Link as LinkIcon 
} from 'lucide-react';
import { Button } from "../ui/button";
import { Separator } from "../ui/separator";
import { cn } from "../../lib/utils";

const RichTextEditor = ({ content, onChange }) => {
  const editor = useEditor({
    extensions: [StarterKit],
    content: content,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      onChange(html);
    },
    parseOptions: {
      preserveWhitespace: true,
    }
  });

  if (!editor) {
    return null;
  }

  const FormatButton = ({ format, icon: Icon }) => {
    const isActive = editor.isActive(format);
    
    const handleClick = () => {
      switch(format) {
        case 'bold':
          editor.chain().focus().toggleBold().run();
          break;
        case 'italic':
          editor.chain().focus().toggleItalic().run();
          break;
        case 'heading':
          editor.chain().focus().toggleHeading({ level: 1 }).run();
          break;
        case 'bulletList':
          editor.chain().focus().toggleBulletList().run();
          break;
        default:
          break;
      }
    };

    return (
      <Button
        variant="ghost"
        size="sm"
        onClick={handleClick}
        className={cn(
          "h-8 w-8 p-1",
          "text-gray-900 hover:text-gray-900 hover:bg-gray-100/50",
          isActive && "bg-red-100 text-red-600 hover:bg-red-100 hover:text-red-600"
        )}
      >
        <Icon className="h-4 w-4" />
      </Button>
    );
  };

  const FormatBar = () => (
    <div className="flex items-center gap-2 px-2 py-1 bg-muted/50">
      <div className="flex gap-1">
        <FormatButton format="bold" icon={Bold} />
        <FormatButton format="italic" icon={Italic} />
        <FormatButton format="heading" icon={Heading} />
        <FormatButton format="bulletList" icon={List} />
      </div>
    </div>
  );

  return (
    <div className="flex flex-col w-full h-full">
      <FormatBar />
      <Separator className="mb-4" />
      <div className="flex-1 overflow-auto px-2">
        <EditorContent editor={editor} />
      </div>
    </div>
  );
};

export default RichTextEditor; 