// components/projects/ProjectRow/index.jsx
import { useState, useMemo, useEffect } from "react"
import { ProjectRowHeader } from "./ProjectRowHeader"
import { ProjectRowTasks } from "./ProjectRowTasks"
import { AVAILABLE_TAGS, MAX_FREE_TASKS } from "../constants"
import { cn } from "../../../lib/utils"
import { useToast } from "../../../components/ui/toast"

export function ProjectRow({
  project,
  onDeleteProject,
  onTaskUpdate,
  onTasksReorder,
  onUpdateDates,
  isSubscribed,
  onUpdateColor,
  onDuplicate,
  onUpdateName,
}) {
  const { toast } = useToast()
  const [isExpanded, setIsExpanded] = useState(false)
  const [isEditing, setIsEditing] = useState(null)
  const [editHours, setEditHours] = useState("")
  const [editMinutes, setEditMinutes] = useState("")
  const [editTag, setEditTag] = useState("")
  const [newTaskForm, setNewTaskForm] = useState(false)
  const [taskName, setTaskName] = useState("")
  const [taskHours, setTaskHours] = useState("")
  const [taskMinutes, setTaskMinutes] = useState("")
  const [taskTag, setTaskTag] = useState("")
  const [selectedTasks, setSelectedTasks] = useState(() => new Set())
  const [editName, setEditName] = useState("")
  const [taskDueDate, setTaskDueDate] = useState("")
  const [editDueDate, setEditDueDate] = useState("")

  useEffect(() => {
    if (project.tasks?.length > 0) {
      const sortedTasks = [...project.tasks].sort((a, b) => {
        return (a.order || 0) - (b.order || 0)
      })

      const hasOrderChanged = sortedTasks.some((task, index) => task !== project.tasks[index])
      if (hasOrderChanged) {
        onTaskUpdate(sortedTasks)
      }
    }
  }, [project.tasks])

  const completion = useMemo(() => {
    const totalMinutes = project.tasks.reduce(
      (total, t) => total + (parseInt(t.estimated_hours, 10) * 60 + parseInt(t.estimated_minutes, 10)),
      0
    )
    const completedMinutes = project.tasks.reduce((acc, t) => {
      if (t.completed) {
        return acc + (parseInt(t.estimated_hours, 10) * 60 + parseInt(t.estimated_minutes, 10))
      }
      return acc
    }, 0)
    return totalMinutes > 0 ? (completedMinutes / totalMinutes) * 100 : 0
  }, [project.tasks])

  const validateTaskDueDate = (dueDate, projectStartTime, projectDeadline) => {
    if (!dueDate) return { isValid: true };

    const taskDate = new Date(dueDate);
    const startDate = new Date(projectStartTime);
    const endDate = new Date(projectDeadline);

    if (taskDate < startDate) {
      return {
        isValid: false,
        error: "Task due date cannot be before project start date"
      };
    }

    if (taskDate > endDate) {
      return {
        isValid: false,
        error: "Task due date cannot be after project deadline"
      };
    }

    return { isValid: true };
  };

  const handleAddTask = () => {
    if (!isSubscribed && project.tasks.length >= MAX_FREE_TASKS) {
      return
    }

    if (!taskTag || !taskName || (!taskHours && !taskMinutes)) {
      return
    }

    if (taskDueDate) {
      const { isValid, error } = validateTaskDueDate(
        taskDueDate,
        project.start_time,
        project.deadline
      );

      if (!isValid) {
        toast({
          title: "Invalid Due Date",
          description: error,
          variant: "destructive",
        });
        return;
      }
    }

    const dueDate = taskDueDate ? new Date(taskDueDate) : null
    const newTask = {
      name: taskName,
      estimated_hours: taskHours || 0,
      estimated_minutes: taskMinutes || 0,
      completed: false,
      tag: taskTag,
      due_date: dueDate ? dueDate.toISOString() : null
    }

    if (!dueDate) {
      // If no due date, simply append to end
      newTask.order = project.tasks.length + 1;
      onTaskUpdate([...project.tasks, newTask]);
      resetTaskForm();
      return;
    }

    // If has due date, find where it should go relative to other tasks with due dates
    let insertIndex = project.tasks.length; // Default to end
    for (let i = 0; i < project.tasks.length; i++) {
      const task = project.tasks[i];
      if (task.due_date && new Date(task.due_date) > dueDate) {
        insertIndex = i;
        break;
      }
    }

    const updatedTasks = [...project.tasks];
    updatedTasks.splice(insertIndex, 0, newTask);

    // Update order numbers
    updatedTasks.forEach((task, index) => {
      task.order = index + 1;
    });

    onTaskUpdate(updatedTasks);
    resetTaskForm();
  }

  const handleTaskEdit = (index) => {
    const task = project.tasks[index]
    setIsEditing(index)
    setEditName(task.name)
    setEditHours(task.estimated_hours || 0)
    setEditMinutes(task.estimated_minutes || 0)
    setEditTag(task.tag || "Other")
    setEditDueDate(task.due_date || "")
  }

  const handleExpandToggle = () => {
    setIsExpanded(!isExpanded)
    setSelectedTasks(new Set())
  }

  const handleSaveTask = (index) => {
    if (editDueDate) {
      const { isValid, error } = validateTaskDueDate(
        editDueDate,
        project.start_time,
        project.deadline
      );

      if (!isValid) {
        toast({
          title: "Invalid Due Date",
          description: error,
          variant: "destructive",
        });
        return;
      }
    }

    const updatedTasks = [...project.tasks]
    updatedTasks[index] = {
      ...updatedTasks[index],
      name: editName,
      estimated_hours: editHours || 0,
      estimated_minutes: editMinutes || 0,
      tag: editTag,
      due_date: editDueDate || null,
    }
    onTaskUpdate(updatedTasks)
    setIsEditing(null)
  }

  const handleTaskDelete = (index) => {
    const updatedTasks = [...project.tasks]
    updatedTasks.splice(index, 1)
    updatedTasks.forEach((task, i) => {
      task.order = i + 1
    })
    onTaskUpdate(updatedTasks)
  }

  const handleTaskCompletionToggle = (index) => {
    const updatedTasks = [...project.tasks]
    updatedTasks[index].completed = !updatedTasks[index].completed
    onTaskUpdate(updatedTasks)
  }

  const handleTaskSelect = (index) => {
    setSelectedTasks(prev => {
      const newSet = new Set(prev)
      if (newSet.has(index)) {
        newSet.delete(index)
      } else {
        newSet.add(index)
      }
      return newSet
    })
  }

  const handleBulkComplete = () => {
    const updatedTasks = [...project.tasks]
    selectedTasks.forEach(index => {
      updatedTasks[index].completed = true
    })
    onTaskUpdate(updatedTasks)
    setSelectedTasks(new Set())
  }

  const handleBulkDelete = () => {
    const updatedTasks = project.tasks.filter((_, index) => !selectedTasks.has(index))
    updatedTasks.forEach((task, i) => {
      task.order = i + 1
    })
    onTaskUpdate(updatedTasks)
    setSelectedTasks(new Set())
  }

  const resetTaskForm = () => {
    setTaskName("")
    setTaskHours("")
    setTaskMinutes("")
    setTaskTag("")
    setTaskDueDate("")
    setNewTaskForm(false)
  }

  return (
    <div className="mb-2">
      <div className={cn(
        "border-2 border-border rounded-lg",
        !isExpanded && "border-none",
        isExpanded && "border-2 border-border rounded-lg"
      )}>
        <ProjectRowHeader
          project={project}
          completion={completion}
          isExpanded={isExpanded}
          onExpandToggle={handleExpandToggle}
          onDelete={onDeleteProject}
          onUpdateDates={onUpdateDates}
          onUpdateColor={onUpdateColor}
          onDuplicate={onDuplicate}
          onUpdateName={onUpdateName}
        />

        {isExpanded && (
          <ProjectRowTasks
            project={project}
            isSubscribed={isSubscribed}
            newTaskForm={newTaskForm}
            taskName={taskName}
            taskHours={taskHours}
            selectedTasks={selectedTasks}
            taskMinutes={taskMinutes}
            taskTag={taskTag}
            taskDueDate={taskDueDate}
            editDueDate={editDueDate}
            isEditing={isEditing}
            editHours={editHours}
            editMinutes={editMinutes}
            editTag={editTag}
            onNewTaskFormToggle={() => setNewTaskForm(!newTaskForm)}
            onTaskNameChange={setTaskName}
            onTaskHoursChange={setTaskHours}
            onTaskMinutesChange={setTaskMinutes}
            onTaskTagChange={setTaskTag}
            onTaskDueDateChange={setTaskDueDate}
            onEditDueDateChange={setEditDueDate}
            onAddTask={handleAddTask}
            onEditTask={handleTaskEdit}
            onSaveTask={handleSaveTask}
            onDeleteTask={handleTaskDelete}
            onTaskCompletionToggle={handleTaskCompletionToggle}
            onTasksReorder={onTasksReorder}
            onEditCancel={() => setIsEditing(null)}
            onEditHoursChange={setEditHours}
            onEditMinutesChange={setEditMinutes}
            onEditTagChange={setEditTag}
            onTaskSelect={handleTaskSelect}
            onBulkComplete={handleBulkComplete}
            onBulkDelete={handleBulkDelete}
            editName={editName}
            onEditNameChange={setEditName}
          />
        )}
      </div>
    </div>
  )
}