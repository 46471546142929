import React from 'react'
import Layout from '../components/Layout'
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card"
import { Button } from "../components/ui/button"

const SubscribeSuccessPage = () => {
  return (
    <Layout>
      <div className="container flex items-center justify-center min-h-screen py-8">
        <div className="w-full max-w-md">
          <Card>
            <CardHeader>
              <CardTitle>Subscription Success</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <p className="text-sm text-muted-foreground">
                  Thanks for subscribing!
                </p>
                <div className="mt-4">
                  <Button asChild className="w-full">
                    <a href="/calendar">Go to Calendar</a>
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </Layout>
  )
}

export default SubscribeSuccessPage