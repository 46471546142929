import React, { useState } from 'react';
import { Button } from '../ui/button';
import { Check, Loader2, RotateCcw } from 'lucide-react';
import axiosInstance from '../../api/axiosInstance';

const TaskCheckbox = ({
  task,
  isSelected,
  selectedCount,
  onSelectionChange,
  onUncomplete,
  isCompletedTab,
  refreshTasks,
  setSelectedTasks
}) => {
  const [loading, setLoading] = useState(false);

  const handleCompleteSelectedTasks = async () => {
    try {
      setLoading(true);
      const selectedTaskElements = document.querySelectorAll('[data-selected="true"]');
      const tasks = Array.from(selectedTaskElements).map(el => ({
        id: el.dataset.taskId,
        type: el.dataset.taskType,
        projectId: el.dataset.projectId
      }));

      for (const task of tasks) {
        if (task.type === 'project_task') {
          const response = await axiosInstance.get(`/api/projects/${task.projectId}`);
          const project = response.data;

          const updatedTasks = project.tasks.map(t =>
            t.id === task.id ? { ...t, completed: true } : t
          );

          await axiosInstance.put(`/api/projects/${task.projectId}`, {
            ...project,
            tasks: updatedTasks
          });
        } else {
          await axiosInstance.post(`/api/tasks/${task.id}/complete`);
        }
      }

      await refreshTasks();
      setSelectedTasks(new Set());
    } catch (error) {
      console.error('Error completing tasks:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteSelectedTasks = async () => {
    try {
      setLoading(true);
      const selectedTaskElements = document.querySelectorAll('[data-selected="true"]');
      const tasks = Array.from(selectedTaskElements).map(el => ({
        id: el.dataset.taskId,
        type: el.dataset.taskType,
        projectId: el.dataset.projectId
      }));

      for (const task of tasks) {
        if (task.type === 'project_task') {
          const response = await axiosInstance.get(`/api/projects/${task.projectId}`);
          const project = response.data;

          const updatedTasks = project.tasks.filter(t => t.id !== task.id);

          await axiosInstance.put(`/api/projects/${task.projectId}`, {
            ...project,
            tasks: updatedTasks
          });
        } else {
          await axiosInstance.delete(`/api/tasks/${task.id}`);
        }
      }

      await refreshTasks();
      setSelectedTasks(new Set());
    } catch (error) {
      console.error('Error deleting tasks:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxClick = (e) => {
    e.stopPropagation();
    if (isCompletedTab) {
      onUncomplete(task);
    } else {
      onSelectionChange(task.id, !isSelected);
    }
  };

  return (
    <>
      {isCompletedTab ? (
        <Button
          variant="ghost"
          className="h-8 w-8 p-0 hover:bg-transparent focus:ring-2 focus:ring-offset-2"
          onClick={handleCheckboxClick}
          data-task-id={task.id}
        >
          <RotateCcw className="h-4 w-4 text-muted-foreground hover:text-primary transition-colors" />
        </Button>
      ) : (
        <>
          <Button
            variant="ghost"
            className="h-8 w-8 p-0 hover:bg-transparent focus:ring-2 focus:ring-offset-2"
            onClick={handleCheckboxClick}
            disabled={task.completed}
            data-selected={isSelected}
            data-task-id={task.id}
            data-task-type={task.type}
            data-project-id={task.project?.id}
          >
            {task.completed ? (
              <Check className="h-5 w-5 text-primary" />
            ) : isSelected ? (
              <div className="h-5 w-5 rounded border-2 border-primary bg-primary/10 flex items-center justify-center">
                <Check className="h-4 w-4 text-primary" />
              </div>
            ) : (
              <div className="h-5 w-5 rounded border-2 border-muted-foreground" />
            )}
          </Button>

          {selectedCount > 0 && (
            <div className="fixed bottom-6 right-6 z-50">
              <div className="bg-background rounded-lg shadow-lg border p-2 min-w-[280px]">
                <div className="px-2 py-1.5 text-sm text-muted-foreground mb-2">
                  {selectedCount} items selected
                </div>

                <Button
                  variant="ghost"
                  className="w-full justify-start text-left py-3 px-4 mb-1"
                  onClick={handleCompleteSelectedTasks}
                  disabled={loading}
                >
                  {loading ? (
                    <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                  ) : (
                    <>Mark {selectedCount} tasks as complete</>
                  )}
                </Button>

                <div className="h-px bg-border my-1" />

                <Button
                  variant="ghost"
                  className="w-full justify-start text-left py-3 px-4 text-destructive hover:text-destructive"
                  onClick={handleDeleteSelectedTasks}
                  disabled={loading}
                >
                  {loading ? (
                    <div className="flex items-center">
                      <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                      Deleting tasks...
                    </div>
                  ) : (
                    <>Delete {selectedCount} tasks</>
                  )}
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default TaskCheckbox;