// components/projects/EmptyState.jsx
import { Plus } from "lucide-react"
import { Button } from "../ui/button"
import projectsIllustration from "../../assets/projects_empty.png"

export function EmptyState({ onCreateClick }) {
  return (
    <div className="mx-auto mt-4 max-w-3xl rounded-lg border bg-card p-6 text-center">
      <div className="flex flex-col items-center gap-6">
        <img
          src={projectsIllustration}
          alt="Projects illustration"
          className="h-auto w-full max-w-3xl"
        />
        <p className="max-w-3xl text-muted-foreground">
          Create assignments and projects to organize your work efficiently. Break
          down complex tasks, set deadlines and let our advanced scheduling algorithm
          place these directly onto your calendar.
        </p>
        <Button
          variant="default"
          size="lg"
          onClick={onCreateClick}
          className="gap-2"
        >
          <Plus className="h-5 w-5" />
          Create Your First Project
        </Button>
      </div>
    </div>
  )
}