import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Card, CardContent } from "../../components/ui/card"
import { Progress } from "../../components/ui/progress"
import { Alert, AlertDescription } from "../../components/ui/alert"
import { ScrollArea } from "../../components/ui/scroll-area"
import { Loader2, ArrowRight, FolderKanban } from "lucide-react"
import { Button } from "../../components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog"
import ProjectForm from "../ProjectForm"
import axiosInstance from "../../api/axiosInstance"
import { useAuth } from "../../contexts/AuthContext"

const MAX_FREE_PROJECTS = 2

const calculateCompletionPercentage = (tasks) => {
  const totalMinutes = tasks.reduce(
    (total, task) => total + task.estimated_hours * 60 + task.estimated_minutes,
    0
  )
  const completedMinutes = tasks.reduce(
    (completed, task) =>
      task.completed
        ? completed + task.estimated_hours * 60 + task.estimated_minutes
        : completed,
    0
  )
  return totalMinutes > 0 ? (completedMinutes / totalMinutes) * 100 : 0
}

const getProjectStatus = (project) => {
  const now = new Date()
  const startDate = project.start_time ? new Date(project.start_time) : new Date()
  const deadline = new Date(project.deadline)

  if (now < startDate) {
    return "to-start"
  } else if (now > deadline) {
    return "overdue"
  } else {
    return "in-progress"
  }
}

const EmptyState = ({ onOpenDialog }) => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-4 p-6">
      <FolderKanban className="h-12 w-12 text-muted-foreground" />
      <div className="text-center">
        <h3 className="font-medium">No Projects Yet</h3>
        <p className="mt-1 text-sm text-muted-foreground">
          Create your first project to get started
        </p>
      </div>
      <Button onClick={onOpenDialog} className="mt-2">
        Add Project
      </Button>
    </div>
  )
}

function ProjectItem({ project }) {
  const completion = calculateCompletionPercentage(project.tasks)
  const status = getProjectStatus(project)

  const statusColors = {
    "to-start": "text-blue-500",
    "in-progress": "text-green-500",
    "overdue": "text-red-500"
  }

  const statusText = {
    "to-start": "To Start",
    "in-progress": "In Progress",
    "overdue": "Overdue"
  }

  return (
    <div className="space-y-2 rounded-lg border p-2 hover:bg-muted/50">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <div
            className="h-3 w-3 rounded-full"
            style={{ backgroundColor: project.color }}
          />
          <span className="text-sm font-medium">{project.name}</span>
        </div>
        <span className={`text-xs ${statusColors[status]}`}>
          {statusText[status]}
        </span>
      </div>

      <div className="space-y-1">
        <Progress value={completion} className="h-1.5" />
        <div className="flex justify-between text-xs text-muted-foreground">
          <span>Progress</span>
          <span>{Math.round(completion)}%</span>
        </div>
      </div>

      <div className="text-xs text-muted-foreground">
        Due {new Date(project.deadline).toLocaleDateString()}
      </div>
    </div>
  )
}

function MiniProjects() {
  const [projects, setProjects] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { auth } = useAuth()

  const isAtProjectLimit = !auth.isSubscribed && projects.length >= MAX_FREE_PROJECTS

  const fetchProjects = async () => {
    try {
      const response = await axiosInstance.get("/api/projects")
      const activeProjects = response.data.projects
        .filter(project => !project.completed)
        .sort((a, b) => new Date(a.deadline) - new Date(b.deadline))
        .slice(0, 4) // Show only top 4 active projects
      setProjects(activeProjects)
      setError(null)
    } catch (err) {
      console.error("Error fetching projects:", err)
      setError("Failed to load projects")
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchProjects()
    const interval = setInterval(fetchProjects, 5 * 60 * 1000) // Refresh every 5 minutes
    return () => clearInterval(interval)
  }, [])

  const handleProjectAdded = () => {
    setIsDialogOpen(false)
    fetchProjects()
  }

  if (loading) {
    return (
      <div className="flex h-full items-center justify-center">
        <Loader2 className="h-6 w-6 animate-spin text-muted-foreground" />
      </div>
    )
  }

  if (error) {
    return (
      <div className="flex h-full items-center justify-center p-4">
        <Alert variant="destructive">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      </div>
    )
  }

  return (
    <div className="flex h-full flex-col">
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        {/* Header section */}
        <div className="mb-4 flex-none">
          <div className="flex items-center justify-between">
            <h3 className="text-xl font-medium">Active Projects</h3>
            <div className="flex items-center gap-4">
              {isAtProjectLimit ? (
                <Link to="/subscribe">
                  <Button variant="outline" size="sm">
                    Upgrade
                  </Button>
                </Link>
              ) : (
                <DialogTrigger asChild>
                  <Button variant="outline" size="sm">
                    Add Project
                  </Button>
                </DialogTrigger>
              )}
              <Link
                to="/taskboard#projects"
                className="flex items-center gap-1 text-xs text-muted-foreground hover:text-primary"
              >
                Go to Projects
                <ArrowRight className="h-3 w-3" />
              </Link>
            </div>
          </div>
        </div>

        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Create New Project</DialogTitle>
            <DialogDescription>
              Set up a new project and add it to your workspace.
            </DialogDescription>
          </DialogHeader>
          <ProjectForm
            onClose={handleProjectAdded}
            refreshProjects={fetchProjects}
          />
        </DialogContent>
      </Dialog>

      {/* Content section */}
      <div className="min-h-0 flex-1">
        {projects.length === 0 ? (
          <EmptyState onOpenDialog={() => setIsDialogOpen(true)} />
        ) : (
          <ScrollArea className="h-full">
            <div className="space-y-3 pr-4">
              {projects.map((project) => (
                <ProjectItem key={project.id} project={project} />
              ))}
            </div>
          </ScrollArea>
        )}
      </div>
    </div>
  );
}

export default MiniProjects