import React, { useState, useEffect } from 'react';
import NoteCard from './NoteCard';
import AddNoteButton from './AddNoteButton';
import axiosInstance from '../../api/axiosInstance';
import { useToast } from '../ui/toast';
import { Loader2 } from 'lucide-react';

const NotesBoard = () => {
  const [notes, setNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { toast } = useToast();

  useEffect(() => {
    fetchNotes();
  }, []);

  const fetchNotes = async () => {
    try {
      const response = await axiosInstance.get('/api/notes');
      setNotes(response.data.notes);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to fetch notes",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddNote = () => {
    const newNote = {
      id: `temp-${Date.now()}`,
      title: '',
      content: '',
      color: getRandomColor(),
      isNew: true
    };
    setNotes([...notes, newNote]);
  };

  const handleNoteSave = async (noteToSave, isAutoSave = false) => {
    try {
      if (noteToSave.isNew) {
        // Create new note
        const response = await axiosInstance.post('/api/notes', {
          title: noteToSave.title,
          content: noteToSave.content,
          color: noteToSave.color
        });
        setNotes(notes.map(note => 
          note.id === noteToSave.id ? response.data.note : note
        ));
        if (!isAutoSave) {
          toast({
            title: "Success",
            description: "Note created successfully",
          });
        }
      } else {
        // Update existing note
        const response = await axiosInstance.put(`/api/notes/${noteToSave.id}`, {
          title: noteToSave.title,
          content: noteToSave.content,
          color: noteToSave.color
        });
        setNotes(notes.map(note => 
          note.id === noteToSave.id ? response.data.note : note
        ));
        if (!isAutoSave) {
          toast({
            title: "Success",
            description: "Note updated successfully",
          });
        }
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to save note",
        variant: "destructive",
      });
    }
  };

  const handleNoteDelete = async (id) => {
    try {
      // If it's a temporary note, just remove it from state
      if (id.startsWith('temp-')) {
        setNotes(notes.filter(note => note.id !== id));
        return;
      }

      // Otherwise, delete from backend
      await axiosInstance.delete(`/api/notes/${id}`);
      setNotes(notes.filter(note => note.id !== id));
      toast({
        title: "Success",
        description: "Note deleted successfully",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to delete note",
        variant: "destructive",
      });
    }
  };

  const handleConvertToTask = async (noteId) => {
    try {
      const response = await axiosInstance.post(`/api/notes/${noteId}/convert-to-task`);
      if (response.data.task) {
        setNotes(notes.filter(note => note.id !== noteId));
        toast({
          title: "Success",
          description: "Note converted to task successfully",
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to convert note to task",
        variant: "destructive",
      });
    }
  };

  const handleConvertToProject = async (noteId) => {
    try {
      const response = await axiosInstance.post(`/api/notes/${noteId}/convert-to-project`);
      if (response.data.project) {
        setNotes(notes.filter(note => note.id !== noteId));
        toast({
          title: "Success",
          description: "Note converted to project successfully",
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.error || "Failed to convert note to project",
        variant: "destructive",
      });
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[200px]">
        <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {notes.map(note => (
          <div className="h-[250px]" key={note.id}>
            <NoteCard
              note={note}
              onSave={handleNoteSave}
              onDelete={handleNoteDelete}
              onConvertToTask={handleConvertToTask}
              onConvertToProject={handleConvertToProject}
            />
          </div>
        ))}
        <div className="h-[250px]">
          <AddNoteButton onClick={handleAddNote} />
        </div>
      </div>
    </div>
  );
};

export default NotesBoard;

const getRandomColor = () => {
  const colors = [
    'bg-yellow-50',  // Light yellow
    'bg-blue-50',    // Light blue
    'bg-green-50',   // Light green
    'bg-pink-50',    // Light pink
    'bg-purple-50',  // Light purple
    'bg-orange-50',  // Light orange
    'bg-indigo-50',  // Light indigo
    'bg-teal-50',    // Light teal
  ];
  return colors[Math.floor(Math.random() * colors.length)];
}; 