import { useState, useEffect } from "react"
import { Card, CardContent } from "../../components/ui/card"
import { Link } from "react-router-dom"
import { Badge } from "../../components/ui/badge"
import { Alert, AlertDescription } from "../../components/ui/alert"
import { ScrollArea } from "../../components/ui/scroll-area"
import { CalendarClock, Loader2, ArrowRight, ListTodo } from "lucide-react"
import { Button } from "../../components/ui/button"
import { useAuth } from "../../contexts/AuthContext"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog"
import TaskForm from "../TaskForm"
import axiosInstance from "../../api/axiosInstance"

const MAX_FREE_TASKS = 5

const TAG_STYLES = {
  Email: "border-blue-400 text-blue-500 bg-blue-50 dark:bg-blue-500/10",
  Admin: "border-purple-400 text-purple-500 bg-purple-50 dark:bg-purple-500/10",
  Planning: "border-green-400 text-green-500 bg-green-50 dark:bg-green-500/10",
  Research: "border-amber-400 text-amber-500 bg-amber-50 dark:bg-amber-500/10",
  Learning: "border-pink-400 text-pink-500 bg-pink-50 dark:bg-pink-500/10",
  Implementation: "border-indigo-400 text-indigo-500 bg-indigo-50 dark:bg-indigo-500/10",
  Other: "border-gray-400 text-gray-500 bg-gray-50 dark:bg-gray-500/10"
}

function TaskItem({ task }) {
  return (
    <div className="flex items-start gap-2 rounded-lg border p-2 hover:bg-muted/50">
      <div className="flex-1 space-y-1">
        <div className="flex items-center gap-2">
          <Badge
            variant="outline"
            className={`${TAG_STYLES[task.tag]} border text-xs`}
          >
            {task.tag || "Other"}
          </Badge>
          <span className="text-sm font-medium">{task.name}</span>
        </div>
        {task.due_date && (
          <div className="flex items-center gap-1 text-xs text-muted-foreground">
            <CalendarClock className="h-3 w-3" />
            <span>{new Date(task.due_date).toLocaleDateString()}</span>
          </div>
        )}
      </div>
    </div>
  )
}

const EmptyState = ({ onOpenDialog }) => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center mt-[-16px] gap-4 p-6">
      <ListTodo className="h-12 w-12 text-muted-foreground" />
      <div className="text-center">
        <h3 className="font-medium">No Tasks Yet</h3>
        <p className="mt-1 text-sm text-muted-foreground">
          Create your first task to get started
        </p>
      </div>
      <Button onClick={onOpenDialog} className="mt-2">
        Add Task
      </Button>
    </div>
  )
}

function MiniTasks() {
  const [tasks, setTasks] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { auth } = useAuth()

  const isAtTaskLimit = !auth.isSubscribed && tasks.length >= MAX_FREE_TASKS

  const fetchTasks = async () => {
    try {
      const response = await axiosInstance.get("/api/tasks")
      const allTasks = [...response.data.scheduled, ...response.data.unscheduled]
        .filter(task => !task.completed)
        .sort((a, b) => {
          if (!a.due_date) return 1
          if (!b.due_date) return -1
          return new Date(a.due_date) - new Date(b.due_date)
        })
        .slice(0, 10)
      setTasks(allTasks)
      setError(null)
    } catch (err) {
      console.error("Error fetching tasks:", err)
      setError("Failed to load tasks")
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchTasks()
    const interval = setInterval(fetchTasks, 5 * 60 * 1000)
    return () => clearInterval(interval)
  }, [])

  const handleOpenChange = (open) => {
    setIsDialogOpen(open)
    if (!open) {
      fetchTasks()
    }
  }

  const handleTaskAdded = async () => {
    setIsDialogOpen(false)
    await fetchTasks()
  }

  if (loading) {
    return (
      <div className="flex h-full items-center justify-center">
        <Loader2 className="h-6 w-6 animate-spin text-muted-foreground" />
      </div>
    )
  }

  if (error) {
    return (
      <div className="flex h-full items-center justify-center p-4">
        <Alert variant="destructive">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      </div>
    )
  }

  return (
    <div className="flex h-full flex-col">
      <Dialog open={isDialogOpen} onOpenChange={handleOpenChange}>
        {/* Header section */}
        <div className="mb-4 flex-none">
          <div className="flex items-center justify-between">
            <h3 className="text-xl font-medium">Current Tasks</h3>
            <div className="flex items-center gap-4">
              {isAtTaskLimit ? (
                <Link to="/subscribe">
                  <Button variant="outline" size="sm">
                    Upgrade
                  </Button>
                </Link>
              ) : (
                <DialogTrigger asChild>
                  <Button variant="outline" size="sm">
                    Add Task
                  </Button>
                </DialogTrigger>
              )}
              <Link
                to="/taskboard#tasks"
                className="flex items-center gap-1 text-xs text-muted-foreground hover:text-primary"
              >
                Go to Tasks
                <ArrowRight className="h-3 w-3" />
              </Link>
            </div>
          </div>
        </div>

        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Add New Task</DialogTitle>
          </DialogHeader>
          <TaskForm
              onClose={handleTaskAdded}
              refreshTasks={fetchTasks}
          />
        </DialogContent>
      </Dialog>

      {/* Content section */}
      <div className="min-h-0 flex-1">
        {tasks.length === 0 ? (
          <EmptyState onOpenDialog={() => setIsDialogOpen(true)} />
        ) : (
          <ScrollArea className="h-full">
            <div className="space-y-3 pr-4">
              {tasks.map((task) => (
                <TaskItem key={task.id} task={task} />
              ))}
            </div>
          </ScrollArea>
        )}
      </div>
    </div>
  )
}

export default MiniTasks