import { useState, useEffect } from "react";
import { Input } from "../ui/input";

export function EditableCell({
  value,
  onEdit,
  type = "text",
  className = "",
  placeholder = "",
  displayValue,
  min,
  max
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);

  // Update currentValue when value prop changes
  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
    if (e.key === 'Escape') {
      setCurrentValue(value);
      setIsEditing(false);
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
    if (currentValue !== value) {
      onEdit(currentValue);
    }
  };

  const handleClick = (e) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  if (!isEditing) {
    return (
      <span
        className={`cursor-pointer ${className} ${!value ? "text-muted-foreground italic" : ""}`}
        onClick={handleClick}
      >
        {displayValue || value || placeholder}
      </span>
    );
  }

  return (
    <Input
      type={type}
      autoFocus
      value={currentValue || ""}
      onChange={(e) => setCurrentValue(e.target.value)}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
      min={min}
      max={max}
      className="h-8"
    />
  );
}

// Optional prop types for better documentation
EditableCell.defaultProps = {
  type: "text",
  className: "",
  placeholder: "",
  displayValue: null,
  min: null,
  max: null
};