import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from '../api/axiosInstance';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [auth, setAuth] = useState(() => {
    const storedAuth = localStorage.getItem('auth');
    const isInOAuthFlow = localStorage.getItem('oauthFlow') === 'true';

    if (isInOAuthFlow) {
      localStorage.removeItem('oauthFlow');
    }

    return storedAuth
      ? { ...JSON.parse(storedAuth), isInOAuthFlow }
      : {
          isLoggedIn: false,
          userEmail: null,
          isSubscribed: false,
          isEmailVerified: false,
          isOnboardingComplete: false,
          calendarType: '',
          isCheckingSession: true,
          isInOAuthFlow,
          is_in_trial: false,
          trial_days_remaining: null,
          subscription_status: null
        };
  });

  // Handle OAuth callback params
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const isAuth = params.get('auth');
    const email = params.get('email');
    const calendarType = params.get('calendar_type');

    console.log('URL Params:', { isAuth, email, calendarType });

    if (isAuth === 'true' && email && calendarType) {
      console.log('Setting auth state from OAuth callback');
      setAuth(prev => ({
        ...prev,
        isLoggedIn: true,
        userEmail: email,
        calendarType: calendarType,
        isCheckingSession: false,
        isInOAuthFlow: false,
        isEmailVerified: true,  // OAuth users are verified by default
      }));

      // Remove auth params from URL
      const cleanUrl = location.pathname;
      navigate(cleanUrl, { replace: true });
    }
  }, [location.search, navigate]);

  useEffect(() => {
    localStorage.setItem('auth', JSON.stringify(auth));
  }, [auth]);

  useEffect(() => {
    if (auth.isLoggedIn && !auth.isCheckingSession && auth.userEmail) {
      window.pendo?.initialize({
        visitor: {
          id: auth.userEmail,
          email: auth.userEmail,
          isEmailVerified: auth.isEmailVerified,
          calendarType: auth.calendarType || 'none',
          isOnboardingComplete: auth.isOnboardingComplete,
          subscriptionStatus: auth.isSubscribed ? 'subscribed' : 'free'
        },
        account: {
          id: auth.userEmail.split('@')[1],
        }
      });
    } else if (!auth.isLoggedIn && window.pendo) {
      window.pendo.initialize({
        visitor: { id: null },
        account: { id: null }
      });
    }
  }, [auth.isLoggedIn, auth.isCheckingSession, auth.userEmail, auth.isEmailVerified,
      auth.calendarType, auth.isOnboardingComplete, auth.isSubscribed]);

  const handleLogoutAndRedirect = useCallback(() => {
    if (window.pendo) {
      window.pendo.initialize({
        visitor: { id: null },
        account: { id: null }
      });
    }

    setAuth({
      isLoggedIn: false,
      userEmail: null,
      isSubscribed: false,
      isEmailVerified: false,
      isOnboardingComplete: false,
      calendarType: '',
      isCheckingSession: false,
      isInOAuthFlow: false,
      is_in_trial: false,
      trial_days_remaining: null,
      subscription_status: null
    });
    navigate('/login', { replace: true });
  }, [navigate]);

  const refreshAndCheckSession = useCallback(async () => {
    try {
      await axiosInstance.post('/api/user/refresh_token');
      const response = await axiosInstance.get('/api/user/check_session');

      if (!response.data.isLoggedIn) {
        setAuth(prev => ({
          ...prev,
          isLoggedIn: false,
          isCheckingSession: false,
          isInOAuthFlow: false
        }));
        return false;
      }

      setAuth(prev => ({
        ...prev,
        isLoggedIn: true,
        userEmail: response.data.userEmail,
        isSubscribed: response.data.is_subscribed,
        isEmailVerified: response.data.is_email_verified,
        isOnboardingComplete: response.data.is_onboarding_complete,
        calendarType: response.data.calendar_type || '',
        isCheckingSession: false,
        isInOAuthFlow: false,
        redeemedPromo: response.data.redeemed_promo || false,
        is_in_trial: response.data.is_in_trial || false,
        trial_days_remaining: response.data.trial_days_remaining || null,
        subscription_status: response.data.subscription_status || null
      }));
      return true;

    } catch (error) {
      console.error('Session refresh/check failed:', error);
      if (error.response?.status === 401) {
        handleLogoutAndRedirect();
      }
      return false;
    }
  }, [handleLogoutAndRedirect]);

  useEffect(() => {
    const isPublicPath =
      location.pathname === '/login' ||
      location.pathname === '/register' ||
      location.pathname === '/forgot-password' ||
      location.pathname.startsWith('/reset-password/') ||
      location.pathname === '/reset';

    const isOAuthCallback =
      location.pathname.includes('/api/google/connect-calendar') ||
      location.pathname.includes('/api/outlook/callback');

    // Skip if we're on a public path or in OAuth flow
    if (isPublicPath || isOAuthCallback) {
      setAuth(prev => ({ ...prev, isCheckingSession: false }));
      return;
    }

    // Skip if we're already logged in and not checking session
    if (auth.isLoggedIn && !auth.isCheckingSession) {
      return;
    }

    let isActive = true;

    const initializeAuth = async () => {
      if (!auth.isCheckingSession) {
        try {
          setAuth(prev => ({ ...prev, isCheckingSession: true }));
          const isAuthenticated = await refreshAndCheckSession();
          
          if (isActive) {
            if (!isAuthenticated) {
              handleLogoutAndRedirect();
            } else {
              setAuth(prev => ({ ...prev, isCheckingSession: false }));
            }
          }
        } catch (error) {
          console.error('Auth initialization error:', error);
          if (isActive) {
            handleLogoutAndRedirect();
          }
        }
      }
    };

    // Only run initial auth check if not logged in
    if (!auth.isLoggedIn) {
      initializeAuth();
    }

    // Set up refresh interval only if logged in
    let intervalId;
    if (auth.isLoggedIn) {
      intervalId = setInterval(() => {
        if (isActive && !auth.isCheckingSession) {
          refreshAndCheckSession();
        }
      }, 15 * 60 * 1000); // 15 minutes
    }

    return () => {
      isActive = false;
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [location.pathname, auth.isLoggedIn, auth.isCheckingSession]);

  const login = async (email, password) => {
    try {
      const response = await axiosInstance.post('/api/user/login', { email, password });

      if (response.status === 200) {
        const { 
          emailVerified, 
          isSubscribed, 
          isOnboardingComplete, 
          calendar_type,
          is_in_trial,
          trial_days_remaining,
          subscription_status 
        } = response.data;

        setAuth({
          isLoggedIn: true,
          userEmail: email,
          isSubscribed: isSubscribed,
          isEmailVerified: emailVerified,
          isOnboardingComplete: isOnboardingComplete || false,
          calendarType: calendar_type || '',
          isCheckingSession: false,
          isInOAuthFlow: false,
          is_in_trial: is_in_trial || false,
          trial_days_remaining: trial_days_remaining || null,
          subscription_status: subscription_status || null
        });

        await refreshAndCheckSession();
        navigate(emailVerified ? '/dashboard' : '/verify');
      } else {
        throw new Error('Login unsuccessful');
      }
    } catch (error) {
      console.error('Login error:', error);
      throw new Error(error.response?.data?.message || 'Login failed. Please try again.');
    }
  };

  const register = async (email, password) => {
    try {
      const fbp = document.cookie.split('; ').find(row => row.startsWith('_fbp='))?.split('=')[1] || '';
      const fbc = document.cookie.split('; ').find(row => row.startsWith('_fbc='))?.split('=')[1] || '';

      const response = await axiosInstance.post('/api/user/register', {
        email,
        password,
        fbp,
        fbc
      });

      if (response.status === 201) {
        // Get the tokens from the response for mobile clients
        const { access_token, refresh_token, csrf_token } = response.data;

        setAuth({
          isLoggedIn: true,
          userEmail: email,
          isSubscribed: false,
          isEmailVerified: false,
          isOnboardingComplete: false,
          calendarType: '',
          isCheckingSession: false,
          isInOAuthFlow: false,
          is_in_trial: false,
          trial_days_remaining: null,
          subscription_status: null
        });

        // Navigate to verify page immediately after successful registration
        navigate('/verify');
      } else {
        throw new Error(response.data.message || 'Registration failed');
      }
    } catch (error) {
      console.error('Registration error:', error);
      throw new Error(error.response?.data?.message || 'Registration failed. Please try again.');
    }
  };

  const logout = async () => {
    try {
      await axiosInstance.post('/api/user/logout', {}, { withCredentials: true });
      handleLogoutAndRedirect();
    } catch (error) {
      console.error('Logout error:', error);
      handleLogoutAndRedirect();
    }
  };

  const microsoftSignIn = async () => {
    try {
      // Get Facebook pixel cookies
      const fbp = document.cookie.split('; ').find(row => row.startsWith('_fbp='))?.split('=')[1] || '';
      const fbc = document.cookie.split('; ').find(row => row.startsWith('_fbc='))?.split('=')[1] || '';

      localStorage.setItem('oauthFlow', 'true');
      setAuth(prev => ({ ...prev, isInOAuthFlow: true }));

      // Add fbp and fbc as query parameters
      const response = await axiosInstance.get(`/api/user/outlook/auth?fbp=${fbp}&fbc=${fbc}`);

      if (response.data.authorization_url) {
        window.location.href = response.data.authorization_url;
      }
    } catch (error) {
      localStorage.removeItem('oauthFlow');
      setAuth(prev => ({ ...prev, isInOAuthFlow: false }));
      throw new Error(error.response?.data?.message || 'Failed to connect to Microsoft');
    }
  };

  const googleSignIn = async () => {
    try {
      // Get Facebook pixel cookies
      const fbp = document.cookie.split('; ').find(row => row.startsWith('_fbp='))?.split('=')[1] || '';
      const fbc = document.cookie.split('; ').find(row => row.startsWith('_fbc='))?.split('=')[1] || '';

      localStorage.setItem('oauthFlow', 'true');
      setAuth(prev => ({ ...prev, isInOAuthFlow: true }));

      // Add fbp and fbc as query parameters
      const response = await axiosInstance.get(`/api/user/google/auth?fbp=${fbp}&fbc=${fbc}`);

      if (response.data.authorization_url) {
        window.location.href = response.data.authorization_url;
      }
    } catch (error) {
      localStorage.removeItem('oauthFlow');
      setAuth(prev => ({ ...prev, isInOAuthFlow: false }));
      throw new Error(error.response?.data?.message || 'Failed to connect to Google');
    }
  };

  return (
    <AuthContext.Provider value={{
      auth,
      setAuth,
      login,
      logout,
      refreshAndCheckSession,
      register,
      microsoftSignIn,
      googleSignIn
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;