// taskUtils.js

import axiosInstance from '../../api/axiosInstance';

export const PRIORITY_OPTIONS = [
  { value: "urgent_important", label: "Urgent & Important" },
  { value: "important_not_urgent", label: "Important but Not Urgent" },
  { value: "urgent_not_important", label: "Urgent but Not Important" },
  { value: "not_important_not_urgent", label: "Not Important or Urgent" },
];

export const getPriorityColor = (priority) => {
  switch (priority) {
    case "urgent_important":
      return "text-red-500 bg-red-100 dark:bg-red-900/20"
    case "important_not_urgent":
      return "text-orange-500 bg-orange-100 dark:bg-orange-900/20"
    case "urgent_not_important":
      return "text-blue-500 bg-blue-100 dark:bg-blue-900/20"
    case "not_important_not_urgent":
      return "text-gray-500 bg-gray-100 dark:bg-gray-900/20"
    default:
      return "text-gray-500 bg-gray-100 dark:bg-gray-900/20"
  }
};

export const getPriorityDisplay = (priority) => {
  switch (priority) {
    case "urgent_important":
      return "Urgent & Important"
    case "important_not_urgent":
      return "Important but Not Urgent"
    case "urgent_not_important":
      return "Urgent but Not Important"
    case "not_important_not_urgent":
      return "Not Important or Urgent"
    default:
      return priority
  }
};

export const formatDateTimeLocal = (dateString) => {
  if (!dateString) return '';
  
  try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      console.warn('Invalid date:', dateString);
      return '';
    }
    
    // Format to YYYY-MM-DDThh:mm
    return date.toISOString().slice(0, 16);
  } catch (error) {
    console.error('Error formatting date:', error);
    return '';
  }
};

export const canBeScheduled = (task) => {
  return task.estimated_minutes > 0 && task.due_date;
};

export const sortTasks = (tasks, sortDirection, calendarEvents) => {
  if (sortDirection === "schedule" && calendarEvents?.length > 0) {
    // First, create a map of task IDs to their earliest scheduled time
    const taskScheduleMap = new Map();

    calendarEvents
      .filter(event => (event.calendar_type === 'task' || event.calendar_type === 'project_task'))
      .forEach(event => {
        const taskId = event.parent_id;
        const startTime = new Date(event.start);

        // Only store the earliest time for each task
        if (!taskScheduleMap.has(taskId) || startTime < taskScheduleMap.get(taskId)) {
          taskScheduleMap.set(taskId, startTime);
        }
      });

    return [...tasks].sort((a, b) => {
      const timeA = taskScheduleMap.get(a.id);
      const timeB = taskScheduleMap.get(b.id);

      // Put scheduled tasks first, sorted by their scheduled time
      if (timeA && timeB) return timeA - timeB;
      if (timeA) return -1;
      if (timeB) return 1;

      // For unscheduled tasks, sort by deadline/due date
      const dateA = new Date(a.type === 'project_task' ? a.deadline : a.due_date || "9999-12-31");
      const dateB = new Date(b.type === 'project_task' ? b.deadline : b.due_date || "9999-12-31");
      return dateA - dateB;
    });
  } else {
    // Regular date sorting for asc/desc
    return [...tasks].sort((a, b) => {
      const dateA = new Date(a.type === 'project_task' ? a.deadline : a.due_date || "9999-12-31");
      const dateB = new Date(b.type === 'project_task' ? b.deadline : b.due_date || "9999-12-31");
      return sortDirection === "asc" ? dateA - dateB : dateB - dateA;
    });
  }
};

// Helper function to filter tasks
export const filterTasks = (tasks, searchQuery) => {
  return tasks.filter(task =>
    task.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    task.description?.toLowerCase().includes(searchQuery.toLowerCase())
  );
};


// In taskUtils.js
export const FILTER_OPTIONS = [
  { value: "all", label: "All Tasks" },
  { value: "standalone", label: "Standalone Tasks" },
  { value: "project", label: "Project Tasks" },
];


// Get sort button text helper
export const getSortButtonText = (direction) => {
  switch (direction) {
    case "schedule": return "Sort by Schedule";
    case "asc": return "Sort by Due Date (Asc)";
    case "desc": return "Sort by Due Date (Desc)";
  }
};

// Update getTagStyle to work with the new tag format
export const getTagStyle = (tagName, tags) => {
  if (!tags || !Array.isArray(tags)) {
    return "border-gray-400 text-gray-500 bg-gray-50 dark:bg-gray-500/10";
  }
  
  const tag = tags.find(t => t.name === tagName);
  if (!tag) {
    return "border-gray-400 text-gray-500 bg-gray-50 dark:bg-gray-500/10";
  }
  
  // Return just the className, and handle the color via inline style in the component
  return "border";
};

// Add helper function to get tag options for dropdowns/selects
export const getTagOptions = (tags) => {
  if (!tags || !Array.isArray(tags)) return [];
  
  return tags.map(tag => ({
    value: tag.name,
    label: tag.name,
    color: tag.color
  }));
};