export const COLORS = [
  "#B5A1FF", // Light violet
  "#FFE599", // Soft yellow
  "#FF9999", // Light red
  "#6CCFF6", // Custom cyan blue
  "#F3A160", // Custom orange
  "#2E3060", // Custom dark blue
  "#C6D8AF", // Custom light green
  "#9999C3", // Custom soft purple
  "#FFCC99", // Light peach
  "#A3D9FF", // Soft sky blue
]

export const AVAILABLE_TAGS = [
  "Email",
  "Admin",
  "Planning",
  "Research",
  "Learning",
  "Implementation",
  "Other"
]

export const MAX_FREE_TASKS = 3

export function formatDateTimeLocal(dateString) {
  if (!dateString) return ""
  const date = new Date(dateString)
  const offset = date.getTimezoneOffset()
  const localDate = new Date(date.getTime() - offset * 60 * 1000)
  return localDate.toISOString().slice(0, 16)
}

export function getProjectStatus(project) {
  const now = new Date()
  const startDate = project.start_time ? new Date(project.start_time) : new Date()
  const deadline = project.deadline ? new Date(project.deadline) : undefined

  if (!deadline) {
    return undefined
  } else if (now < startDate) {
    return "to-start"
  } else if (now > deadline) {
    return "overdue"
  } else {
    return "in-progress"
  }
}