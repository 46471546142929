import { ChevronDown } from "lucide-react"
import { Input } from "../../ui/input"
import { Button } from "../../ui/button"
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "../../ui/dropdown-menu"
import { AVAILABLE_TAGS } from "../constants"

export function NewTaskForm({
  taskName,
  taskHours,
  taskMinutes,
  taskTag,
  taskDueDate,
  onTaskNameChange,
  onTaskHoursChange,
  onTaskMinutesChange,
  onTaskTagChange,
  onTaskDueDateChange,
  onAddTask,
}) {
  return (
    <div className="mb-4 grid grid-cols-12 gap-4 rounded-md border bg-card p-4">
      <div className="col-span-3">
        <Input
          placeholder="Task name"
          value={taskName}
          onChange={(e) => onTaskNameChange(e.target.value)}
        />
      </div>
      <div className="col-span-2">
        <Input
          type="number"
          placeholder="Hours"
          value={taskHours}
          onChange={(e) => onTaskHoursChange(e.target.value)}
          min="0"
          max="999"
        />
      </div>
      <div className="col-span-2">
        <Input
          type="number"
          placeholder="Minutes"
          value={taskMinutes}
          onChange={(e) => onTaskMinutesChange(e.target.value)}
          min="0"
          max="59"
        />
      </div>
      <div className="col-span-2">
        <Input
          type="datetime-local"
          value={taskDueDate || ''}
          onChange={(e) => onTaskDueDateChange(e.target.value)}
          className="bg-muted"
        />
      </div>
      <div className="col-span-2">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="w-full justify-between">
              {taskTag || "Select tag"}
              <ChevronDown className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {AVAILABLE_TAGS.map((tag) => (
              <DropdownMenuItem
                key={tag}
                onClick={() => onTaskTagChange(tag)}
              >
                {tag}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="col-span-1">
        <Button
          className="w-full"
          onClick={onAddTask}
        >
          Add
        </Button>
      </div>
    </div>
  )
}