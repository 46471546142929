import React from "react"
import { Popover, PopoverContent, PopoverTrigger } from "./popover"
import { cn } from "../../lib/utils"

const COLORS = {
  red: "#ef4444",
  orange: "#f97316", 
  amber: "#f59e0b",
  yellow: "#eab308",
  lime: "#84cc16",
  green: "#22c55e",
  emerald: "#10b981",
  teal: "#14b8a6",
  cyan: "#06b6d4",
  sky: "#0ea5e9",
  blue: "#3b82f6",
  indigo: "#6366f1",
  violet: "#8b5cf6",
  purple: "#a855f7",
  fuchsia: "#d946ef",
  pink: "#ec4899",
  rose: "#f43f5e",
  slate: "#64748b",
}

export function ColorPicker({ color, onChange, className }) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <button
          className={cn(
            "h-10 w-10 rounded-md border border-input",
            className
          )}
          style={{ backgroundColor: color }}
        />
      </PopoverTrigger>
      <PopoverContent className="w-64">
        <div className="grid grid-cols-6 gap-2">
          {Object.entries(COLORS).map(([name, hex]) => (
            <button
              key={name}
              className={cn(
                "h-6 w-6 rounded-md border border-input",
                color === hex && "ring-2 ring-ring ring-offset-2"
              )}
              style={{ backgroundColor: hex }}
              onClick={() => onChange({ name, hex })}
            />
          ))}
        </div>
      </PopoverContent>
    </Popover>
  )
} 