import axios from 'axios';
import { clearChatSession } from '../utils/chatUtils';

// Update the API URL configuration
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';
console.log('Environment:', process.env.NODE_ENV);
console.log('API URL being used:', apiUrl);
console.log('Current cookies:', document.cookie);

const axiosInstance = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  timeout: 10000,
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const CHAT_ERRORS = {
  CONNECTION: 'Failed to connect to chat service',
  THREAD_CREATE: 'Failed to create chat thread',
  THREAD_END: 'Failed to end chat session',
  MESSAGE_SEND: 'Failed to send message',
  SESSION_EXPIRED: 'Chat session has expired',
  CONCURRENT_RUN: 'A message is currently being processed',
};

axiosInstance.interceptors.request.use(
  (config) => {
    // Get CSRF token from cookie
    const csrfToken = document.cookie
      .split('; ')
      .find(row => row.startsWith('csrf_access_token='))
      ?.split('=')[1];

    if (csrfToken) {
      // Use lowercase header name consistently
      config.headers['x-csrf-token'] = csrfToken;
    }

    config.headers['X-Client-Type'] = 'web';
    console.log('Starting Request:', {
      url: config.url,
      method: config.method,
      headers: config.headers
    });
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    console.log('Response cookies:', document.cookie);
    console.log('Response:', {
      status: response.status,
      data: response.data,
      headers: response.headers,
      url: response.config.url
    });
    return response;
  },
  async (error) => {
    console.log('Error cookies:', document.cookie);
    const originalRequest = error.config;
    const isChatRequest = originalRequest.url.includes('/api/chat');

    // Handle chat-specific errors
    if (isChatRequest) {
      if (error.response?.status === 409) {
        console.warn('Chat concurrent run detected');
        return Promise.reject(new Error(CHAT_ERRORS.CONCURRENT_RUN));
      }

      if (error.response?.status === 404) {
        console.warn('Chat thread not found');
        clearChatSession();
        return Promise.reject(new Error('Chat session expired or not found'));
      }

      if (error.code === 'ECONNABORTED') {
        console.error('Chat request timed out');
        return Promise.reject(new Error('Chat request timed out. Please try again.'));
      }
    }

    // Handle token refresh
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // Try to refresh the token
        await axiosInstance.post('/api/user/refresh_token');
        
        // Retry the original request
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        // If refresh fails, redirect to login
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }

    // Other error handling
    if (error.response) {
      console.error('API error response:', {
        status: error.response.status,
        data: error.response.data,
        headers: error.response.headers,
        endpoint: originalRequest.url,
      });

      if (error.response.status === 403) {
        console.warn('Forbidden! You don\'t have permission for this action.');
        if (isChatRequest) {
          clearChatSession();
        }
      } else if (error.response.status === 500) {
        console.error('Server error! Please try again later.');
        if (isChatRequest) {
          return Promise.reject(new Error('Chat service unavailable. Please try again later.'));
        }
      }
    } else if (error.request) {
      console.error('No response from API:', error.request);
      if (isChatRequest) {
        return Promise.reject(new Error(CHAT_ERRORS.CONNECTION));
      }
    } else {
      console.error('Error setting up the API request:', error.message);
    }

    // Enhance error messages for chat requests
    if (isChatRequest) {
      let errorMessage = 'An error occurred with the chat service.';
      if (error.response?.data?.message) {
        errorMessage = error.response.data.message;
      } else if (error.message) {
        errorMessage = error.message;
      }
      return Promise.reject(new Error(errorMessage));
    }

    console.error('Response Error:', {
      status: error.response?.status,
      data: error.response?.data,
      headers: error.response?.headers
    });

    if (error.code === 'ERR_NETWORK') {
      console.error('Network Error Details:', {
        message: error.message,
        config: {
          url: error.config?.url,
          method: error.config?.method,
          baseURL: error.config?.baseURL,
          headers: error.config?.headers
        }
      });
    }

    return Promise.reject(error);
  }
);

// Add request retry functionality for chat endpoints
axiosInstance.chat = {
  async retryRequest(config, maxRetries = 3) {
    let retries = 0;
    while (retries < maxRetries) {
      try {
        return await axiosInstance(config);
      } catch (error) {
        retries++;
        if (retries === maxRetries) {
          throw error;
        }
        // Exponential backoff
        await new Promise(resolve => setTimeout(resolve, Math.pow(2, retries) * 1000));
      }
    }
  }
};

export default axiosInstance;