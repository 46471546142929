// components/projects/ProjectHeader.jsx
import { Plus, Wand2, Loader2 } from "lucide-react"
import { Button } from "../ui/button"
import { Input } from "../ui/input"
import { useState } from "react"
import axiosInstance from '../../api/axiosInstance'

export function ProjectHeader({ onAddProject, onGenerateProject }) {
  const [quickProjectName, setQuickProjectName] = useState("")
  const [isGenerating, setIsGenerating] = useState(false)
  const [generationStep, setGenerationStep] = useState("")

  const handleGenerate = async () => {
    if (!quickProjectName.trim()) return

    setIsGenerating(true)
    try {
      setGenerationStep("Analyzing project requirements...")
      await new Promise(resolve => setTimeout(resolve, 1000))

      // Call onGenerateProject with the project name and status updater
      await onGenerateProject(quickProjectName, (status) => {
        setGenerationStep(status)
      })

    } catch (error) {
      console.error("Generation failed:", error)
    } finally {
      setQuickProjectName("")
      setIsGenerating(false)
      setGenerationStep("")
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !isGenerating && quickProjectName.trim()) {
      handleGenerate()
    }
  }

  return (
    <div className="flex justify-between items-center px-6 py-4">
      <div className="flex gap-2">
        <div className="flex gap-2">
          <Input
            placeholder="Enter project description..."
            value={quickProjectName}
            onChange={(e) => setQuickProjectName(e.target.value)}
            onKeyDown={handleKeyDown}
            className="w-[400px]"
          />
          <Button
            onClick={handleGenerate}
            disabled={isGenerating || !quickProjectName.trim()}
            variant="outline"
            className="gap-2 min-w-[140px]"
          >
            {isGenerating ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin" />
                <span className="animate-pulse">
                  {generationStep || "Generating..."}
                </span>
              </>
            ) : (
              <>
                <Wand2 className="h-4 w-4" />
                <span>Generate</span>
              </>
            )}
          </Button>
        </div>
      </div>
    </div>
  )
}