import React, { useState } from 'react';
import Layout from "../components/Layout";
import { Card, CardContent } from "../components/ui/card";
import MiniCalendar from '../components/dashboard/MiniCalendar';
import MiniTasks from '../components/dashboard/MiniTasks';
import MiniProjects from '../components/dashboard/MiniProjects';
import MiniCapacityChart from '../components/dashboard/MiniCapacityChart';
import DorisChatWindow from '../components/DorisChatWindow';
import DashboardHeader from '../components/dashboard/DashboardHeader';
import { useAuth } from "../contexts/AuthContext";
import { useToast } from "../components/ui/toast";
import axiosInstance from "../api/axiosInstance";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../components/ui/dialog";
import Step1WorkSetup from "../components/Step1WorkSetup";
import InviteForm from '../components/InviteForm';

const Dashboard = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isInviteFormOpen, setIsInviteFormOpen] = useState(false);
  const { auth, setAuth } = useAuth();
  const { toast } = useToast();

  // Onboarding state
  const [workStartTime, setWorkStartTime] = useState("");
  const [workEndTime, setWorkEndTime] = useState("");
  const [timezone, setTimezone] = useState("");
  const [referralSource, setReferralSource] = useState("");
  const [profession, setProfession] = useState('')
  const [workdays, setWorkdays] = useState({
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
    Sunday: false,
  });

  const completeOnboarding = async () => {
    try {
      await axiosInstance.post("/api/user/complete-onboarding", null, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setAuth((prev) => ({ ...prev, isOnboardingComplete: true }));
    } catch (error) {
      console.error("Error completing onboarding:", error);
      toast({
        title: "Error",
        description: "Failed to complete onboarding",
        variant: "destructive",
      });
    }
  };

  return (
    <Layout>
      {/* Invite Form Dialog */}
      <Dialog open={isInviteFormOpen} onOpenChange={setIsInviteFormOpen}>
        <DialogContent className="sm:max-w-[500px]">
          <DialogHeader>
            <DialogTitle>Invite</DialogTitle>
          </DialogHeader>
          <InviteForm onClose={() => setIsInviteFormOpen(false)} />
        </DialogContent>
      </Dialog>

      {/* Onboarding Dialog */}
      <Dialog
        open={!auth.isOnboardingComplete}
        onOpenChange={() => {}}
      >
        <DialogContent className="sm:max-w-[500px]">
          <DialogHeader>
            <DialogTitle>Welcome to MeetDoris!</DialogTitle>
            <DialogDescription>
              Let's set up your work schedule to help you be more productive.
            </DialogDescription>
          </DialogHeader>
          <Step1WorkSetup
            completeOnboarding={completeOnboarding}
            setWorkStartTime={setWorkStartTime}
            setWorkEndTime={setWorkEndTime}
            setTimezone={setTimezone}
            setWorkdays={setWorkdays}
            workStartTime={workStartTime}
            workEndTime={workEndTime}
            timezone={timezone}
            workdays={workdays}
            profession={profession}
            setProfession={setProfession}
          />
        </DialogContent>
      </Dialog>

      <div className="flex h-screen w-[calc(100%+24px)] flex-col -m-3 bg-transparent p-6">
        <DashboardHeader
          onOpenChat={() => setIsChatOpen(true)}
          onOpenInvite={() => setIsInviteFormOpen(true)}
        />

        <div className="flex-1 flex flex-col gap-4 overflow-hidden">
          {/* Top row */}
          <div className="h-[55%] overflow-hidden">
            <div className="grid h-full grid-cols-12 gap-4">
              <div className="col-span-12 lg:col-span-6 overflow-hidden">
                <Card className="h-full">
                  <CardContent className="h-full overflow-hidden p-0">
                    <MiniCalendar/>
                  </CardContent>
                </Card>
              </div>

              <div className="col-span-12 lg:col-span-3 overflow-hidden">
                <Card className="h-full">
                  <CardContent className="h-full overflow-hidden p-3">
                    <MiniTasks/>
                  </CardContent>
                </Card>
              </div>

              <div className="col-span-12 lg:col-span-3 overflow-hidden">
                <Card className="h-full">
                  <CardContent className="h-full p-3">
                    <MiniProjects/>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>

          {/* Bottom row */}
          <div className="h-[45%] overflow-hidden">
            <Card className="h-full">
              <CardContent className="h-full p-3">
                <MiniCapacityChart/>
              </CardContent>
            </Card>
          </div>
        </div>

        <DorisChatWindow
          open={isChatOpen}
          onClose={() => setIsChatOpen(false)}
          onMinimize={() => setIsChatOpen(false)}
        />
      </div>
    </Layout>
  );
};

export default Dashboard;