import { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useToast } from "../components/ui/toast";
import axiosInstance from "../api/axiosInstance";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../components/ui/dialog";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../components/ui/tabs";

// Import our separated components
import TasksHeader from "../components/tasks/TasksHeader";
import TasksTable from "../components/tasks/TasksTable";
import TaskForm from "../components/TaskForm";

// Import sort and filter utilities
import { sortTasks, filterTasks } from "../components/tasks/taskUtils";

function TasksPage() {
  const { auth } = useAuth();
  const { toast } = useToast();
  const [activeTab, setActiveTab] = useState("active");
  const [tasks, setTasks] = useState({ scheduled: [], unscheduled: [], completed: [] });
  const [searchQuery, setSearchQuery] = useState("");
  const [sortDirection, setSortDirection] = useState("schedule");
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [activeFilter, setActiveFilter] = useState("all");
  const [tags, setTags] = useState([]);

  const mapTask = (task) => {
    console.log('Mapping task:', {
      original: task,
      type: task.type,
      due_date: task.due_date,
      project_info: task.type === 'project_task' ? {
        deadline: task.project?.deadline,
        raw_project: task.project
      } : null
    });

    if (task.type === 'project_task') {
      const mappedTask = {
        ...task,
        due_date: task.due_date || task.project?.deadline,
        project: {
          ...task.project,
          color: task.project?.hex_color || task.project?.color,
          deadline: task.project?.deadline
        }
      };
      console.log('Mapped project task:', mappedTask);
      return mappedTask;
    }
    return task;
  };

  const fetchTasks = async () => {
    try {
      const [unifiedTasks, completedTasks] = await Promise.all([
        axiosInstance.get("/api/tasks/unified-tasks"),
        axiosInstance.get("/api/tasks/completed")
      ]);

      console.log('Raw unified tasks response:', unifiedTasks.data);

      // Log a sample project task if one exists
      const sampleProjectTask = unifiedTasks.data.tasks.find(t => t.type === 'project_task');
      if (sampleProjectTask) {
        console.log('Sample project task before mapping:', {
          task: sampleProjectTask,
          project: sampleProjectTask.project,
          deadline: sampleProjectTask.project?.deadline
        });
      }

      // Separate unified tasks into scheduled and unscheduled
      const scheduled = unifiedTasks.data.tasks
        .map(mapTask)
        .filter(task => task.type === 'standalone_task' ? task.requires_scheduling : false);

      const unscheduled = unifiedTasks.data.tasks
        .map(mapTask)
        .filter(task => task.type === 'standalone_task' ? !task.requires_scheduling : true);

      const mappedCompleted = completedTasks.data.completed.map(mapTask);

      console.log('Final mapped tasks:', {
        scheduled: scheduled,
        unscheduled: unscheduled,
        completed: mappedCompleted
      });

      setTasks({
        scheduled: scheduled,
        unscheduled: unscheduled,
        completed: mappedCompleted
      });

      // Ensure tags is always an array
      if (unifiedTasks.data.tags) {
        setTags(Array.isArray(unifiedTasks.data.tags) ? unifiedTasks.data.tags : []);
      } else {
        setTags([]);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching tasks:", error);
      setError("Failed to load tasks");
      setLoading(false);
    }
  };

  const fetchCalendarEvents = async () => {
    try {
      const response = await axiosInstance.get("/api/outlook/events");
      setCalendarEvents(response.data.events);
    } catch (error) {
      console.error("Error fetching calendar events:", error);
    }
  };

  const fetchTags = async () => {
    try {
      const response = await axiosInstance.get('/api/user/tags');
      console.log('Fetched tags:', response.data);
      setTags(response.data);
    } catch (error) {
      console.error('Error fetching tags:', error);
      toast({
        title: "Error",
        description: "Failed to load tags.",
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      await Promise.all([
        fetchTasks(),
        fetchCalendarEvents(),
        fetchTags()
      ]);
    };
    fetchInitialData();
  }, []);

  const handleTaskComplete = async (task) => {
    try {
      await axiosInstance.post(`/api/tasks/${task.id}/complete`);
      await fetchTasks();
    } catch (error) {
      console.error("Error completing task:", error);
      toast({
        title: "Error",
        description: "Failed to complete task",
        variant: "destructive",
      });
    }
  };

  const handleTaskUncomplete = async (task) => {
    try {
      await axiosInstance.post(`/api/tasks/${task.id}/uncomplete`);
      await fetchTasks();
      toast({
        title: "Success",
        description: "Task marked as active",
      });
    } catch (error) {
      console.error("Error uncompleting task:", error);
      toast({
        title: "Error",
        description: "Failed to mark task as active",
        variant: "destructive",
      });
    }
  };

  const handleTaskDelete = async (task) => {
    try {
      await axiosInstance.delete(`/api/tasks/${task.id}`);
      await fetchTasks();
    } catch (error) {
      console.error("Error deleting task:", error);
      toast({
        title: "Error",
        description: "Failed to delete task",
        variant: "destructive",
      });
    }
  };

  const handleTaskEdit = async (updatedTask) => {
    try {
      if (updatedTask.type === 'project_task') {
        // Fetch latest project data
        const projectResponse = await axiosInstance.get(`/api/projects/${updatedTask.project.id}`);
        const project = projectResponse.data;

        // Update the specific task within the project's tasks array
        const updatedTasks = project.tasks.map(t => {
          if (t.id === updatedTask.id) {
            return {
              ...t,
              name: updatedTask.name || t.name,
              estimated_hours: updatedTask.estimated_minutes ? 
                Math.floor(updatedTask.estimated_minutes / 60) : 
                t.estimated_hours,
              estimated_minutes: updatedTask.estimated_minutes ? 
                updatedTask.estimated_minutes % 60 : 
                t.estimated_minutes,
              order: t.order,
              completed: updatedTask.completed ?? t.completed,
              due_date: updatedTask.due_date !== undefined ? updatedTask.due_date : t.due_date,
              tag: updatedTask.tag !== undefined ? updatedTask.tag : t.tag
            };
          }
          return t;
        });

        // Send the project update
        await axiosInstance.put(`/api/projects/${updatedTask.project.id}`, {
          ...project,
          tasks: updatedTasks
        });
      } else {
        // Handle standalone task update
        const updatePayload = {
          name: updatedTask.name,
          description: updatedTask.description,
          estimated_minutes: updatedTask.estimated_minutes,
          due_date: updatedTask.due_date,
          priority: updatedTask.priority,
          requires_scheduling: updatedTask.requires_scheduling,
          tag: updatedTask.tag
        };

        await axiosInstance.put(
          `/api/tasks/${updatedTask.id}`,
          updatePayload
        );
      }

      await fetchTasks();
    } catch (error) {
      console.error("Error updating task:", error);
      // Propagate the error to be handled by the component
      throw error;
    }
  };

  const handleDuplicateTask = async (task) => {
    try {
      const now = new Date();
      const newDueDate = task.due_date
        ? new Date(Math.max(new Date(task.due_date).getTime(), now.getTime() + 10 * 60000))
        : new Date(now.getTime() + 24 * 60 * 60000);

      const duplicateTask = {
        name: `${task.name} (copy)`,
        description: task.description,
        estimated_minutes: task.estimated_minutes,
        due_date: newDueDate.toISOString(),
        priority: task.priority,
        requires_scheduling: task.requires_scheduling,
        tag: task.tag
      };

      await axiosInstance.post("/api/tasks", duplicateTask);
      await fetchTasks();
      toast({
        title: "Success",
        description: "Task duplicated successfully",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to duplicate task",
        variant: "destructive",
      });
    }
  };

  const handleSort = async (newDirection) => {
    if (newDirection === "schedule") {
      await fetchCalendarEvents();
    }
    setSortDirection(newDirection);
  };

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="flex items-center gap-2">
          <div className="h-3 w-3 animate-spin rounded-full border-2 border-primary border-t-transparent" />
          <span className="text-sm text-muted-foreground">Loading tasks...</span>
        </div>
      </div>
    );
  }

  const getVisibleTasks = () => {
    if (activeTab === "active") {
      const allTasks = [...tasks.scheduled, ...tasks.unscheduled].filter(task => !task.completed);
      // First filter by task type
      const typeFiltered = allTasks.filter(task => {
        if (activeFilter === "all") return true;
        if (activeFilter === "standalone") return task.type === "standalone_task";
        if (activeFilter === "project") return task.type === "project_task";
        return true;
      });
      // Then apply search filter and sorting
      return sortTasks(
        filterTasks(typeFiltered, searchQuery),
        sortDirection,
        calendarEvents
      );
    } else {
      return sortTasks(
        filterTasks(tasks.completed, searchQuery),
        sortDirection,
        calendarEvents
      );
    }
  };

  return (
    <div className="flex flex-col h-full max-h-screen overflow-hidden">
      {/* Header Section */}
      <div className="flex-none px-4 pt-3 pb-2 border-b border-border/50">
        <TasksHeader
          searchQuery={searchQuery}
          onSearchChange={setSearchQuery}
          onSort={handleSort}
          sortDirection={sortDirection}
          activeTab={activeTab}
          onTabChange={setActiveTab}
          activeFilter={activeFilter}
          onFilterChange={setActiveFilter}
          refreshTasks={fetchTasks}
        />
      </div>

      {/* Main Content */}
      <div className="flex-1 min-h-0 p-4 pb-6">
        <div className="h-full rounded-lg border border-border/50 bg-card/50 overflow-hidden shadow-sm">
          <TasksTable
            tasks={getVisibleTasks()}
            tags={tags}
            onComplete={handleTaskComplete}
            onUncomplete={handleTaskUncomplete}
            onEdit={handleTaskEdit}
            onDelete={handleTaskDelete}
            onDuplicate={handleDuplicateTask}
            isCompletedTab={activeTab === "completed"}
            refreshTasks={fetchTasks}
          />
        </div>
      </div>

      {/* Task Creation/Edit Modal */}
      <Dialog open={createModalOpen} onOpenChange={setCreateModalOpen}>
        <DialogContent className="sm:max-w-[500px] p-0">
          <DialogHeader className="px-4 py-3 border-b border-border/50">
            <DialogTitle className="text-lg font-semibold">
              {selectedTask ? "Edit Task" : "Add a New Task"}
            </DialogTitle>
          </DialogHeader>
          <div className="p-4">
            <TaskForm
              onClose={() => setCreateModalOpen(false)}
              refreshTasks={fetchTasks}
              task={selectedTask}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default TasksPage;