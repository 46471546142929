import React from 'react';
import Layout from "../components/Layout";
import NotesBoard from "../components/Notes/NotesBoard";

function NotesPage() {
  return (
    <Layout>
      <div className="flex h-screen flex-col">
        <div className="sticky top-0 z-50 border-b border-border bg-background/95 p-6 backdrop-blur supports-[backdrop-filter]:bg-background/60">
          <div className="flex items-center justify-between">
            <div>
              <h1 className="text-3xl font-bold">Notes</h1>
              <p className="mt-1 text-muted-foreground">
                Capture your thoughts and convert them to tasks or projects.
              </p>
            </div>
          </div>
        </div>
        <div className="flex-1 overflow-auto">
          <NotesBoard />
        </div>
      </div>
    </Layout>
  );
}

export default NotesPage; 