import React from 'react';
import { Plus } from 'lucide-react';
import { Button } from '../ui/button';

const AddNoteButton = ({ onClick }) => {
  return (
    <Button
      variant="ghost"
      onClick={onClick}
      className="w-full h-[250px] border border-gray-100 
        hover:border-primary/50 hover:bg-gray-50/50 rounded-lg p-6 
        flex flex-col items-center justify-center gap-3 
        group transition-all duration-200"
    >
      <div className="h-10 w-10 rounded-full bg-gray-50 flex items-center justify-center 
        group-hover:bg-primary/10 transition-colors">
        <Plus className="h-5 w-5 text-gray-400 group-hover:text-primary" />
      </div>
      <span className="text-sm font-medium text-gray-400 group-hover:text-primary">
        New Note
      </span>
    </Button>
  );
};

export default AddNoteButton; 