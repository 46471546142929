import {useState, useEffect, useCallback, useMemo} from "react"
import { Calendar as BigCalendar, dateFnsLocalizer } from "react-big-calendar"
import { Dialog, DialogContent } from "../../components/ui/dialog"
import {Loader2, Heart, CheckSquare, Target, MoreVertical} from "lucide-react"
import CalendarToolbar from "./CalendarToolbar"
import PropTypes from "prop-types"
import FloatingSettingsMenu from "./FloatingSettingsMenu"
import { format, parse, startOfWeek, getDay, setHours, setMinutes } from "date-fns"
import enUS from "date-fns/locale/en-US"
import { cn } from "../../lib/utils"
import "../../App.css"
import {useTheme} from "../../ThemeProvider";
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import { debounce } from 'lodash'
import { motion, AnimatePresence } from 'framer-motion'
import axiosInstance from "../../api/axiosInstance";
import { useToast } from "../../components/ui/toast"
import { Trash, Check } from "lucide-react"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu"
import { toZonedTime, formatInTimeZone } from 'date-fns-tz'

const locales = {
  "en-US": enUS,
}

const createLocalizer = (timezone) => {
  return dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });
};

const DragAndDropCalendar = withDragAndDrop(BigCalendar)

const dateAccessors = {
  startAccessor: (event) => event.start,
  endAccessor: (event) => event.end,
  allDayAccessor: (event) => event.allDay || false,
};

// At the top of your file, keep the color helper functions:

const rgbToHsl = (r, g, b) => {
  r /= 255
  g /= 255
  b /= 255
  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)
  let h, s, l = (max + min) / 2
  if (max === min) {
    h = s = 0
  } else {
    const d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break
      case g: h = (b - r) / d + 2; break
      case b: h = (r - g) / d + 4; break
    }
    h /= 6
  }
  return [h, s, l]
}

const hslToRgb = (h, s, l) => {
  let r, g, b
  if (s === 0) {
    r = g = b = l
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1
      if (t > 1) t -= 1
      if (t < 1/6) return p + (q - p) * 6 * t
      if (t < 1/2) return q
      if (t < 2/3) return p + (q - p) * (2/3 - t) * 6
      return p
    }
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s
    const p = 2 * l - q
    r = hue2rgb(p, q, h + 1/3)
    g = hue2rgb(p, q, h)
    b = hue2rgb(p, q, h - 1/3)
  }
  return [r * 255, g * 255, b * 255]
}

const darkenColor = (hex, percent) => {
  hex = hex.replace('#', '')
  const r = parseInt(hex.slice(0, 2), 16)
  const g = parseInt(hex.slice(2, 4), 16)
  const b = parseInt(hex.slice(4, 6), 16)
  const hsl = rgbToHsl(r, g, b)
  hsl[2] = Math.max(0, hsl[2] * (1 - percent))
  const rgb = hslToRgb(hsl[0], hsl[1], hsl[2])
  return '#' +
    Math.round(rgb[0]).toString(16).padStart(2, '0') +
    Math.round(rgb[1]).toString(16).padStart(2, '0') +
    Math.round(rgb[2]).toString(16).padStart(2, '0')
}

const LoadingModal = ({ open }) => {
  const [messageIndex, setMessageIndex] = useState(0)
  const messages = [
    "Updating your schedule...",
    "Understanding your calendar...",
    "Feeding the server hamsters..."
  ]

  useEffect(() => {
    if (!open) return
    const interval = setInterval(() => {
      setMessageIndex((prev) => (prev + 1) % messages.length)
    }, 2000)
    return () => clearInterval(interval)
  }, [open])

  return (
    <Dialog open={open}>
      <DialogContent className="flex flex-col items-center gap-6 p-6 sm:max-w-[425px]">
        <Loader2 className="h-12 w-12 animate-spin text-primary" />
        <p className="min-w-[200px] text-center text-foreground">
          {messages[messageIndex]}
        </p>
      </DialogContent>
    </Dialog>
  )
}

const CustomHeader = ({ label }) => {
  const [day, date] = label.split(" ")
  return (
    <div className="flex flex-col items-center p-1">
      <span className="text-xs text-muted-foreground lowercase opacity-70">
        {day}
      </span>
      <span className="mt-0.5 text-lg font-medium text-foreground">
        {date}
      </span>
    </div>
  )
}

function Calendar({ events, onSync, calendarType, addCustomEventButton, needsUpdate, userSettings }) {
  const [view, setView] = useState("week")
  const [isLoading, setIsLoading] = useState(false)
  const { theme, themeChangeListeners } = useTheme();
  const [forceUpdate, setForceUpdate] = useState(0);
  const [localEvents, setLocalEvents] = useState(events)
  const { toast } = useToast()
  const [pendingUpdates, setPendingUpdates] = useState(new Set())
  const [isSyncing, setIsSyncing] = useState(false)

  // Create a memoized localizer that updates when timezone changes
  const localizer = useMemo(() => 
    createLocalizer(userSettings.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone),
    [userSettings.timezone]
  );

  // Convert event times to user's timezone
  const convertToUserTimezone = useCallback((date) => {
    if (!userSettings.timezone || !date) return new Date(date);
    return toZonedTime(new Date(date), userSettings.timezone);
  }, [userSettings.timezone]);

  // Define CustomEvent inside Calendar component
  const CustomEvent = useCallback(({ event }) => {
    let Icon;
    switch (event.calendar_type) {
      case "habit":
        Icon = Heart
        break
      case "task":
        Icon = CheckSquare
        break
      case "project_task":
        Icon = Target
        break
      default:
        Icon = null
    }

    // Format the time in user's timezone
    const startTime = format(convertToUserTimezone(event.start), "h:mm a")
    const endTime = format(convertToUserTimezone(event.end), "h:mm a")

    return (
      <div className="flex h-full flex-col items-start gap-0.5 p-0.5">
        <div className="flex w-full items-start gap-1">
          <span className="flex-1 overflow-hidden text-ellipsis px-1 font-medium">
            {event.title}
          </span>
          {Icon && (
            <Icon
              className={cn(
                "h-3 w-3 shrink-0 mr-1 mt-0.5",
                event.textInvert ? "text-white" : "text-current opacity-50"
              )}
            />
          )}
        </div>
        <span className="px-1 text-xs opacity-75">
          {startTime} - {endTime}
        </span>
      </div>
    )
  }, [convertToUserTimezone]);

  // Update getEventStart and getEventEnd
  const getEventStart = useCallback((event) => {
    return convertToUserTimezone(event.start);
  }, [convertToUserTimezone]);

  const getEventEnd = useCallback((event) => {
    return convertToUserTimezone(event.end);
  }, [convertToUserTimezone]);

  // Update getWorkHours to add debugging
  const getWorkHours = useCallback(() => {
    // Get user's configured hours or use defaults
    let startHour = 8;
    let endHour = 18;

    if (userSettings.workStartTime && userSettings.workEndTime) {
      [startHour] = userSettings.workStartTime.split(':').map(Number);
      [endHour] = userSettings.workEndTime.split(':').map(Number);
    }

    console.log('User Settings:', {
      timezone: userSettings.timezone,
      workStartTime: userSettings.workStartTime,
      workEndTime: userSettings.workEndTime,
      startHour,
      endHour
    });

    // Create times directly in local timezone
    const minTime = new Date();
    minTime.setHours(startHour, 0, 0, 0);

    const maxTime = new Date();
    maxTime.setHours(endHour, 0, 0, 0);

    // Log times without timezone conversion to debug
    console.log('Calendar Times:', {
      minTime: minTime.toLocaleTimeString('en-US'),
      maxTime: maxTime.toLocaleTimeString('en-US'),
      browserTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });

    return { minTime, maxTime };
  }, [userSettings]);

  // Update the events processing in useEffect
  useEffect(() => {
    const processedEvents = events.map(event => ({
      ...event,
      start: convertToUserTimezone(event.start),
      end: convertToUserTimezone(event.end),
      allDay: false,
      resource: null
    }));
    setLocalEvents(processedEvents);
  }, [events, convertToUserTimezone]);

  // Get the work hours with timezone consideration
  const { minTime, maxTime } = getWorkHours();

  const getEventId = (event) => {
        if (!event) return null;
        return event.id;
    };

   const handleSync = async (type = 'lite') => {
    if (isSyncing) return

    setIsSyncing(true)
    const { dismiss } = toast({
      title: "Updating Schedule",
      description: type === 'pro'
        ? "Using advanced scheduler. If you have a complex schedule, this may take a few minutes..."
        : "Using quick scheduler. This should only take a moment...",
      duration: 1000000,
    })

    try {
      await onSync(type)
      dismiss()
      toast({
        description: "Schedule updated successfully",
        variant: "success",
        icon: <Check className="h-4 w-4" />,
          style: { zIndex: 9999 }
      })
    } catch (error) {
      dismiss()
      toast({
        title: "Error",
        description: "Failed to update schedule",
        variant: "destructive",
          style: { zIndex: 9999 },
      })
    } finally {
      setIsSyncing(false)
    }
  }

  const handleCompleteTask = async (event) => {
      try {
        await axiosInstance.post(`/api/tasks/${event.event_id}/complete`);
        await axiosInstance.delete(`/api/outlook/events/${event.event_id}`);

        setLocalEvents(current =>
          current.map(e => e.id === event.id ? {...e, completed: true} : e)
        );
      } catch (error) {
        console.error('Error completing task:', error);
      }
    };

    const handleDeleteTask = async (event) => {
      try {
        await Promise.all([
          axiosInstance.delete(`/api/outlook/events/${event.id}`)
        ]);

        setLocalEvents(current => current.filter(e => e.id !== event.id));
        setPendingUpdates(prev => {
          const next = new Set(prev);
          next.delete(event.id);
          return next;
        });
      } catch (error) {
        console.error('Error deleting task:', error);
      }
    };

  const AnimatedEvent = ({ event, ...props }) => {
       const isPending = pendingUpdates.has(event.id);
       const uniqueKey = event.id;

       const baseAnimation = {
         initial: { opacity: 0, scale: 0.9, y: 10 },
         animate: {
           opacity: isPending ? 0.7 : 1,
           scale: isPending ? 0.98 : 1,
           y: 0
         },
         transition: { duration: 0.3, ease: "easeOut" }
       };

       // Return the same component for all event types now
       return (
         <motion.div
           key={uniqueKey}
           {...baseAnimation}
           className="h-full"
         >
           <CustomEvent event={event} {...props} />
         </motion.div>
       );
    };


  const updateEventAPI = useCallback(
    debounce(async (event) => {
      const eventId = getEventId(event);
      if (!eventId) {
        console.error('No event ID for API call:', event);
        return;
      }

      try {
        console.log('Updating event:', eventId);  // Debug log
        const response = await axiosInstance.put(`/api/outlook/events/${event.id}`, {
          start: event.start,
          end: event.end
        });

        const data = response.data;
        console.log('Server response:', data);  // Debug log

        // Update all events that were modified in this update
        setLocalEvents(current =>
          current.map(e => {
            const updatedEvent = data.updated_events.find(ue =>
              ue.event_id === e.event_id
            );
            if (updatedEvent) {
              return {
                ...e,
                ...updatedEvent,
                start: new Date(updatedEvent.start),
                end: new Date(updatedEvent.end)
              };
            }
            return e;
          })
        );

        setPendingUpdates(prev => {
          const next = new Set(prev);
          next.delete(eventId);
          return next;
        });

      } catch (error) {
        console.error('Failed to update event:', error.response?.data || error.message);
        setLocalEvents(events);
        setPendingUpdates(new Set());
      }
    }, 1000),
    [events]
  );

   const handleEventResize = useCallback(({ event, start, end }) => {
      if (event.calendar_type === 'synced_event') return;

      setLocalEvents(current =>
        current.map(e =>
          e.id === event.id ? {
            ...e,
            start: new Date(start),
            end: new Date(end)
          } : e
        )
      );

      setPendingUpdates(prev => new Set([...prev, event.id]));
      updateEventAPI({...event, start: new Date(start), end: new Date(end)});
    }, [localEvents, updateEventAPI]);

  const handleEventDrop = useCallback(({ event, start }) => {
      if (event.calendar_type === 'synced_event') return;

      const timeDiff = start - new Date(event.start);

      setLocalEvents(current =>
        current.map(e =>
          e.id === event.id ? {
            ...e,
            start: new Date(new Date(e.start).getTime() + timeDiff),
            end: new Date(new Date(e.end).getTime() + timeDiff)
          } : e
        )
      );

      setPendingUpdates(prev => new Set([...prev, event.id]));
      updateEventAPI({...event, start: new Date(start)});
    }, [localEvents, updateEventAPI]);


   useEffect(() => {
        console.log('Incoming events:', JSON.stringify(events, null, 2));

        // Debug check for duplicate IDs
        const idSet = new Set();
        const duplicates = [];

        events.forEach(event => {
            if (idSet.has(event.id)) {
                duplicates.push(event.id);
            }
            idSet.add(event.id);
        });

        if (duplicates.length > 0) {
            console.error('Found duplicate IDs:', duplicates);
            console.error('Events with duplicate IDs:',
                events.filter(e => duplicates.includes(e.id))
            );
        }

        const processedEvents = events.map(event => {
            console.log('Raw event:', event); // Debug log
            return {
                ...event,
                start: new Date(event.start),
                end: new Date(event.end),
                allDay: false,
                resource: null
            };
        });
        setLocalEvents(processedEvents);
    }, [events]);


  useEffect(() => {
    const listener = () => {
      setForceUpdate(prev => prev + 1);
    };
    themeChangeListeners.add(listener);
    return () => themeChangeListeners.delete(listener);
  }, [themeChangeListeners]);

  const eventStyleGetter = useCallback((event, start, end, isSelected, index) => {
    const backgroundColor = event.color || "hsl(var(--primary))";
    const textColor = darkenColor(backgroundColor, 0.47);
    const isDarkMode = theme === "dark";
    const delay = `${index * 0.1}s`;

    event.textInvert = textColor === "#ffffff";

    return {
      style: {
        backgroundColor: isDarkMode ? `${backgroundColor}4D` : backgroundColor,
        borderRadius: "8px",
        color: isDarkMode ? backgroundColor : textColor,
        border: isDarkMode ? `2px solid ${backgroundColor}` : "none",
        display: "block",
        boxShadow: isDarkMode ? "none" : "0 2px 8px rgba(0, 0, 0, 0.15)",
        textShadow: isDarkMode ? "none" : "0 1px 1px rgba(0, 0, 0, 0.2)",
        fontSize: "0.85rem",
        opacity: event.completed ? 0.5 : 1,
        transform: "translateY(0)",
        animation: `slideIn 1.0s ease-out ${delay}`,
      }
    };
  }, [theme]);

  const formats = {
    weekdayFormat: (date, culture, localizer) =>
      localizer.format(date, "EEE d", culture).toUpperCase(),
    dayFormat: (date, culture, localizer) =>
      localizer.format(date, "EEE d", culture).toUpperCase(),
    timeGutterFormat: (date) => format(date, 'h a') // Simplified time format
  }

  return (
    <div className="relative -m-3 flex h-screen w-[calc(100%+24px)] flex-col bg-transparent p-3">
      <div className="relative flex-grow min-h-0">
        <DragAndDropCalendar
          key={`${forceUpdate}-${userSettings.timezone}`}
          localizer={localizer}
          events={localEvents}
          startAccessor={getEventStart}
          endAccessor={getEventEnd}
          defaultView="week"
          formats={formats}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
          view={view}
          views={["month", "week", "day"]}
          eventPropGetter={eventStyleGetter}
          min={getWorkHours().minTime}
          max={getWorkHours().maxTime}
          onView={setView}
          onEventDrop={handleEventDrop}
          idAccessor="id"
          draggableAccessor={(event) => event.calendar_type !== 'synced_event'}
          resizable={true}
          onEventResize={handleEventResize}
          resizableAccessor={(event) => event.calendar_type !== 'synced_event'}
          selectable={true}
          onSelecting={() => false}
          showMultiDayTimes={true}
          step={30}
          timeslots={2}
          defaultDate={new Date()}
          getNow={() => new Date()}
          components={{
          toolbar: (props) => (
            <CalendarToolbar
              {...props}
              onSync={handleSync}
              needsUpdate={needsUpdate}
              calendarType={calendarType}
              addCustomEventButton={calendarType === "ics" && addCustomEventButton}
              isSyncing={isSyncing}
            />
          ),
            header: CustomHeader,
            event: CustomEvent,
          }}
        />
      </div>
    </div>
  );
}

Calendar.propTypes = {
  events: PropTypes.array.isRequired,
  onSync: PropTypes.func.isRequired,
  calendarType: PropTypes.string.isRequired,
  addCustomEventButton: PropTypes.node,
  needsUpdate: PropTypes.bool,
  userSettings: PropTypes.shape({
    workStartTime: PropTypes.string,
    workEndTime: PropTypes.string,
    timezone: PropTypes.string
  }).isRequired,
}

Calendar.defaultProps = {
  needsUpdate: false,
}

export default Calendar