import { useState, useEffect } from "react";
import { useToast } from "../ui/toast";
import { Button } from "../ui/button";
import { Badge } from "../ui/badge";
import { Copy, CalendarClock, Info } from "lucide-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import TaskCheckbox from './TaskCheckbox';
import { EditableCell } from './EditableCell';
import TaskDialog from './TaskDialog';
import {
  PRIORITY_OPTIONS,
  getPriorityColor,
  getPriorityDisplay,
  getTagStyle,
  formatDateTimeLocal
} from './taskUtils';
import { format } from 'date-fns';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";

export function TasksTable({
  tasks,
  tags = [],
  onComplete,
  onEdit,
  onDelete,
  onDuplicate,
  onUncomplete,
  isCompletedTab,
  refreshTasks
}) {
  console.log('TasksTable received tasks:', tasks);
  console.log('TasksTable received tags:', tags);
  console.log('Tags type:', typeof tags, Array.isArray(tags));
  console.log('Tags received:', tags);
  console.log('Task with tag:', tasks.find(t => t.tag));

  const [selectedTasks, setSelectedTasks] = useState(new Set());
  const [selectedTask, setSelectedTask] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogAction, setDialogAction] = useState(null);
  const { toast } = useToast();

  // Ensure tags is always an array
  const validTags = Array.isArray(tags) ? tags : [];

  // Add debugging
  useEffect(() => {
    console.log('Tasks with due dates:', tasks.map(task => ({
      id: task.id,
      name: task.name,
      type: task.type,
      due_date: task.due_date,
      project: task.type === 'project_task' ? {
        name: task.project?.name,
        deadline: task.project?.deadline
      } : null
    })));
  }, [tasks]);

  useEffect(() => {
    console.log('Tasks with dates:', tasks.map(task => ({
      id: task.id,
      name: task.name,
      type: task.type,
      due_date: task.due_date,
      project_deadline: task.project?.deadline,
      raw_task: task
    })));
  }, [tasks]);

  const handleSelectionChange = (taskId, isSelected) => {
    setSelectedTasks(prev => {
      const newSet = new Set(prev);
      if (isSelected) {
        newSet.add(taskId);
      } else {
        newSet.delete(taskId);
      }
      return newSet;
    });
  };

  const whiteDatePickerStyles = `
    .white-calendar {
      color-scheme: light;
    }
    .white-calendar::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }
  `;

  const canBeScheduled = (task) => {
    return task.estimated_minutes > 0 && task.due_date;
  };

  const handleAction = (task, action) => {
    if (selectedTasks.size > 0 && action !== 'edit') {
      setSelectedTask(Array.from(selectedTasks).map(id =>
        tasks.find(t => t.id === id)
      ));
    } else {
      setSelectedTask(task);
    }
    setDialogAction(action);
    setDialogOpen(true);
  };

  const formatEstimatedTime = (task) => {
    return task.estimated_time || '0m';
  };

  const handleTimeEdit = (task, value) => {
    // Parse input in format "XhYm" or "Ym"
    let totalMinutes = 0;

    const hourMatch = value.match(/(\d+)h/);
    const minuteMatch = value.match(/(\d+)m/);

    if (hourMatch) {
      totalMinutes += parseInt(hourMatch[1]) * 60;
    }
    if (minuteMatch) {
      totalMinutes += parseInt(minuteMatch[1]);
    }

    // For both task types, just send the total minutes
    onEdit({...task, estimated_minutes: totalMinutes});
  };

  const handleConfirm = () => {
    if (selectedTask && dialogAction) {
      if (Array.isArray(selectedTask)) {
        selectedTask.forEach(task => {
          if (dialogAction === "delete") {
            onDelete(task);
          } else if (dialogAction === "complete") {
            onComplete(task);
          }
        });
        setSelectedTasks(new Set());
      } else {
        if (dialogAction === "delete") {
          onDelete(selectedTask);
        } else if (dialogAction === "complete") {
          onComplete(selectedTask);
        }
      }
    }
    setDialogOpen(false);
    setSelectedTask(null);
    setDialogAction(null);
  };

  const getTagClassName = (tagName) => {
    if (!tags) return "border-gray-400 text-gray-500 bg-gray-50 dark:bg-gray-500/10";
    return getTagStyle(tagName, tags);
  };

  return (
    <>
      <style>
        {`
          .white-calendar {
            color-scheme: light;
          }
          .white-calendar::-webkit-calendar-picker-indicator {
            filter: invert(1);
          }
        `}
      </style>
      <div className="h-full flex flex-col">
        <div className="flex-1 overflow-auto">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[50px]"> </TableHead>
                <TableHead>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger className="cursor-help inline-flex items-center gap-1">
                        Tag
                        <Info className="h-4 w-4 text-muted-foreground" />
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Define your tags in your settings!</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </TableHead>
                <TableHead>Task</TableHead>
                <TableHead>Project</TableHead>
                <TableHead>Time Req.</TableHead>
                <TableHead>Deadline</TableHead>
                <TableHead>Priority (Standalone Tasks)</TableHead>
                <TableHead>Schedule Type</TableHead>
                <TableHead className="w-[50px]"> </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {tasks.map((task) => (
                <TableRow
                  key={task.id}
                  className={`group hover:bg-muted/50 ${selectedTasks.has(task.id) ? 'bg-muted/50' : ''}`}
                >
                  <TableCell>
                    <TaskCheckbox
                      task={task}
                      isSelected={selectedTasks.has(task.id)}
                      selectedCount={selectedTasks.size}
                      onOpenChange={handleAction}
                      onSelectionChange={handleSelectionChange}
                      onUncomplete={onUncomplete}
                      isCompletedTab={isCompletedTab}
                      refreshTasks={refreshTasks}
                      setSelectedTasks={setSelectedTasks}
                    />
                  </TableCell>
                  <TableCell>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="h-8 w-full justify-start p-2">
                          <Badge 
                            variant="outline" 
                            className="flex items-center gap-1 border px-2 py-1"
                            style={{
                              backgroundColor: task.tag && validTags?.length ? 
                                `${validTags.find(t => t.name === task.tag)?.color.hex}15` : 
                                'rgb(243 244 246)',
                              borderColor: task.tag && validTags?.length ? 
                                validTags.find(t => t.name === task.tag)?.color.hex : 
                                'rgb(156 163 175)',
                              color: task.tag && validTags?.length ? 
                                validTags.find(t => t.name === task.tag)?.color.hex : 
                                'rgb(107 114 128)'
                            }}
                          >
                            {task.tag || "No Tag"}
                          </Badge>
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="start">
                        {(!validTags || validTags.length === 0) ? (
                          <DropdownMenuItem disabled>
                            No tags available
                          </DropdownMenuItem>
                        ) : (
                          <>
                            <DropdownMenuItem
                              onClick={() => {
                                if (task.type === 'project_task' && task.project?.tasks) {
                                  const otherProjectTasks = task.project.tasks.filter(t => t.id !== task.id);
                                  if (otherProjectTasks.some(t => t.tag === null)) {
                                    toast({
                                      variant: "destructive",
                                      title: "Invalid tag",
                                      description: `Tag "No Tag" is already used in this project`
                                    });
                                    return;
                                  }
                                  // For project tasks, maintain the existing due_date
                                  onEdit({
                                    ...task,
                                    tag: null,
                                    due_date: task.due_date
                                  });
                                } else {
                                  // For standalone tasks, just update the tag
                                  onEdit({
                                    ...task,
                                    tag: null
                                  });
                                }
                              }}
                            >
                              <Badge 
                                variant="outline" 
                                className="flex items-center gap-1 border px-2 py-1"
                                style={{
                                  backgroundColor: 'rgb(243 244 246)',
                                  borderColor: 'rgb(156 163 175)',
                                  color: 'rgb(107 114 128)'
                                }}
                              >
                                No Tag
                              </Badge>
                            </DropdownMenuItem>
                            {validTags.map(tag => (
                              <DropdownMenuItem
                                key={tag.id}
                                onClick={() => {
                                  if (task.type === 'project_task' && task.project?.tasks) {
                                    const otherProjectTasks = task.project.tasks.filter(t => t.id !== task.id);
                                    if (otherProjectTasks.some(t => t.tag === tag.name)) {
                                      toast({
                                        variant: "destructive",
                                        title: "Invalid tag",
                                        description: `Tag "${tag.name}" is already used in this project`
                                      });
                                      return;
                                    }
                                    // For project tasks, maintain the existing due_date
                                    onEdit({
                                      ...task,
                                      tag: tag.name,
                                      due_date: task.due_date
                                    });
                                  } else {
                                    // For standalone tasks, just update the tag
                                    onEdit({
                                      ...task,
                                      tag: tag.name
                                    });
                                  }
                                }}
                              >
                                <Badge 
                                  variant="outline" 
                                  className="flex items-center gap-1 border px-2 py-1"
                                  style={{
                                    backgroundColor: `${tag.color.hex}15`,
                                    borderColor: tag.color.hex,
                                    color: tag.color.hex
                                  }}
                                >
                                  {tag.name}
                                </Badge>
                              </DropdownMenuItem>
                            ))}
                          </>
                        )}
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </TableCell>
                  <TableCell>
                    <EditableCell
                      value={task.name}
                      onEdit={(value) => onEdit({...task, name: value})}
                      className={task.completed ? "line-through text-muted-foreground" : ""}
                      disabled={isCompletedTab}
                    />
                  </TableCell>
                  {/* Project Cell */}
                  <TableCell>
                    <Badge
                      variant="outline"
                      style={task.type === 'project_task' ? {backgroundColor: task.project?.color} : {}}
                      className="ml-2" // Add a bit of left margin to align with other cells
                    >
                      {task.type === 'project_task' ? task.project?.name : 'Standalone Task'}
                    </Badge>
                  </TableCell>
                  <TableCell>
                    <EditableCell
                      value={formatEstimatedTime(task)}
                      onEdit={(value) => handleTimeEdit(task, value)}
                      type="text"
                      placeholder="Set time..."
                      className="cursor-pointer hover:text-primary transition-colors"
                      disabled={isCompletedTab}
                    />
                  </TableCell>
                  <TableCell>
                    <div className="flex items-center gap-2">
                      <CalendarClock className="h-4 w-4 text-muted-foreground"/>
                      <EditableCell
                        value={task.due_date ? formatDateTimeLocal(task.due_date) : ""}
                        onEdit={async (value) => {
                          try {
                            const selectedDate = new Date(value);
                            const now = new Date();

                            // Ensure the date is within reasonable bounds
                            if (!selectedDate || isNaN(selectedDate.getTime())) {
                              toast({
                                variant: "destructive",
                                title: "Invalid Date Format",
                                description: "Please enter a valid date and time"
                              });
                              return;
                            }

                            // Project task validations
                            if (task.type === 'project_task' && task.project) {
                              const projectStart = new Date(task.project.start_time);
                              const projectEnd = new Date(task.project.deadline);

                              // Convert dates to timestamps for comparison
                              const selectedTime = selectedDate.getTime();
                              const projectStartTime = projectStart.getTime();
                              const projectEndTime = projectEnd.getTime();

                              // Strict validation against project timeline
                              if (selectedTime < projectStartTime) {
                                toast({
                                  variant: "destructive",
                                  title: "Invalid Task Deadline",
                                  description: (
                                    <div className="space-y-2">
                                      <p>Task deadline cannot be before project start date.</p>
                                      <p className="text-sm opacity-90">
                                        Project starts: {projectStart.toLocaleString([], {
                                          month: 'short',
                                          day: 'numeric',
                                          hour: '2-digit',
                                          minute: '2-digit',
                                          hour12: false
                                        })}
                                      </p>
                                      <p className="text-sm opacity-90">
                                        Selected date: {selectedDate.toLocaleString([], {
                                          month: 'short',
                                          day: 'numeric',
                                          hour: '2-digit',
                                          minute: '2-digit',
                                          hour12: false
                                        })}
                                      </p>
                                    </div>
                                  )
                                });
                                return;
                              }

                              if (selectedTime > projectEndTime) {
                                toast({
                                  variant: "destructive",
                                  title: "Invalid Task Deadline",
                                  description: (
                                    <div className="space-y-2">
                                      <p>Task deadline cannot be after project deadline.</p>
                                      <p className="text-sm opacity-90">
                                        Project ends: {projectEnd.toLocaleString([], {
                                          month: 'short',
                                          day: 'numeric',
                                          hour: '2-digit',
                                          minute: '2-digit',
                                          hour12: false
                                        })}
                                      </p>
                                      <p className="text-sm opacity-90">
                                        Selected date: {selectedDate.toLocaleString([], {
                                          month: 'short',
                                          day: 'numeric',
                                          hour: '2-digit',
                                          minute: '2-digit',
                                          hour12: false
                                        })}
                                      </p>
                                    </div>
                                  )
                                });
                                return;
                              }

                              // Only check task order if there are other tasks
                              if (task.project.tasks?.length > 0) {
                                const otherTasks = task.project.tasks.filter(t => t.id !== task.id && t.due_date);

                                for (const otherTask of otherTasks) {
                                  const otherDate = new Date(otherTask.due_date);
                                  if (otherTask.order < task.order && selectedDate < otherDate) {
                                    toast({
                                      variant: "destructive",
                                      title: "Task Order Conflict",
                                      description: (
                                        <div className="space-y-2">
                                          <p>Task deadline must be after "{otherTask.name}"</p>
                                          <p className="text-sm opacity-90">
                                            Previous task due: {otherDate.toLocaleString([], {
                                              month: 'short',
                                              day: 'numeric',
                                              hour: '2-digit',
                                              minute: '2-digit',
                                              hour12: false
                                            })}
                                          </p>
                                        </div>
                                      )
                                    });
                                    return;
                                  }
                                  if (otherTask.order > task.order && selectedDate > otherDate) {
                                    toast({
                                      variant: "destructive",
                                      title: "Task Order Conflict",
                                      description: (
                                        <div className="space-y-2">
                                          <p>Task deadline must be before "{otherTask.name}"</p>
                                          <p className="text-sm opacity-90">
                                            Next task due: {otherDate.toLocaleString([], {
                                              month: 'short',
                                              day: 'numeric',
                                              hour: '2-digit',
                                              minute: '2-digit',
                                              hour12: false
                                            })}
                                          </p>
                                        </div>
                                      )
                                    });
                                    return;
                                  }
                                }
                              }
                            } else {
                              // Standalone task validations
                              if (selectedDate < now) {
                                toast({
                                  variant: "destructive",
                                  title: "Invalid date",
                                  description: "Deadline cannot be in the past"
                                });
                                return;
                              }
                            }

                            // Future date validation
                            const maxDate = new Date();
                            maxDate.setFullYear(now.getFullYear() + 10);
                            if (selectedDate > maxDate) {
                              toast({
                                variant: "destructive",
                                title: "Invalid date",
                                description: "Deadline cannot be more than 10 years in the future"
                              });
                              return;
                            }

                            // Always use due_date for consistency
                            await onEdit({
                              ...task,
                              due_date: value
                            });
                          } catch (error) {
                            // Enhanced error handling
                            console.error('Error updating task:', error);
                            
                            let errorMessage;
                            if (error.response?.data?.error) {
                              // Backend validation error
                              errorMessage = error.response.data.error;
                            } else if (error.message) {
                              // Network or other error
                              errorMessage = error.message;
                            } else {
                              // Fallback error message
                              errorMessage = "Failed to update task deadline";
                            }

                            // Split the error message into lines if it contains newlines
                            const messageLines = errorMessage.split('\n').filter(line => line.trim());
                            
                            toast({
                              variant: "destructive",
                              title: "Invalid Task Deadline",
                              description: (
                                <div className="space-y-2">
                                  {messageLines.map((line, index) => (
                                    <p key={index} className={index === 0 ? "" : "text-sm opacity-90"}>
                                      {line}
                                    </p>
                                  ))}
                                </div>
                              )
                            });
                          }
                        }}
                        type="datetime-local"
                        placeholder="Set deadline..."
                        disabled={isCompletedTab}
                        inputClassName="white-calendar"
                        className="white-calendar"
                        displayValue={
                          task.deadline || task.due_date
                            ? new Date(task.deadline || task.due_date).toLocaleString([], {
                                month: 'short',
                                day: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false
                              })
                            : "Set deadline..."
                        }
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    {task.type !== 'project_task' && (
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button variant="ghost" className="h-8 w-full justify-start p-2">
                            <Badge variant="secondary" className={getPriorityColor(task.priority)}>
                              {getPriorityDisplay(task.priority)}
                            </Badge>
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                          {PRIORITY_OPTIONS.map(option => (
                            <DropdownMenuItem
                              key={option.value}
                              onClick={() => onEdit({...task, priority: option.value})}
                            >
                              <Badge variant="secondary" className={getPriorityColor(option.value)}>
                                {option.label}
                              </Badge>
                            </DropdownMenuItem>
                          ))}
                        </DropdownMenuContent>
                      </DropdownMenu>
                    )}
                  </TableCell>
                  <TableCell>
                    {task.type === 'project_task' ? (
                      <Badge variant="outline" className="ml-2">
                        Scheduled
                      </Badge>
                    ) : (
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button variant="ghost" className="h-8 w-full justify-start p-2">
                            <Badge variant="outline">
                              {task.requires_scheduling ? "Scheduled" : "Flexible"}
                            </Badge>
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                          <DropdownMenuItem
                            onClick={() => {
                              // Parse the estimated time from the "Xh Ym" format
                              const estimatedTime = task.estimated_time;
                              let totalMinutes = 0;

                              if (estimatedTime) {
                                const hourMatch = estimatedTime.match(/(\d+)h/);
                                const minuteMatch = estimatedTime.match(/(\d+)m/);

                                if (hourMatch) {
                                  totalMinutes += parseInt(hourMatch[1]) * 60;
                                }
                                if (minuteMatch) {
                                  totalMinutes += parseInt(minuteMatch[1]);
                                }
                              }

                              // Check if we have both time and deadline
                              if (totalMinutes > 0 && task.deadline) {
                                onEdit({
                                  ...task,
                                  estimated_minutes: totalMinutes,
                                  requires_scheduling: true
                                });
                              } else {
                                toast({
                                  title: "Cannot schedule task",
                                  description: "Please set both time and deadline before scheduling",
                                  variant: "destructive",
                                });
                              }
                            }}
                          >
                            <Badge variant="outline">Scheduled</Badge>
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            onClick={() => onEdit({...task, requires_scheduling: false})}
                          >
                            <Badge variant="outline">Flexible</Badge>
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    )}
                  </TableCell>
                  <TableCell>
                    {task.type !== 'project_task' && (
                      <Button
                        variant="ghost"
                        size="icon"
                        className="h-8 w-8 opacity-0 group-hover:opacity-100 hover:bg-primary/10 hover:text-primary transition-opacity"
                        onClick={() => onDuplicate(task)}
                      >
                        <Copy className="h-4 w-4" />
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>

      <TaskDialog
        open={dialogOpen}
        onOpenChange={setDialogOpen}
        selectedTask={selectedTask}
        dialogAction={dialogAction}
        onConfirm={handleConfirm}
        onCancel={() => setDialogOpen(false)}
      />
    </>
  );
}

export default TasksTable;