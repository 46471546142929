import { useState } from "react";
import { useToast } from "../ui/toast";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Search, ArrowUpDown, ChevronDown, Wand2, Loader2, Filter } from "lucide-react";
import { Tabs, TabsList, TabsTrigger } from "../ui/tabs";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import axiosInstance from "../../api/axiosInstance";
import { FILTER_OPTIONS } from "./taskUtils";

const SORT_OPTIONS = [
  { value: "schedule", label: "Sort by Schedule" },
  { value: "asc", label: "Sort by Due Date (Ascending)" },
  { value: "desc", label: "Sort by Due Date (Descending)" },
];

export function TasksHeader({
  searchQuery,
  onSearchChange,
  onSort,
  sortDirection,
  activeTab,
  onTabChange,
  onFilterChange,
  activeFilter = "all",
  refreshTasks,
}) {
  const { toast } = useToast();
  const currentSort = SORT_OPTIONS.find((opt) => opt.value === sortDirection);
  const currentFilter = FILTER_OPTIONS.find((opt) => opt.value === activeFilter);

  const [taskName, setTaskName] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState("");
  const [showSearch, setShowSearch] = useState(false);

  const handleGenerate = async (e) => {
    e.preventDefault();
    if (!taskName.trim()) return;

    setIsGenerating(true);
    setError("");

    try {
      const { data } = await axiosInstance.post("/api/generate-task", {
        task_name: taskName,
      });

      if (data.success) {
        setTaskName("");
        await refreshTasks();
        toast({
          title: "Success",
          description: "Task created successfully",
          variant: "default",
        });
      } else {
        throw new Error(data.error || "Failed to generate task");
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.error ||
        error.message ||
        "Failed to generate task";
      setError(errorMessage);
      toast({
        title: "Error",
        description: errorMessage,
        variant: "destructive",
      });
    } finally {
      setIsGenerating(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleGenerate(e);
    }
  };

  return (
      <div className="mb-4 flex flex-wrap items-center gap-2">
        {activeTab === "active" && (
            <>
              <form onSubmit={handleGenerate} className="w-[400px] flex items-center gap-0">
                <div className="relative flex-1 flex items-center">
                  <Input
                      placeholder="Enter task name..."
                      value={taskName}
                      onChange={(e) => setTaskName(e.target.value)}
                      onKeyDown={handleKeyDown}
                      className="h-9 rounded-r-none focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:ring-transparent focus-visible:border-r-0"
                      disabled={isGenerating}
                      aria-label="Task name"
                  />
                  <Button
                      type="submit"
                      variant="outline"
                      className="h-9 px-3 gap-2 rounded-l-none border-l hover:bg-primary/10 transition-colors whitespace-nowrap"
                      disabled={isGenerating || !taskName.trim()}
                  >
                    {isGenerating ? (
                        <>
                          <Loader2 className="h-4 w-4 animate-spin"/>
                          <span>Generating...</span>
                        </>
                    ) : (
                        <>
                          <Wand2 className="h-4 w-4"/>
                          <span>Generate</span>
                        </>
                    )}
                  </Button>
                </div>
              </form>

              {error && (
                  <div className="text-sm text-destructive" role="alert">
                    {error}
                  </div>
              )}
            </>
        )}

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="h-9 px-3 min-w-[180px] justify-between">
              <div className="flex items-center gap-2">
                <Filter className="h-4 w-4"/>
                <span>{currentFilter?.label}</span>
              </div>
              <ChevronDown className="h-4 w-4 opacity-50"/>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-[180px]">
            {FILTER_OPTIONS.map((option) => (
                <DropdownMenuItem
                    key={option.value}
                    onClick={() => onFilterChange(option.value)}
                    className="flex items-center justify-between"
                >
                  {option.label}
                  {option.value === activeFilter && (
                      <span className="text-primary">✓</span>
                  )}
                </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="h-9 px-3 min-w-[200px] justify-between">
              <div className="flex items-center gap-2">
                <ArrowUpDown className="h-4 w-4"/>
                <span>{currentSort?.label}</span>
              </div>
              <ChevronDown className="h-4 w-4 opacity-50"/>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-[200px]">
            {SORT_OPTIONS.map((option) => (
                <DropdownMenuItem
                    key={option.value}
                    onClick={() => onSort(option.value)}
                    className="flex items-center justify-between"
                >
                  {option.label}
                  {option.value === sortDirection && (
                      <span className="text-primary">✓</span>
                  )}
                </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>

        <div className="relative">
          {showSearch ? (
              <div className="animate-in slide-in-from-right-8 duration-200">
                <div className="relative w-[300px]">
                  <Search
                      className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground"
                      aria-hidden="true"
                  />
                  <Input
                      placeholder="Filter tasks..."
                      value={searchQuery}
                      onChange={(e) => onSearchChange(e.target.value)}
                      className="pl-8"
                      aria-label="Search tasks"
                  />
                  <Button
                      onClick={() => setShowSearch(false)}
                      variant="ghost"
                      className="h-9 px-3 absolute right-0 top-0"
                      aria-label="Close search"
                  >
                    ×
                  </Button>
                </div>
              </div>
          ) : (
              <div className="animate-in slide-in-from-left-8 duration-200">
                <Button
                    onClick={() => setShowSearch(true)}
                    variant="outline"
                    className="h-9 px-3 flex items-center gap-2"
                >
                  <Search className="h-4 w-4"/>
                  <span>Search</span>
                </Button>
              </div>
          )}
        </div>

        <Tabs value={activeTab} onValueChange={onTabChange} className="ml-auto">
          <TabsList>
            <TabsTrigger value="active">Active Tasks</TabsTrigger>
            <TabsTrigger value="completed">
              Completed Standalone Tasks
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>
  );
}

export default TasksHeader;