import { Link } from "react-router-dom"
import { Plus, Check, Trash2 } from "lucide-react"
import { Button } from "../../ui/button"
import { NewTaskForm } from "./NewTaskForm"
import { TaskList } from "./TaskList"
import { MAX_FREE_TASKS } from "../constants"

export function ProjectRowTasks({
  project,
  isSubscribed,
  newTaskForm,
  taskName,
  taskHours,
  taskMinutes,
  taskTag,
  taskDueDate,  // Add this
  isEditing,
  editHours,
  editMinutes,
  editTag,
  editDueDate,  // Add this
  selectedTasks,
  onNewTaskFormToggle,
  onTaskNameChange,
  onTaskHoursChange,
  onTaskMinutesChange,
  onTaskTagChange,
  onTaskDueDateChange,  // Add this
  onAddTask,
  onEditTask,
  onSaveTask,
  onDeleteTask,
  onTaskCompletionToggle,
  onTasksReorder,
  onEditCancel,
  onEditHoursChange,
  onEditMinutesChange,
  onEditTagChange,
  onEditDueDateChange,  // Add this
  onTaskSelect,
  onBulkComplete,
  onBulkDelete,
  editName,
  onEditNameChange,
}) {
  return (
    <div className="bg-muted/50 px-4 py-3">
      <div className="mb-4">
        {!isSubscribed && (
          <div className="text-sm text-muted-foreground">
            {project.tasks.length}/{MAX_FREE_TASKS} tasks
            <Link to="/subscribe" className="ml-2 text-primary underline">
              Upgrade for more
            </Link>
          </div>
        )}
      </div>

      <TaskList
        projectId={project.id}
        tasks={project.tasks}
        selectedTasks={selectedTasks}
        isEditing={isEditing}
        editHours={editHours}
        editMinutes={editMinutes}
        editTag={editTag}
        editDueDate={editDueDate}  // Add this
        onEditHoursChange={onEditHoursChange}
        onEditMinutesChange={onEditMinutesChange}
        onEditTagChange={onEditTagChange}
        onEditDueDateChange={onEditDueDateChange}  // Add this
        onSaveTask={onSaveTask}
        onEditCancel={onEditCancel}
        onEditTask={onEditTask}
        onDeleteTask={onDeleteTask}
        onTaskCompletionToggle={onTaskCompletionToggle}
        onTasksReorder={onTasksReorder}
        onTaskSelect={onTaskSelect}
        editName={editName}
        onEditNameChange={onEditNameChange}
      />

      <div className="mt-4">
        {newTaskForm && (
          <div className="mb-4">
            <NewTaskForm
              taskName={taskName}
              taskHours={taskHours}
              taskMinutes={taskMinutes}
              taskTag={taskTag}
              taskDueDate={taskDueDate}  // Add this
              onTaskNameChange={onTaskNameChange}
              onTaskHoursChange={onTaskHoursChange}
              onTaskMinutesChange={onTaskMinutesChange}
              onTaskTagChange={onTaskTagChange}
              onTaskDueDateChange={onTaskDueDateChange}  // Add this
              onAddTask={onAddTask}
            />
          </div>
        )}

        <div className="flex justify-start">
          <Button
            variant="outline"
            size="sm"
            onClick={onNewTaskFormToggle}
            disabled={!isSubscribed && project.tasks.length >= MAX_FREE_TASKS}
          >
            <Plus className={`mr-2 h-4 w-4 ${newTaskForm ? 'rotate-45' : ''}`} />
            {newTaskForm ? 'Cancel' : 'Add Task'}
          </Button>
        </div>
      </div>

      {selectedTasks.size > 0 && (
        <div className="fixed bottom-6 right-6 z-50">
          <div className="bg-background rounded-lg shadow-lg border p-2 min-w-[280px]">
            <div className="px-2 py-1.5 text-sm text-muted-foreground mb-2">
              {selectedTasks.size} tasks selected
            </div>

            <Button
              variant="ghost"
              className="w-full justify-start text-left py-3 px-4 mb-1"
              onClick={onBulkComplete}
            >
              <Check className="mr-2 h-4 w-4" />
              Complete {selectedTasks.size} tasks
            </Button>

            <Button
              variant="ghost"
              className="w-full justify-start text-left py-3 px-4 text-destructive hover:text-destructive"
              onClick={onBulkDelete}
            >
              <Trash2 className="mr-2 h-4 w-4" />
              Delete {selectedTasks.size} tasks
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}