// components/projects/NewProjectDialog.jsx
import { Wand2, Loader2 } from "lucide-react"
import { Button } from "../ui/button"
import { Input } from "../ui/input"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog"
import { cn } from "../../lib/utils"
import { COLORS } from "./constants"

export function NewProjectDialog({
  open,
  onClose,
  onSubmit,
  isGenerating,
  onGenerate,
  projectName,
  onProjectNameChange,
  startTime,
  onStartTimeChange,
  deadline,
  onDeadlineChange,
  selectedColor,
  onColorChange,
}) {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create New Project</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          <div className="flex gap-2">
            <Input
              placeholder="Project name..."
              value={projectName}
              onChange={(e) => onProjectNameChange(e.target.value)}
            />
            <Button
              variant="outline"
              onClick={onGenerate}
              disabled={isGenerating || !projectName.trim()}
            >
              {isGenerating ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin"/>
                  Generating...
                </>
              ) : (
                <>
                  <Wand2 className="mr-2 h-4 w-4"/>
                  Generate
                </>
              )}
            </Button>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="mb-1 block text-sm">Start Date</label>
              <Input
                type="datetime-local"
                value={startTime}
                onChange={(e) => onStartTimeChange(e.target.value)}
              />
            </div>
            <div>
              <label className="mb-1 block text-sm">Deadline</label>
              <Input
                type="datetime-local"
                value={deadline}
                onChange={(e) => onDeadlineChange(e.target.value)}
              />
            </div>
          </div>

          <div>
            <label className="mb-1 block text-sm">Color</label>
            <div className="flex gap-2">
              {COLORS.map(color => (
                <button
                  key={color}
                  type="button"
                  className={cn(
                    "h-8 w-8 rounded-full border-2",
                    selectedColor === color ? "border-black" : "border-transparent"
                  )}
                  style={{ backgroundColor: color }}
                  onClick={() => onColorChange(color)}
                />
              ))}
            </div>
          </div>
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onSubmit}>
            Create Project
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}