import { useState, useEffect, useRef } from "react"
import {
  RefreshCw,
  ChevronLeft,
  ChevronRight,
  Calendar,
  MessageSquare,
  Settings,
} from "lucide-react"

import { Button } from "../../components/ui/button"
import { cn } from "../../lib/utils"
import { useAuth } from "../../contexts/AuthContext"

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverPortal,
} from "../../components/ui/popover"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog"

import FeedbackForm from "../FeedbackForm"
import CalendarSettings from "./CalendarSettings"

function CalendarToolbar({
  view,
  views,
  label,
  onNavigate,
  onView,
  onSync,
  needsUpdate,
  calendarType,
  addCustomEventButton,
  isSyncing,
  minTime,
  maxTime,
  onMinTimeChange,
  onMaxTimeChange,
}) {
  const [showSchedulerOptions, setShowSchedulerOptions] = useState(false)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [dialogContent, setDialogContent] = useState(null)
  const [dialogTitle, setDialogTitle] = useState("")

  const dropdownRef = useRef(null)
  const { auth } = useAuth()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowSchedulerOptions(false)
      }
    }

    const handleEscape = (event) => {
      if (event.key === "Escape") {
        setShowSchedulerOptions(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    document.addEventListener("keydown", handleEscape)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
      document.removeEventListener("keydown", handleEscape)
    }
  }, [])

  const handleNavigate = (action) => {
    onNavigate(action)
  }

  const handleViewChange = (newView) => {
    onView(newView)
  }

  const handleSchedulerClick = () => {
    setShowSchedulerOptions(!showSchedulerOptions)
  }

  const handleSyncClick = async (type) => {
    try {
      await onSync(type)
    } finally {
      setShowSchedulerOptions(false)
    }
  }

  const handlePopoverOpenChange = (open) => {
    setIsPopoverOpen(open)
  }

  const handleOpenDialog = (content, title) => {
    setDialogContent(content)
    setDialogTitle(title)
    setOpenDialog(true)
    setIsPopoverOpen(false)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const MenuItem = ({ icon: Icon, children, onClick }) => (
    <button
      className="flex w-full items-center gap-2 rounded-sm px-2 py-1.5 text-sm hover:bg-accent focus:bg-accent"
      onClick={onClick}
    >
      {Icon && <Icon className="h-4 w-4" />}
      {children}
    </button>
  )

  return (
    <div className="mb-6">
      <div className="flex items-center justify-between">
        <div className="flex rounded-lg">
          <Button
            variant="ghost"
            className="rounded-r-none"
            onClick={() => handleNavigate("TODAY")}
          >
            Today
          </Button>
          <Button
            variant="ghost"
            className="rounded-none"
            onClick={() => handleNavigate("PREV")}
          >
            <ChevronLeft className="h-4 w-4" />
            Back
          </Button>
          <Button
            variant="ghost"
            className="rounded-l-none"
            onClick={() => handleNavigate("NEXT")}
          >
            Next
            <ChevronRight className="h-4 w-4" />
          </Button>
        </div>

        <h1 className="text-lg font-semibold">{label}</h1>

        <div className="flex items-center gap-4">
          <div className="flex rounded-lg">
            {views.map((viewName) => (
              <Button
                key={viewName}
                variant="ghost"
                className={cn(
                  "rounded-none last:rounded-r-lg first:rounded-l-lg",
                  view === viewName && "bg-accent text-accent-foreground"
                )}
                onClick={() => handleViewChange(viewName)}
              >
                {viewName.charAt(0).toUpperCase() + viewName.slice(1)}
              </Button>
            ))}
          </div>

          {needsUpdate && (
            <p className="flex items-center gap-2 text-sm font-medium">
              🚩 Hey! You&apos;ve got pending project changes to schedule →
            </p>
          )}

          <div className="relative" ref={dropdownRef}>
            <Button
              variant="default"
              onClick={handleSchedulerClick}
              className="flex items-center gap-2"
              disabled={isSyncing}
            >
              <RefreshCw className={cn("h-4 w-4", isSyncing && "animate-spin")} />
              Update Schedule
            </Button>
            {showSchedulerOptions && (
              <div className="absolute right-0 top-full mt-2 w-80 rounded-lg border bg-background p-4 shadow-lg z-50">
                <div className="flex flex-col gap-4">
                  <div className="space-y-2">
                    <Button
                      variant="outline"
                      className="w-full"
                      disabled={isSyncing}
                      onClick={() => handleSyncClick("lite")}
                    >
                      {isSyncing && (
                        <RefreshCw className="mr-2 h-4 w-4 animate-spin" />
                      )}
                      Scheduler Lite
                    </Button>
                    <p className="text-sm text-muted-foreground">
                      Quick scheduling for basic task management. Best for
                      simple schedules and immediate updates.
                    </p>
                  </div>

                  <div className="space-y-2">
                    <Button
                      variant="outline"
                      className="w-full"
                      disabled={!auth.isSubscribed || isSyncing}
                      onClick={() =>
                        auth.isSubscribed ? handleSyncClick("pro") : null
                      }
                    >
                      {isSyncing && (
                        <RefreshCw className="mr-2 h-4 w-4 animate-spin" />
                      )}
                      Scheduler Plus{" "}
                    </Button>
                    <div>
                      <p className="text-sm text-muted-foreground">
                        {auth.isSubscribed ? (
                          "Advanced algorithm for complex scheduling of many tasks and multiple projects. More thorough but may take up to a few minutes to complete."
                        ) : (
                          <>
                            <a href="/subscribe" className="text-primary hover:underline">
                              Upgrade
                            </a>{" "}
                            to Plus to access advanced scheduling features
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <Popover open={isPopoverOpen} onOpenChange={handlePopoverOpenChange}>
            <PopoverTrigger asChild>
              <Button variant="outline"><Settings className="h-4 w-4" /></Button>
            </PopoverTrigger>
            <PopoverPortal>
              <PopoverContent className="w-48" align="end" sideOffset={8}>
                <div className="flex flex-col space-y-1">
                  <MenuItem
                    icon={Calendar}
                    onClick={() =>
                      handleOpenDialog(
                        <CalendarSettings
                          minTime={minTime}
                          maxTime={maxTime}
                          onMinTimeChange={onMinTimeChange}
                          onMaxTimeChange={onMaxTimeChange}
                          onClose={handleCloseDialog}
                        />,
                        "Calendar Settings"
                      )
                    }
                  >
                    Calendar Settings
                  </MenuItem>

                  <MenuItem
                    icon={MessageSquare}
                    onClick={() =>
                      handleOpenDialog(<FeedbackForm />, "Submit Feedback")
                    }
                  >
                    Submit Feedback
                  </MenuItem>
                </div>
              </PopoverContent>
            </PopoverPortal>
          </Popover>

          {addCustomEventButton}
        </div>
      </div>

      <Dialog open={openDialog} onOpenChange={setOpenDialog}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>{dialogTitle}</DialogTitle>
          </DialogHeader>
          {dialogContent}
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default CalendarToolbar