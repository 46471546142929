import { useState } from "react"
import { useNavigate } from "react-router-dom"
import axiosInstance from "../api/axiosInstance"
import Cookies from "js-cookie"
import { Check, Loader2 } from "lucide-react"
import Layout from "../components/Layout"

import { Button } from "../components/ui/button"
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../components/ui/card"
import { Badge } from "../components/ui/badge"

const PlanFeature = ({ children }) => {
  return (
    <div className="flex items-center space-x-2 py-1.5 lg:py-2">
      <Check className="h-3.5 w-3.5 lg:h-4 lg:w-4 text-primary" />
      <span className="text-xs lg:text-sm">{children}</span>
    </div>
  )
}

const SubscribePage = () => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const handleSubscribe = async () => {
    setLoading(true)
    try {
      const fbpCookie = Cookies.get("_fbp") || null
      const fbcCookie = Cookies.get("_fbc") || null
      const response = await axiosInstance.post("/api/user/subscribe", {
        fbp: fbpCookie,
        fbc: fbcCookie,
      })
      if (response.status === 200) {
        window.location.href = response.data.checkout_url
      }
    } catch (error) {
      console.error("Error:", error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Layout>
      <div className="h-screen overflow-y-auto">
        <div className="container max-w-6xl px-4 py-12 min-h-full">
          <div className="text-center">
            <h1 className="text-3xl lg:text-4xl font-bold">Upgrade to Plus</h1>
            <p className="mt-2 text-base lg:text-lg text-muted-foreground">
              Unlock all features with our Plus plan
            </p>
          </div>

          <div className="mt-16 grid gap-6 lg:grid-cols-3 md:grid-cols-2 pb-8">
            {/* Free Plan */}
            <Card className="relative flex h-full flex-col border bg-card">
              <CardHeader className="p-4 lg:p-6">
                <CardTitle className="text-xl lg:text-2xl">Free</CardTitle>
                <div className="flex items-baseline gap-1">
                  <span className="text-3xl lg:text-4xl font-bold">$0</span>
                  <span className="text-xs lg:text-sm text-muted-foreground">/ month</span>
                </div>
                <CardDescription className="mt-4">
                  A great starting point to simplify your calendar and tackle a few key projects with ease.
                </CardDescription>
              </CardHeader>
              <CardContent className="p-4 lg:p-6 flex flex-1 flex-col justify-between">
                <div className="mt-6 flex-1 space-y-2">
                  <PlanFeature>Schedular Lite</PlanFeature>
                  <PlanFeature>Up to 5 standalone tasks</PlanFeature>
                  <PlanFeature>Up to 2 projects</PlanFeature>
                  <PlanFeature>Up to 3 tasks per project</PlanFeature>
                  <PlanFeature>Connect to Outlook & Google Calendar</PlanFeature>
                </div>
                <div className="h-[40px]" />
              </CardContent>
            </Card>

            {/* Plus Plan */}
            <div className="relative pt-4">
              <Badge
                className="absolute -right-3 -top-3 z-20 px-3 py-1 text-xs lg:text-sm font-semibold"
                variant="default"
              >
                Recommended
              </Badge>
              <Card className="relative flex h-full flex-col scale-[1.03] lg:scale-105 border-2 border-primary bg-card">
                <CardHeader className="p-4 lg:p-6">
                  <CardTitle className="text-xl lg:text-2xl">Plus</CardTitle>
                  <div className="flex items-baseline gap-1">
                    <span className="text-3xl lg:text-4xl font-bold">$10</span>
                    <span className="text-xs lg:text-sm text-muted-foreground">/ month</span>
                  </div>
                  <CardDescription className="mt-4">
                    Ideal for professionals ready to streamline unlimited projects and tasks seamlessly.
                  </CardDescription>
                </CardHeader>
                <CardContent className="p-4 lg:p-6 flex flex-1 flex-col justify-between">
                  <div className="mt-2 flex-1 space-y-2">
                    <PlanFeature>Everything in Free, and...</PlanFeature>
                    <PlanFeature>Scheduler Pro</PlanFeature>
                    <PlanFeature>Insights</PlanFeature>
                    <PlanFeature>Unlimited projects</PlanFeature>
                    <PlanFeature>Unlimited tasks per project</PlanFeature>
                    <PlanFeature>Unlimited standalone tasks</PlanFeature>
                  </div>
                  <Button
                    className="mt-6"
                    onClick={handleSubscribe}
                    disabled={loading}
                  >
                    {loading ? (
                      <Loader2 className="mr-2 h-3.5 w-3.5 lg:h-4 lg:w-4 animate-spin" />
                    ) : (
                      "Get Started"
                    )}
                  </Button>
                </CardContent>
              </Card>
            </div>

            {/* Teams Plan */}
            <Card className="relative flex h-full flex-col border bg-card">
              <CardHeader className="p-4 lg:p-6">
                <CardTitle className="text-xl lg:text-2xl">Team</CardTitle>
                <div className="flex items-baseline gap-1">
                  <span className="text-xl lg:text-2xl font-bold">Contact Us</span>
                </div>
                <CardDescription className="mt-4">
                  Empower your team with insights, advanced integrations, and unlimited scheduling capacity.
                </CardDescription>
              </CardHeader>
              <CardContent className="p-4 lg:p-6 flex flex-1 flex-col justify-between">
                <div className="mt-7 flex-1 space-y-2">
                  <PlanFeature>Everything in Plus, and...</PlanFeature>
                  <PlanFeature>Team Insights</PlanFeature>
                  <PlanFeature>Integrations including ClickUp, Todoist</PlanFeature>
                  <PlanFeature>Dedicated Account Manager</PlanFeature>
                </div>
                <Button
                  className="mt-6"
                  variant="outline"
                  onClick={() => window.open("https://www.meetdoris.com", "_blank")}
                >
                  Contact Us
                </Button>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SubscribePage