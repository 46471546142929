import React, { useState } from 'react'
import axiosInstance from '../api/axiosInstance'
import { Button } from "../components/ui/button"
import { Input } from "../components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select"
import { Checkbox } from "../components/ui/checkbox"
import { Label } from "../components/ui/label"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../components/ui/tooltip"
import { Loader2 } from "lucide-react"
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
} from "../components/ui/dropdown-menu"

const referralSources = [
  'Google Search',
  'Social Media',
  'Online Ad',
  'Friend/Colleague',
  'Blog or Article',
  'Other'
]

const professions = [
  'Student',
  'Freelancer',
  'Entrepreneur',
  'Consultant',
  'Sales',
  'Marketing',
  'Technical Professional (e.g., Engineer, Developer)',
  'Business Operations (e.g., Finance, HR)',
  'Academic',
  'Other'
];

const PROFESSION_DISPLAY = {
  'student': 'Student',
  'freelancer': 'Freelancer',
  'entrepreneur': 'Entrepreneur',
  'consultant': 'Consultant',
  'sales': 'Sales',
  'marketing': 'Marketing',
  'technical': 'Technical Professional (e.g., Engineer, Developer)',
  'bizops': 'Business Operations (e.g., Finance, HR)',
  'academic': 'Academic',
  'other': 'Other'
};

const timezones = [
  'Pacific/Honolulu',
  'America/Anchorage',
  'America/Los_Angeles',
  'America/Denver',
  'America/Chicago',
  'America/New_York',
  'America/Sao_Paulo',
  'Europe/London',
  'Europe/Paris',
  'Europe/Berlin',
  'Europe/Madrid',
  'Europe/Istanbul',
  'Asia/Dubai',
  'Asia/Kolkata',
  'Asia/Shanghai',
  'Asia/Tokyo',
  'Australia/Sydney',
  'Australia/Perth',
  'Australia/Adelaide',
]

function Step1WorkSetup({
  completeOnboarding,
  setWorkStartTime,
  setWorkEndTime,
  setTimezone,
  setWorkdays,
  workStartTime,
  workEndTime,
  timezone,
  setProfession,
  profession,
  workdays,
}) {
  const [isSaving, setIsSaving] = useState(false)
  const [timeError, setTimeError] = useState('')

  const validateTimes = (start, end) => {
    if (!start || !end) return true
    const startDate = new Date(`1970/01/01 ${start}`)
    const endDate = new Date(`1970/01/01 ${end}`)
    return startDate < endDate
  }

  const handleWorkStartTimeChange = (e) => {
    const newStartTime = e.target.value
    if (workEndTime && !validateTimes(newStartTime, workEndTime)) {
      setTimeError('Start time must be earlier than end time')
    } else {
      setTimeError('')
    }
    setWorkStartTime(newStartTime)
  }

  const handleWorkEndTimeChange = (e) => {
    const newEndTime = e.target.value
    if (workStartTime && !validateTimes(workStartTime, newEndTime)) {
      setTimeError('End time must be later than start time')
    } else {
      setTimeError('')
    }
    setWorkEndTime(newEndTime)
  }

  const handleWorkdayChange = (day) => {
    setWorkdays((prevWorkdays) => ({
      ...prevWorkdays,
      [day]: !prevWorkdays[day],
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!validateTimes(workStartTime, workEndTime)) {
      setTimeError('Work start time must be earlier than end time')
      return
    }

    setIsSaving(true)

    const workdaysFormatted = Object.keys(workdays).filter(day => workdays[day])

    const settingsData = {
      workStartTime,
      workEndTime,
      timezone,
      workdays: workdaysFormatted,
      profession,
    }

    try {
      await axiosInstance.post('/api/user/settings', settingsData)
      await completeOnboarding()
    } catch (error) {
      console.error('Error saving settings:', error)
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="space-y-4">
        {/* Work Start Time */}
        <div className="space-y-2">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div>
                  <Label htmlFor="workStartTime">Work Start Time</Label>
                  <Input
                    id="workStartTime"
                    type="time"
                    value={workStartTime}
                    onChange={handleWorkStartTimeChange}
                    required
                    className={`mt-1 white-calendar ${timeError ? 'border-red-500' : ''}`}
                  />
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p>The time you typically start working each day.</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>

        {/* Work End Time */}
        <div className="space-y-2">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div>
                  <Label htmlFor="workEndTime">Work End Time</Label>
                  <Input
                    id="workEndTime"
                    type="time"
                    value={workEndTime}
                    onChange={handleWorkEndTimeChange}
                    required
                    className={`mt-1 white-calendar ${timeError ? 'border-red-500' : ''}`}
                  />
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p>The time you usually finish working each day.</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          {timeError && (
            <p className="text-sm text-red-500 mt-1">{timeError}</p>
          )}
        </div>

        {/* Timezone Selection */}
        <div className="space-y-2">
          <Label htmlFor="timezone">Time Zone</Label>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="w-full justify-between">
                {timezone || "Select Time Zone"}
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="max-h-[300px] overflow-y-auto">
              <DropdownMenuLabel>Time Zones</DropdownMenuLabel>
              {timezones.map((zone) => (
                <DropdownMenuItem
                  key={zone}
                  onSelect={() => setTimezone(zone)}
                >
                  {zone}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        {/* Workdays Checkboxes */}
        <div className="space-y-2">
          <Label>Working Days</Label>
          <div className="grid grid-cols-2 gap-4">
            {Object.keys(workdays).map((day) => (
              <div key={day} className="flex items-center space-x-2">
                <Checkbox
                  id={day}
                  checked={workdays[day]}
                  onCheckedChange={() => handleWorkdayChange(day)}
                />
                <Label
                  htmlFor={day}
                  className="font-normal"
                >
                  {day}
                </Label>
              </div>
            ))}
          </div>
        </div>

        {/* Profession Dropdown */}
        <div className="space-y-2">
          <Label htmlFor="profession">What do you do? (optional)</Label>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="w-full justify-between">
                {profession ? PROFESSION_DISPLAY[profession] : "Select an option"}
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="max-h-[300px] overflow-y-auto">
              {Object.entries(PROFESSION_DISPLAY).map(([value, label]) => (
                <DropdownMenuItem
                  key={value}
                  onSelect={() => setProfession(value)}
                >
                  {label}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        {/* Submit Button */}
        <Button
          type="submit"
          className="w-full"
          disabled={isSaving || !!timeError}
        >
          {isSaving ? (
            <Loader2 className="mr-2 h-4 w-4 animate-spin"/>
          ) : (
            'Save & Continue'
          )}
        </Button>
      </div>
    </form>
  )
}

export default Step1WorkSetup