import { useEffect, useState } from "react"
import Calendar from "../components/calendar/Calendar"
import Layout from "../components/Layout"
import axiosInstance from "../api/axiosInstance"
import { useAuth } from "../contexts/AuthContext"
import AddCustomEventForm from "../components/calendar/CustomEvent"
import Step1WorkSetup from "../components/Step1WorkSetup"
import { useToast } from "../components/ui/toast"

import { Button } from "../components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../components/ui/dialog"
import { Plus, Loader2 } from "lucide-react"

function CalendarPage() {
  const { auth } = useAuth()
  const { toast } = useToast()
  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(true)
  const [isAddEventModalOpen, setIsAddEventModalOpen] = useState(false)
  const [needsUpdate, setNeedsUpdate] = useState(false)
  const [userSettings, setUserSettings] = useState({
    workStartTime: '',
    workEndTime: '',
    timezone: ''
  });

  const fetchEvents = async () => {
    setLoading(true)
    try {
      console.log('Fetching events...');  // Debug log
      const response = await axiosInstance.get("/api/outlook/events")
      console.log('Response received:', response);  // Debug log
      
      const eventsData = response.data.events.map((event) => ({
        id: event.id,
        event_id: event.event_id,
        title: event.title,
        start: event.start,
        end: event.end,
        calendar_type: event.calendar_type,
        color: event.color,
        allDay: false
      }))

      console.log('Processed events:', eventsData);
      setEvents(eventsData)
      setNeedsUpdate(response.data.needs_update)
    } catch (err) {
      console.error("Error fetching events:", err);
      console.error("Error response:", err.response);
      console.error("Error request:", err.request);
      console.error("Error config:", err.config);
      toast({
        title: "Error",
        description: `Failed to load events: ${err.response?.data?.error || err.message}`,
        variant: "destructive",
      })
    } finally {
      setLoading(false)
    }
  }

  const fetchUserSettings = async () => {
    try {
      const response = await axiosInstance.get("/api/user/settings");
      console.log('Received user settings:', response.data);
      
      // Validate timezone format
      if (response.data.timezone) {
        try {
          // Test if the timezone is valid
          Intl.DateTimeFormat('en-US', { timeZone: response.data.timezone });
          console.log('Valid timezone:', response.data.timezone);
        } catch (e) {
          console.error('Invalid timezone format:', response.data.timezone);
          // Use browser timezone as fallback
          response.data.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
      }
      
      setUserSettings(response.data);
    } catch (err) {
      console.error("Error fetching user settings:", err);
      toast({
        title: "Error",
        description: "Failed to load user settings",
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    if (auth.isLoggedIn) {
      // Check for no-events hash
      if (window.location.hash === '#no-events') {
        toast({
          title: "Calendar Connected",
          description: "No upcoming events found in your calendar.",
          duration: 5000,
        })
        // Clean up the URL
        window.history.replaceState(null, '', window.location.pathname)
      }

      fetchUserSettings();
      fetchEvents()
      const interval = setInterval(fetchEvents, 5 * 60 * 1000)
      return () => clearInterval(interval)
    }
  }, [auth.isLoggedIn])

  const handleAddCustomEvent = () => {
    setIsAddEventModalOpen(true)
  }

  const handleSync = async (type = 'lite') => {
    console.log('Syncing with scheduler type:', type);
    try {
      const response = await axiosInstance.get("/api/sync-calendar", {
        params: { type }
      });
      console.log("Sync successful", response.data);

      if (response.data.events) {
        const eventsData = response.data.events.map((event) => ({
          title: event.title,
          start: new Date(event.start),
          end: new Date(event.end),
          color: event.color,
          event_id: event.event_id,
          calendar_type: event.calendar_type
        }));
        setEvents(eventsData);
        setNeedsUpdate(false);
      } else {
        await fetchEvents();
      }

      toast({
        description: `Calendar synchronized successfully using ${type} scheduler`
      });
    } catch (err) {
      console.error("Error during sync:", err);
      toast({
        title: "Error",
        description: `Failed to sync calendar with ${type} scheduler`,
        variant: "destructive",
      });
    }
  };

  return (
    <Layout>
      {/* Add Custom Event Dialog */}
      <Dialog
        open={isAddEventModalOpen}
        onOpenChange={setIsAddEventModalOpen}
      >
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Add Custom Event</DialogTitle>
          </DialogHeader>
          <AddCustomEventForm
            onClose={() => setIsAddEventModalOpen(false)}
            refreshEvents={fetchEvents}
          />
        </DialogContent>
      </Dialog>

      {/* Dashboard Content */}
      <div className="relative -m-3 flex h-screen w-[calc(100%+24px)] flex-col bg-transparent p-3">
        {loading ? (
          <div className="flex h-full items-center justify-center">
            <Loader2 className="h-8 w-8 animate-spin" />
          </div>
        ) : (
          <Calendar
            events={events}
            onSync={handleSync}
            calendarType={auth.calendarType}
            needsUpdate={needsUpdate}
            addCustomEventButton={
              <Button onClick={handleAddCustomEvent}>
                <Plus className="mr-2 h-4 w-4" />
                Add Custom Event
              </Button>
            }
            userSettings={userSettings}
          />
        )}
      </div>
    </Layout>
  )
}

export default CalendarPage