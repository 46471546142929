import { useState } from "react"
import { ChevronRight, Trash2, Calendar, Clock, ListTodo, Copy } from "lucide-react"
import { Button } from "../../ui/button"
import { Progress } from "../../ui/progress"
import { Input } from "../../ui/input"
import { cn } from "../../../lib/utils"
import { format, formatISO } from "date-fns"
import { COLORS } from "../constants"

export function ProjectRowHeader({
  project,
  completion,
  isExpanded,
  onExpandToggle,
  onDelete,
  onUpdateDates,
  onUpdateColor,
  onDuplicate,
  onUpdateName
}) {
  const [isEditingStartDate, setIsEditingStartDate] = useState(false)
  const [isEditingEndDate, setIsEditingEndDate] = useState(false)
  const [startTime, setStartTime] = useState(formatISO(new Date(project.start_time), { representation: 'complete' }).slice(0, 16))
  const [deadline, setDeadline] = useState(formatISO(new Date(project.deadline), { representation: 'complete' }).slice(0, 16))
  const [selectedColor, setSelectedColor] = useState(project.color)
  const [isEditingColor, setIsEditingColor] = useState(false)
  const [isEditingName, setIsEditingName] = useState(false)
  const [editedName, setEditedName] = useState(project.name)

  const handleSaveDates = () => {
    onUpdateDates(startTime, deadline)
    setIsEditingStartDate(false)
    setIsEditingEndDate(false)
  }

  const handleCancelEdit = () => {
    setStartTime(formatISO(new Date(project.start_time), { representation: 'complete' }).slice(0, 16))
    setDeadline(formatISO(new Date(project.deadline), { representation: 'complete' }).slice(0, 16))
    setIsEditingStartDate(false)
    setIsEditingEndDate(false)
  }

  const handleColorClick = (color) => {
    setSelectedColor(color)
    onUpdateColor(color)
    setIsEditingColor(false)
  }

  const handleSaveName = () => {
    onUpdateName(editedName)
    setIsEditingName(false)
  }

  const handleCancelNameEdit = () => {
    setEditedName(project.name)
    setIsEditingName(false)
  }

  const handleDuplicate = () => {
    const now = new Date()
    const newStartTime = new Date(now.getTime() + 10 * 60000) // 10 minutes from now
    onDuplicate({
      ...project,
      name: `${project.name} (copy)`,
      start_time: newStartTime.toISOString(),
      tasks: project.tasks.map(task => ({
        ...task,
        completed: false
      }))
    })
  }

  return (
    <div className={cn(
      "group grid grid-cols-12 items-center gap-4 px-4 py-3 hover:bg-accent/50",
      !isExpanded && "border-2 border-border rounded-lg"
    )}>
      <div className="col-span-3 flex items-center gap-3">
        <Button
          variant="ghost"
          size="sm"
          className="h-6 w-6 p-0"
          onClick={onExpandToggle}
        >
          <ChevronRight className={cn(
            "h-4 w-4 transition-transform",
            isExpanded && "rotate-90"
          )}/>
        </Button>
        <div
          className="h-4 w-4 rounded-full cursor-pointer relative"
          style={{backgroundColor: project.color}}
          onClick={() => setIsEditingColor(!isEditingColor)}
        >
          {isEditingColor && (
            <div className="absolute top-6 left-0 z-50 p-3 bg-background border rounded-lg shadow-lg grid grid-cols-6 gap-2 w-[200px]">
              {COLORS.map((color) => (
                <div
                  key={color}
                  className="w-6 h-6 rounded-full cursor-pointer hover:scale-110 transition-transform hover:ring-2 hover:ring-offset-2 hover:ring-offset-background hover:ring-primary"
                  style={{ backgroundColor: color }}
                  onClick={() => handleColorClick(color)}
                />
              ))}
            </div>
          )}
        </div>
        <span
            className="font-medium cursor-pointer hover:text-primary"
            onClick={() => setIsEditingName(true)}
              >
        {isEditingName ? (
            <Input
                value={editedName}
                onChange={(e) => setEditedName(e.target.value)}
                onBlur={handleSaveName}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handleSaveName()
                  if (e.key === 'Escape') handleCancelNameEdit()
                }}
                className="h-8"
                autoFocus
            />
        ) : (
            project.name
        )}
      </span>
      </div>

      <div className="col-span-3">
        <div className="flex items-center gap-2">
          <Progress value={completion} className="h-2 w-32"/>
          <span className="text-sm text-muted-foreground">
            {Math.round(completion)}%
          </span>
        </div>
      </div>

      <div className="col-span-1 text-sm text-muted-foreground flex items-center gap-1">
        <ListTodo className="h-4 w-4"/>
        {project.tasks.length}
      </div>

      <div className="col-span-2 flex items-center gap-1 text-sm text-muted-foreground">
        <Clock className="h-4 w-4"/>
        {isEditingStartDate ? (
          <Input
            type="datetime-local"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            onBlur={handleSaveDates}
            onKeyDown={(e) => e.key === 'Escape' && handleCancelEdit()}
            className="h-8 white-calendar"
            autoFocus
          />
        ) : (
          <span
            className="cursor-pointer hover:text-primary"
            onClick={() => setIsEditingStartDate(true)}
          >
            {format(new Date(project.start_time), 'MMM d, yyyy')}
          </span>
        )}
      </div>

      <div className="col-span-2 flex items-center gap-1 text-sm text-muted-foreground">
        <Calendar className="h-4 w-4"/>
        {isEditingEndDate ? (
          <Input
            type="datetime-local"
            value={deadline}
            onChange={(e) => setDeadline(e.target.value)}
            onBlur={handleSaveDates}
            onKeyDown={(e) => e.key === 'Escape' && handleCancelEdit()}
            className="h-8 white-calendar"
            autoFocus
          />
        ) : (
          <span
            className="cursor-pointer hover:text-primary"
            onClick={() => setIsEditingEndDate(true)}
          >
            {format(new Date(project.deadline), 'MMM d, yyyy')}
          </span>
        )}
      </div>

      <div className="col-span-1 flex justify-end gap-2 opacity-0 transition-opacity group-hover:opacity-100">
        <Button
          variant="ghost"
          size="icon"
          className="h-8 w-8 hover:bg-primary/10 hover:text-primary"
          onClick={handleDuplicate}
        >
          <Copy className="h-4 w-4"/>
        </Button>
        <Button
          variant="ghost"
          size="icon"
          className="h-8 w-8 hover:bg-destructive/10 hover:text-destructive"
          onClick={onDelete}
        >
          <Trash2 className="h-4 w-4"/>
        </Button>
      </div>
    </div>
  )
}