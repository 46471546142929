import { Draggable } from "react-beautiful-dnd"
import { Clock, Edit, Trash2, ChevronDown, GripVertical, Calendar } from "lucide-react"
import { Button } from "../../ui/button"
import { Input } from "../../ui/input"
import { Checkbox } from "../../ui/checkbox"
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "../../ui/dropdown-menu"
import { cn } from "../../../lib/utils"
import { AVAILABLE_TAGS } from "../constants"
import { format } from "date-fns"

export function TaskItem({
  task,
  index,
  isSelected,
  isEditing,
  editHours,
  editMinutes,
  editTag,
  editDueDate,
  onEditHoursChange,
  onEditMinutesChange,
  onEditTagChange,
  onEditDueDateChange,
  onSave,
  onCancel,
  editName,
  onEditNameChange,
  onEdit,
  onDelete,
  onCompletionToggle,
  onSelect,
}) {
  const handleDueDateChange = (e) => {
    const value = e.target.value
    if (!value) {
      onEditDueDateChange(null)
      return
    }
    const newDate = new Date(value)
    if (!isNaN(newDate)) {
      onEditDueDateChange(newDate.toISOString())
    }
  }

  return (
    <Draggable draggableId={`task-${index}`} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={cn(
            "mt-2 grid grid-cols-12 gap-4 rounded-md border bg-card p-4 group relative",
            isSelected && "border-primary bg-primary/5"
          )}
        >
          <div className="absolute left-[-1.25rem] top-1/2 w-5 border-t-2 border-muted" />
          {isEditing ? (
            <>
              <div className="col-span-3">
                <Input
                  type="text"
                  value={editName}
                  onChange={(e) => onEditNameChange(e.target.value)}
                  className="bg-muted"
                />
              </div>
              <div className="col-span-2">
                <Input
                  type="number"
                  value={editHours}
                  onChange={(e) => onEditHoursChange(e.target.value)}
                  placeholder="Hours"
                  min="0"
                  max="999"
                />
              </div>
              <div className="col-span-2">
                <Input
                  type="number"
                  value={editMinutes}
                  onChange={(e) => onEditMinutesChange(e.target.value)}
                  placeholder="Minutes"
                  min="0"
                  max="59"
                />
              </div>
              <div className="col-span-2">
                <Input
                  type="datetime-local"
                  value={editDueDate ? format(new Date(editDueDate), "yyyy-MM-dd'T'HH:mm") : ''}
                  onChange={handleDueDateChange}
                  className="bg-muted"
                />
              </div>
              <div className="col-span-2">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="outline" className="w-full justify-between">
                      {editTag || "Select tag"}
                      <ChevronDown className="h-4 w-4" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent>
                    {AVAILABLE_TAGS.map((tag) => (
                      <DropdownMenuItem
                        key={tag}
                        onClick={() => onEditTagChange(tag)}
                      >
                        {tag}
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
              <div className="col-span-1 flex justify-end gap-2">
                <Button onClick={onSave}>Save</Button>
                <Button variant="outline" onClick={onCancel}>
                  Cancel
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="flex items-center gap-2 col-span-1">
                <div
                  {...provided.dragHandleProps}
                  className="opacity-0 group-hover:opacity-100"
                >
                  <GripVertical className="h-4 w-4 text-muted-foreground" />
                </div>
                <div className="flex gap-2">
                  <Checkbox
                    checked={task.completed || isSelected}
                    onCheckedChange={() => onSelect(index)}
                    disabled={task.completed}
                    className="bg-transparent"
                  />
                </div>
              </div>
              <div className="col-span-4 flex items-center">
                <span
                  className={cn(
                    "text-sm",
                    task.completed && "line-through opacity-60"
                  )}
                >
                  {task.name}
                </span>
              </div>
              <div className="col-span-2 flex items-center text-sm text-muted-foreground">
                <Clock className="mr-1 h-3 w-3" />
                {task.estimated_hours}h {task.estimated_minutes}m
              </div>
              <div className="col-span-2 flex items-center text-sm text-muted-foreground">
                {task.due_date && (
                  <>
                    <Calendar className="mr-1 h-3 w-3" />
                    {format(new Date(task.due_date), 'MMM d, yyyy h:mm a')}
                  </>
                )}
              </div>
              <div className="col-span-2">
                <span className="rounded-md bg-secondary px-2 py-1 text-xs">
                  {task.tag}
                </span>
              </div>
              <div className="col-span-1 flex justify-end gap-2 opacity-0 group-hover:opacity-100 transition-opacity">
                {!task.completed && (
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={onEdit}
                    disabled={isSelected}
                  >
                    <Edit className="h-4 w-4" />
                  </Button>
                )}
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={onDelete}
                  disabled={isSelected}
                >
                  <Trash2 className="h-4 w-4" />
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </Draggable>
  )
}