import React from 'react';
import { 
  Trash2, FileText, FolderKanban, Save, Loader2, Minimize2,
} from 'lucide-react';
import RichTextEditor from './RichTextEditor';
import { Button } from '../ui/button';
import { Separator } from "../ui/separator";
import {
  Tooltip, TooltipContent, TooltipProvider, TooltipTrigger,
} from "../ui/tooltip";

const ExpandedNoteView = ({ 
  note, 
  onUpdate, 
  onDelete, 
  onConvertToTask, 
  onConvertToProject,
  onClose,
  isDirty,
  isSaving,
  isConverting,
  isDeleting,
  onSave 
}) => {
  const handleContentChange = (newContent) => {
    onUpdate({ ...note, content: newContent });
  };

  const handleTitleChange = (e) => {
    onUpdate({ ...note, title: e.target.value });
  };

  const ActionButton = ({ onClick, icon: Icon, label, className, disabled, loading }) => (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant="ghost"
          size="sm"
          onClick={onClick}
          className={`h-8 w-8 p-1 ${className}`}
          disabled={disabled || loading}
        >
          {loading ? <Loader2 className="h-4 w-4 animate-spin" /> : <Icon className="h-4 w-4" />}
        </Button>
      </TooltipTrigger>
      <TooltipContent className="bg-popover text-popover-foreground">
        <p>{loading ? "Please wait..." : label}</p>
      </TooltipContent>
    </Tooltip>
  );

  return (
    <TooltipProvider>
      <div className="flex flex-col h-full overflow-hidden bg-white">
        <div className="flex-none p-6 pb-2">
          <input
            className="w-full bg-transparent font-semibold text-3xl focus:outline-none text-gray-900"
            value={note.title}
            onChange={handleTitleChange}
            placeholder="Note title..."
          />
        </div>
        <div className="flex-1 px-6 pb-6 min-h-0 overflow-auto">
          <RichTextEditor 
            content={note.content}
            onChange={handleContentChange}
          />
        </div>
        <div className="flex-none px-6 py-4 border-t border-border/20 bg-muted/50">
          <div className="flex items-center justify-between">
            <div className="flex gap-1">
              {!note.isNew && (
                <>
                  <ActionButton
                    icon={FileText}
                    onClick={onConvertToTask}
                    label="Convert to task"
                    className="text-gray-700 hover:text-gray-900 hover:bg-gray-200/50"
                    loading={isConverting}
                  />
                  <ActionButton
                    icon={FolderKanban}
                    onClick={onConvertToProject}
                    label="Convert to project"
                    className="text-gray-700 hover:text-gray-900 hover:bg-gray-200/50"
                    loading={isConverting}
                  />
                </>
              )}
            </div>
            <div className="flex gap-1">
              <ActionButton
                icon={Save}
                onClick={onSave}
                label={isDirty ? "Save changes" : "No changes to save"}
                className={isDirty ? 'text-green-600 hover:text-green-700 hover:bg-green-100/50' : 'text-gray-400'}
                disabled={!isDirty}
                loading={isSaving}
              />
              <ActionButton
                icon={Minimize2}
                onClick={onClose}
                label="Exit fullscreen"
                className="text-gray-700 hover:text-gray-900 hover:bg-gray-200/50"
              />
              <ActionButton
                icon={Trash2}
                onClick={onDelete}
                label="Delete note"
                className="text-red-600 hover:text-red-700 hover:bg-red-100/50"
                loading={isDeleting}
              />
            </div>
          </div>
        </div>
      </div>
    </TooltipProvider>
  );
};

export default ExpandedNoteView; 