import { DragDropContext, Droppable } from "react-beautiful-dnd"
import { TaskItem } from "./TaskItem"

export function TaskList({
  projectId,
  tasks,
  selectedTasks,
  isEditing,
  editHours,
  editMinutes,
  editTag,
  editName,
  editDueDate,
  onEditHoursChange,
  onEditMinutesChange,
  onEditTagChange,
  onEditNameChange,
  onEditDueDateChange,
  onSaveTask,
  onEditCancel,
  onEditTask,
  onDeleteTask,
  onTaskCompletionToggle,
  onTasksReorder,
  onTaskSelect,
}) {
  // Ensure we sort strictly by order
  const sortedTasks = [...tasks].sort((a, b) => {
    const orderA = typeof a.order === 'number' ? a.order : Number.MAX_SAFE_INTEGER;
    const orderB = typeof b.order === 'number' ? b.order : Number.MAX_SAFE_INTEGER;
    return orderA - orderB;
  });

  const handleDragEnd = (result) => {
    if (!result.destination) return

    const reordered = Array.from(sortedTasks)
    const [moved] = reordered.splice(result.source.index, 1)
    reordered.splice(result.destination.index, 0, moved)

    // Update the order property for all tasks
    const updatedTasks = reordered.map((task, index) => ({
      ...task,
      order: index + 1
    }))

    // Immediately update the UI
    onTasksReorder(updatedTasks)
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId={`project-${projectId}`}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef} className="relative pl-8">
            <div
              className="absolute left-3 top-0 bottom-[50%] border-l-2 border-muted last:bottom-1/2"
              style={{
                height: `calc(100% - ${sortedTasks.length > 0 ? "1.5rem" : "0px"})`
              }}
            />
            {sortedTasks.map((task, index) => (
              <TaskItem
                key={`task-${task.order}`}
                task={task}
                index={index}
                isSelected={selectedTasks.has(index)}
                isEditing={isEditing === index}
                editHours={editHours}
                editMinutes={editMinutes}
                editTag={editTag}
                editName={editName}
                editDueDate={editDueDate}
                onEditHoursChange={onEditHoursChange}
                onEditMinutesChange={onEditMinutesChange}
                onEditTagChange={onEditTagChange}
                onEditNameChange={onEditNameChange}
                onEditDueDateChange={onEditDueDateChange}
                onSave={() => onSaveTask(index)}
                onCancel={onEditCancel}
                onEdit={() => onEditTask(index)}
                onDelete={() => onDeleteTask(index)}
                onCompletionToggle={() => onTaskCompletionToggle(index)}
                onSelect={() => onTaskSelect(index)}
                isLastItem={index === sortedTasks.length - 1}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}