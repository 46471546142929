import React, { useState } from 'react';
import { Button } from "../../components/ui/button";
import { MessageCircle, MessageSquare, UserPlus } from "lucide-react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../../components/ui/dialog";
import { Textarea } from "../../components/ui/textarea";
import axiosInstance from '../../api/axiosInstance';
import { useAuth } from "../../contexts/AuthContext";

const DashboardHeader = ({ onOpenInvite, redeemedPromo }) => {
  const { auth } = useAuth()
  const [feedback, setFeedback] = useState({
    easeOfUse: 3,
    biggestChallenge: '',
    improvements: ''
  });
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
  const [statusDialog, setStatusDialog] = useState({ open: false, message: '' });

  const handleInputChange = (field, value) => {
    setFeedback(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSubmitFeedback = async () => {
    try {
      await axiosInstance.post('/api/feedback/founder', feedback);
      setStatusDialog({
        open: true,
        message: 'Your feedback has been successfully submitted. We appreciate your input!'
      });
      setFeedback({
        easeOfUse: '',
        biggestChallenge: '',
        improvements: ''
      });
      setOpenFeedbackDialog(false);
    } catch (error) {
      setStatusDialog({
        open: true,
        message: error.response?.status === 429
          ? 'You can only submit feedback once per minute. Please try again later.'
          : 'Failed to submit feedback. Please try again later.'
      });
    }
  };

  return (
    <div className="mb-4 flex items-center justify-between">
      <h1 className="text-3xl font-bold tracking-tight">Welcome 👋</h1>

      <div className="flex gap-2">
        <Button
          variant="outline"
          className="flex items-center gap-2"
          onClick={() => setOpenFeedbackDialog(true)}
        >
          Give Feedback
          <MessageCircle className="h-4 w-4" />
        </Button>
      </div>

      {/* Feedback Form Dialog */}
      <Dialog open={openFeedbackDialog} onOpenChange={setOpenFeedbackDialog}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Share Your Feedback</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-4">
            <p className="text-sm text-muted-foreground">
              We'd love to hear your thoughts on MeetDoris. Your feedback helps us improve!
            </p>

            <div>
              <label className="block mb-5 text-sm font-medium">
                How difficult was it to get started?
              </label>
              <div className="flex items-center justify-center gap-2 mb-1">
                {[1, 2, 3, 4, 5].map((rating) => (
                  <button
                    key={rating}
                    onClick={() => handleInputChange('easeOfUse', rating)}
                    className={`
                      w-10 h-10 rounded-full flex items-center justify-center
                      transition-all duration-200 font-medium
                      ${feedback.easeOfUse === rating
                        ? 'bg-primary text-white shadow-lg scale-110'
                        : 'bg-gray-100 hover:bg-gray-200 text-gray-900'
                      }
                    `}
                  >
                    {rating}
                  </button>
                ))}
              </div>
              <div className="flex justify-between text-xs text-gray-500 px-1 mb-2">
                <span>Very easy</span>
                <span>Very hard</span>
              </div>
            </div>

            <div>
              <label className="block mb-2 text-sm font-medium">
                What's the biggest challenge or frustration you face when using MeetDoris?
              </label>
              <Textarea
                value={feedback.biggestChallenge}
                onChange={(e) => handleInputChange('biggestChallenge', e.target.value)}
                className="min-h-[100px] resize-none"
              />
            </div>

            <div>
              <label className="block mb-2 text-sm font-medium">
                What's something you'd like to see that would make MeetDoris better for you?
              </label>
              <Textarea
                value={feedback.improvements}
                onChange={(e) => handleInputChange('improvements', e.target.value)}
                className="min-h-[100px] resize-none"
              />
            </div>
          </div>

          <DialogFooter>
            <Button
              variant="secondary"
              onClick={() => setOpenFeedbackDialog(false)}
            >
              Cancel
            </Button>
            <Button
              variant="default"
              onClick={handleSubmitFeedback}
              disabled={!feedback.easeOfUse || !feedback.biggestChallenge.trim() || !feedback.improvements.trim()}
            >
              Submit
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Status Dialog */}
      <Dialog open={statusDialog.open} onOpenChange={(open) => setStatusDialog({ ...statusDialog, open })}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Feedback Status</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <p className="text-sm text-muted-foreground">{statusDialog.message}</p>
          </div>
          <DialogFooter>
            <Button
              variant="secondary"
              onClick={() => setStatusDialog({ ...statusDialog, open: false })}
            >
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DashboardHeader;