import React, { useState, useRef, useEffect, useCallback } from 'react';
import { 
  Trash2, FileText, FolderKanban, Save, Loader2, Maximize2, Minimize2,
  Bold, Italic, List, Heading, Link as LinkIcon
} from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import { Button } from '../ui/button';
import {
  Tooltip, TooltipContent, TooltipProvider, TooltipTrigger,
} from "../ui/tooltip";
import {
  Dialog,
  DialogContent,
} from "../ui/dialog";
import { Separator } from "../ui/separator";
import { ToggleGroup, ToggleGroupItem } from "../ui/toggle-group";
import { cn } from "../../lib/utils";
import ExpandedNoteView from './ExpandedNoteView';

const NoteCard = ({ note, onUpdate, onDelete, onConvertToTask, onConvertToProject, onSave }) => {
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [shouldShowExpand, setShouldShowExpand] = useState(false);
  const [localNote, setLocalNote] = useState(note);
  const [isDirty, setIsDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isConverting, setIsConverting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const contentRef = useRef(null);
  const [activeFormats, setActiveFormats] = useState([]);

  const MAX_HEIGHT = 300; // Maximum height in pixels before showing "Read more"

  useEffect(() => {
    if (contentRef.current) {
      setShouldShowExpand(contentRef.current.scrollHeight > MAX_HEIGHT);
    }
  }, [localNote.content]);

  // Update debouncedSave to pass isAutoSave flag
  const debouncedSave = useCallback(
    debounce(async (noteToSave) => {
      try {
        setIsSaving(true);
        await onSave(noteToSave, true); // Pass true to indicate autosave
        setIsDirty(false);
      } catch (error) {
        console.error('Failed to save note:', error);
      } finally {
        setIsSaving(false);
      }
    }, 5000),
    [onSave]
  );

  // Update handleContentChange to include autosave
  const handleContentChange = (newContent) => {
    const updatedNote = { ...localNote, content: newContent };
    setLocalNote(updatedNote);
    setIsDirty(true);
    debouncedSave(updatedNote);
  };

  // Update handleTitleChange to include autosave
  const handleTitleChange = (e) => {
    const updatedNote = { ...localNote, title: e.target.value };
    setLocalNote(updatedNote);
    setIsDirty(true);
    debouncedSave(updatedNote);
  };

  const handleTitleBlur = () => {
    setIsEditingTitle(false);
  };

  // Update manual save to pass isAutoSave flag
  const handleManualSave = async () => {
    if (!isDirty) return;
    
    try {
      setIsSaving(true);
      await onSave(localNote, false); // Pass false to indicate manual save
      setIsDirty(false);
    } catch (error) {
      console.error('Failed to save note:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleConvertToTask = async () => {
    setIsConverting(true);
    try {
      await onConvertToTask(note.id);
    } finally {
      setIsConverting(false);
    }
  };

  const handleConvertToProject = async () => {
    setIsConverting(true);
    try {
      await onConvertToProject(note.id);
    } finally {
      setIsConverting(false);
    }
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await onDelete(note.id);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleFormat = (format) => {
    const textarea = contentRef.current;
    if (!textarea) return;

    // Only apply formatting if there is selected text
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const hasSelection = start !== end;
    const selectedText = localNote.content.substring(start, end);
    
    // If no text is selected, don't apply formatting
    if (!hasSelection) {
      return;
    }
    
    let newText;
    switch(format) {
      case 'bold':
        newText = `**${selectedText}**`;
        break;
      case 'italic':
        newText = `_${selectedText}_`;
        break;
      case 'heading':
        newText = `# ${selectedText}`;
        break;
      case 'list':
        newText = `- ${selectedText}`;
        break;
      case 'link':
        newText = `[${selectedText}](url)`;
        break;
      default:
        newText = selectedText;
    }

    const newContent = 
      localNote.content.substring(0, start) + 
      newText + 
      localNote.content.substring(end);
    
    setLocalNote({ ...localNote, content: newContent });
    setIsDirty(true);

    // Reset active formats after applying
    setActiveFormats([]);
  };

  const ActionButton = ({ onClick, icon: Icon, label, className, disabled, loading }) => (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant="ghost"
          size="sm"
          onClick={onClick}
          className={`h-8 w-8 p-1 ${className}`}
          disabled={disabled || loading}
        >
          {loading ? <Loader2 className="h-4 w-4 animate-spin" /> : <Icon className="h-4 w-4" />}
        </Button>
      </TooltipTrigger>
      <TooltipContent className="bg-popover text-popover-foreground">
        <p>{loading ? "Saving..." : label}</p>
      </TooltipContent>
    </Tooltip>
  );

  const FormatBar = () => (
    <div className="flex items-center gap-2 px-2 py-1 bg-muted/50">
      <ToggleGroup type="multiple" value={activeFormats} onValueChange={setActiveFormats}>
        <ToggleGroupItem value="bold" size="sm" onClick={() => handleFormat('bold')} className="data-[state=on]:bg-primary/20">
          <Bold className="h-4 w-4" />
        </ToggleGroupItem>
        <ToggleGroupItem value="italic" size="sm" onClick={() => handleFormat('italic')} className="data-[state=on]:bg-primary/20">
          <Italic className="h-4 w-4" />
        </ToggleGroupItem>
        <ToggleGroupItem value="heading" size="sm" onClick={() => handleFormat('heading')} className="data-[state=on]:bg-primary/20">
          <Heading className="h-4 w-4" />
        </ToggleGroupItem>
        <ToggleGroupItem value="list" size="sm" onClick={() => handleFormat('list')} className="data-[state=on]:bg-primary/20">
          <List className="h-4 w-4" />
        </ToggleGroupItem>
        <ToggleGroupItem value="link" size="sm" onClick={() => handleFormat('link')} className="data-[state=on]:bg-primary/20">
          <LinkIcon className="h-4 w-4" />
        </ToggleGroupItem>
      </ToggleGroup>
      <Separator orientation="vertical" className="h-4" />
      <ActionButton
        icon={Save}
        onClick={handleManualSave}
        label={isDirty ? "Save changes" : "No changes to save"}
        className={isDirty ? 'text-green-600 hover:text-green-700 hover:bg-green-100/50' : 'text-gray-400'}
        disabled={!isDirty}
        loading={isSaving}
      />
    </div>
  );

  const convertHtmlToMarkdown = (html) => {
    if (!html) return '';
    
    // Create a temporary div to parse HTML
    const div = document.createElement('div');
    div.innerHTML = html;

    // Function to extract text content and maintain basic formatting
    const extractContent = (element) => {
      let result = '';
      
      // Process child nodes
      element.childNodes.forEach(node => {
        if (node.nodeType === Node.TEXT_NODE) {
          result += node.textContent;
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          switch (node.tagName.toLowerCase()) {
            case 'p':
              result += extractContent(node) + '\n\n';
              break;
            case 'strong':
            case 'b':
              result += extractContent(node);
              break;
            case 'em':
            case 'i':
              result += extractContent(node);
              break;
            case 'ul':
              result += '\n' + extractContent(node) + '\n';
              break;
            case 'li':
              result += '• ' + extractContent(node) + '\n';
              break;
            case 'h1':
              result += extractContent(node) + '\n\n';
              break;
            case 'h2':
              result += extractContent(node) + '\n\n';
              break;
            case 'h3':
              result += extractContent(node) + '\n\n';
              break;
            default:
              result += extractContent(node);
          }
        }
      });
      
      return result;
    };

    // Extract content and clean up extra whitespace
    return extractContent(div)
      .replace(/\n\s*\n\s*\n/g, '\n\n') // Remove extra line breaks
      .replace(/^\s+|\s+$/g, ''); // Trim whitespace
  };

  const MarkdownContent = ({ content, isFullscreen }) => (
    <ReactMarkdown 
      className={cn(
        "space-y-2",
        isFullscreen ? "text-lg" : "text-sm",
        "text-gray-900",
        "[&>*]:text-gray-900",
        "[&>h1]:text-2xl [&>h1]:font-bold [&>h1]:mb-4",
        "[&>h2]:text-xl [&>h2]:font-semibold [&>h2]:mb-3",
        "[&>h3]:text-lg [&>h3]:font-semibold [&>h3]:mb-2",
        "[&>p]:leading-relaxed [&>p]:mb-2",
        "[&>ul]:list-disc [&>ul]:pl-4 [&>ul]:mb-2",
        "[&>ol]:list-decimal [&>ol]:pl-4 [&>ol]:mb-2",
        "[&>blockquote]:border-l-2 [&>blockquote]:border-muted [&>blockquote]:pl-4 [&>blockquote]:italic",
        "[&>code]:bg-muted [&>code]:px-1 [&>code]:py-0.5 [&>code]:rounded",
        "[&>a]:text-primary [&>a]:underline [&>a]:underline-offset-4",
        "max-w-none"
      )}
    >
      {convertHtmlToMarkdown(content || '')}
    </ReactMarkdown>
  );

  const NoteContent = ({ isFullscreen = false }) => (
    <div className={`flex flex-col ${isFullscreen ? 'h-full' : 'h-[250px]'}`}>
      {!isFullscreen ? (
        // Compact view
        <>
          <div className="mb-2">
            {isEditingTitle ? (
              <input
                className="w-full bg-transparent font-semibold text-black"
                value={localNote.title}
                onChange={handleTitleChange}
                onBlur={handleTitleBlur}
                placeholder="Note title..."
                autoFocus
              />
            ) : (
              <div
                className="font-semibold text-xl cursor-pointer text-black truncate"
                onClick={() => setIsEditingTitle(true)}
              >
                {localNote.title || "Untitled"}
              </div>
            )}
          </div>
          <div 
            className="flex-1 relative min-h-0 cursor-pointer"
            onClick={() => setIsFullscreen(true)}
          >
            <div className="w-full h-full overflow-hidden px-1">
              <MarkdownContent content={localNote.content} isFullscreen={false} />
            </div>
            {shouldShowExpand && (
              <div className="absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-t from-white/50 to-transparent" />
            )}
          </div>
          <div className="absolute bottom-0 right-0 left-0 p-4 bg-gradient-to-t from-white/80 via-white/80 to-transparent">
            <div className="flex gap-1 justify-end">
              <ActionButton
                icon={isFullscreen ? Minimize2 : Maximize2}
                onClick={() => setIsFullscreen(!isFullscreen)}
                label={isFullscreen ? "Exit fullscreen" : "Expand note"}
                className="text-gray-700 hover:text-gray-900 hover:bg-gray-200/50"
              />
              <ActionButton
                icon={Save}
                onClick={handleManualSave}
                label={isDirty ? "Save changes" : "No changes to save"}
                className={isDirty ? 'text-green-600 hover:text-green-700 hover:bg-green-100/50' : 'text-gray-400'}
                disabled={!isDirty}
                loading={isSaving}
              />
              
              {!note.isNew && (
                <>
                  <ActionButton
                    icon={FileText}
                    onClick={handleConvertToTask}
                    label="Convert to task"
                    className="text-gray-700 hover:text-gray-900 hover:bg-gray-200/50"
                    loading={isConverting}
                  />
                  <ActionButton
                    icon={FolderKanban}
                    onClick={handleConvertToProject}
                    label="Convert to project"
                    className="text-gray-700 hover:text-gray-900 hover:bg-gray-200/50"
                    loading={isConverting}
                  />
                </>
              )}
              
              <ActionButton
                icon={Trash2}
                onClick={handleDelete}
                label="Delete note"
                className="text-red-600 hover:text-red-700 hover:bg-red-100/50"
                loading={isDeleting}
              />
            </div>
          </div>
        </>
      ) : (
        // Expanded view - simplified
        <div className="flex flex-col h-full">
          <div className="flex-none p-6 pb-2">
            <input
              className="w-full bg-transparent font-semibold text-3xl focus:outline-none text-gray-800"
              value={localNote.title}
              onChange={handleTitleChange}
              onBlur={handleTitleBlur}
              placeholder="Note title..."
            />
          </div>
          <FormatBar />
          <Separator className="mb-4" />
          <div className="flex-1 px-6 pb-6 min-h-0">
            <textarea
              ref={contentRef}
              className="w-full h-full bg-transparent resize-none focus:outline-none text-lg leading-relaxed"
              value={localNote.content}
              onChange={handleContentChange}
              placeholder="Write your note here..."
              style={{ 
                height: '100%',
                overflow: 'auto'
              }}
            />
          </div>
          <div className="flex-none px-6 py-4 border-t border-border/20 bg-muted/50">
            <div className="flex items-center justify-between">
              <div className="flex gap-1">
                {!note.isNew && (
                  <>
                    <ActionButton
                      icon={FileText}
                      onClick={handleConvertToTask}
                      label="Convert to task"
                      className="text-gray-700 hover:text-gray-900 hover:bg-gray-200/50"
                      loading={isConverting}
                    />
                    <ActionButton
                      icon={FolderKanban}
                      onClick={handleConvertToProject}
                      label="Convert to project"
                      className="text-gray-700 hover:text-gray-900 hover:bg-gray-200/50"
                      loading={isConverting}
                    />
                  </>
                )}
              </div>
              <div className="flex gap-1">
                <ActionButton
                  icon={Minimize2}
                  onClick={() => setIsFullscreen(false)}
                  label="Exit fullscreen"
                  className="text-gray-700 hover:text-gray-900 hover:bg-gray-200/50"
                />
                <ActionButton
                  icon={Trash2}
                  onClick={handleDelete}
                  label="Delete note"
                  className="text-red-600 hover:text-red-700 hover:bg-red-100/50"
                  loading={isDeleting}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  // Update the debounce utility function to include cancel method
  function debounce(func, wait) {
    let timeout;
    
    function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    }

    executedFunction.cancel = () => {
      clearTimeout(timeout);
    };

    return executedFunction;
  }

  // Make sure to clean up the debounce timer when component unmounts
  useEffect(() => {
    return () => {
      debouncedSave.cancel();
    };
  }, [debouncedSave]);

  return (
    <TooltipProvider>
      <>
        <div className={`rounded-lg p-4 relative ${note.color} shadow-sm h-full overflow-hidden`}>
          <NoteContent />
        </div>

        <Dialog open={isFullscreen} onOpenChange={setIsFullscreen}>
          <DialogContent className="max-w-[900px] h-[90vh] p-0 rounded-lg overflow-hidden">
            <div className={`h-full ${note.color}`}>
              <ExpandedNoteView
                note={localNote}
                onUpdate={(updatedNote) => {
                  setLocalNote(updatedNote);
                  setIsDirty(true);
                  debouncedSave(updatedNote);
                }}
                onDelete={handleDelete}
                onConvertToTask={handleConvertToTask}
                onConvertToProject={handleConvertToProject}
                onClose={() => setIsFullscreen(false)}
                isDirty={isDirty}
                isSaving={isSaving}
                isConverting={isConverting}
                isDeleting={isDeleting}
                onSave={handleManualSave}
              />
            </div>
          </DialogContent>
        </Dialog>
      </>
    </TooltipProvider>
  );
};

export default NoteCard; 