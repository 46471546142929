import { useState } from "react";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../ui/dialog";

export function TaskDialog({
  open,
  onOpenChange,
  selectedTask,
  dialogAction,
  onConfirm,
  onCancel
}) {
  // Get the appropriate title based on action type
  const getTitle = () => {
    if (dialogAction === "delete") {
      return "Delete Task(s)?";
    } else if (dialogAction === "complete") {
      return "Complete Task(s)?";
    }
    return "";
  };

  // Get the appropriate description based on single/multiple tasks
  const getDescription = () => {
    if (Array.isArray(selectedTask)) {
      return `Are you sure you want to ${dialogAction} ${selectedTask.length} tasks? This action cannot be undone.`;
    }
    return `Are you sure you want to ${dialogAction} "${selectedTask?.name}"? This action cannot be undone.`;
  };

  // Get the appropriate button variant and text based on action type
  const getActionButton = () => {
    const variant = dialogAction === "delete" ? "destructive" : "default";
    const text = dialogAction === "delete" ? "Delete" : "Complete";

    return (
      <Button variant={variant} onClick={onConfirm}>
        {text}
      </Button>
    );
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{getTitle()}</DialogTitle>
          <DialogDescription>{getDescription()}</DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button variant="outline" onClick={onCancel}>
            Cancel
          </Button>
          {getActionButton()}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

// Optional prop types for better documentation
TaskDialog.defaultProps = {
  open: false,
  onOpenChange: () => {},
  selectedTask: null,
  dialogAction: null,
  onConfirm: () => {},
  onCancel: () => {},
};

export default TaskDialog;