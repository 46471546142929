import React, { useState } from "react"
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom"
import CalendarPage from "./pages/CalendarPage"
import LoginPage from "./pages/Login"
import Register from "./pages/Register"
import VerifyPage from "./pages/Verify"
import Settings from "./pages/Settings"
import HabitsPage from "./pages/HabitsPage"
import TasksProjectsContainer from "./pages/TasksProjectsContainer"
import ForgotPasswordPage from "./pages/ForgotPassword"
import SubscribePage from "./pages/Subscribe"
import ResetPasswordPage from "./pages/ResetPassword"
import SubscribeSuccessPage from "./pages/SubscribeSuccess"
import SubscribeCancelPage from "./pages/SubscribeCancel"
import AnalyticsPage from "./pages/AnalyticsPage"
import DorisAIPage from "./pages/DorisAIPage"
import Dashboard from "./pages/Dashboard"
import ProtectedRoute from "./components/ProtectedRoute"
import { AuthProvider } from "./contexts/AuthContext"
import NotesPage from "./pages/NotesPage"

// shadcn/ui
import { TooltipProvider } from "./components/ui/tooltip"
import { Toaster } from "./components/ui/toast"

function App() {
  const [darkMode, setDarkMode] = useState(false)

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode)
  }

  return (
    <Router>
      <AuthProvider>
        <TooltipProvider delayDuration={0}>
          <Toaster />

          <Routes>
            {/* Public routes */}
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Navigate to="/dashboard" replace />
                </ProtectedRoute>
              }
            />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/reset-password/:token" element={<ResetPasswordPage />} />

            {/* Protected routes */}
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/verify"
              element={
                <ProtectedRoute>
                  <VerifyPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/subscribe"
              element={
                <ProtectedRoute>
                  <SubscribePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/calendar"
              element={
                <ProtectedRoute>
                  <CalendarPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/habits"
              element={
                <ProtectedRoute>
                  <HabitsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/taskboard"
              element={
                <ProtectedRoute>
                  <TasksProjectsContainer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute>
                  <Settings toggleDarkMode={toggleDarkMode} darkMode={darkMode} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/analytics"
              element={
                <ProtectedRoute>
                  <AnalyticsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/notes"
              element={
                <ProtectedRoute>
                  <NotesPage />
                </ProtectedRoute>
              }
            />
            {/* Subscription success and cancel routes */}
            <Route path="/subscribe/success" element={<SubscribeSuccessPage />} />
            <Route path="/subscribe/cancel" element={<SubscribeCancelPage />} />
            <Route
                path="*"
                element={
                  <ProtectedRoute>
                    <Navigate to="/dashboard" replace />
                  </ProtectedRoute>
                }
              />
          </Routes>
        </TooltipProvider>
      </AuthProvider>
    </Router>
  )
}

export default App