import { useState } from "react"
import TaskForm from "../TaskForm"
import ProjectForm from "../ProjectForm"
import { Tabs, TabsList, TabsTrigger } from "../../components/ui/tabs"
import { Button } from "../../components/ui/button"
import { Plus } from "lucide-react"
import { useLocation } from "react-router-dom"
import { useAuth } from "../../contexts/AuthContext"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog"

const MAX_FREE_PROJECTS = 2
const MAX_FREE_TASKS = 5

const WorkspaceHeader = ({
  activeTab,
  onTabChange,
  projectCount = 0,
  taskCount = 0,
  onUpgradeClick
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { auth } = useAuth()
  const location = useLocation()

  const handleModalClose = () => {
    setIsDialogOpen(false)
  }

  const handleNewClick = () => {
    setIsDialogOpen(true)
  }

  const showLimitInfo = !auth.isSubscribed
  const isAtLimit = !auth.isSubscribed && (
    (activeTab === "projects" && projectCount >= MAX_FREE_PROJECTS) ||
    (activeTab === "tasks" && taskCount >= MAX_FREE_TASKS)
  )

  const getCurrentCount = () => {
    switch (activeTab) {
      case "projects":
        return projectCount
      case "tasks":
        return taskCount
      default:
        return 0
    }
  }

  const getMaxItems = () => {
    switch (activeTab) {
      case "projects":
        return MAX_FREE_PROJECTS
      case "tasks":
        return MAX_FREE_TASKS
      default:
        return 0
    }
  }

  const getTitle = () => {
    switch (activeTab) {
      case "tasks":
        return "Tasks"
      case "projects":
        return "Projects"
      case "notes":
        return "Notes"
      default:
        return "Tasks"
    }
  }

  const getDescription = () => {
    switch (activeTab) {
      case "tasks":
        return "Manage your tasks and schedule them on your calendar."
      case "projects":
        return "Keep track of your current projects."
      case "notes":
        return "Capture your thoughts and convert them to tasks or projects."
      default:
        return ""
    }
  }

  return (
    <>
      <div className="sticky top-0 z-50 border-b border-border bg-background/95 p-6 backdrop-blur supports-[backdrop-filter]:bg-background/60">
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-3xl font-bold">{getTitle()}</h1>
            <p className="mt-1 text-muted-foreground">{getDescription()}</p>
          </div>

          <div className="flex items-center gap-4">
            {showLimitInfo && (
              <span className="text-sm text-muted-foreground">
                {getCurrentCount()}/{getMaxItems()} {getTitle()}
              </span>
            )}

            <div className="flex items-center gap-4">
              <Tabs value={activeTab} onValueChange={onTabChange}>
                <TabsList>
                  <TabsTrigger value="tasks">Tasks</TabsTrigger>
                  <TabsTrigger value="projects">Projects</TabsTrigger>
                </TabsList>
              </Tabs>

              <div className="w-[135px]">
                {activeTab !== "notes" && (
                  isAtLimit ? (
                    <Button onClick={onUpgradeClick} className="w-full">Upgrade</Button>
                  ) : (
                    <Button onClick={handleNewClick} className="w-full">
                      <Plus className="mr-2 h-4 w-4" />
                      Add {getTitle().slice(0, -1)}
                    </Button>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Add New {getTitle().slice(0, -1)}</DialogTitle>
          </DialogHeader>
          {activeTab === "tasks" ? (
            <TaskForm
              onClose={handleModalClose}
              refreshTasks={() => {
                handleModalClose()
                if (location.pathname === "/taskboard") {
                  window.location.reload()
                }
              }}
            />
          ) : (
            <ProjectForm
              onClose={handleModalClose}
              refreshProjects={() => {
                handleModalClose()
                if (location.pathname === "/taskboard") {
                  window.location.reload()
                }
              }}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default WorkspaceHeader
